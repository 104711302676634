import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Header from '../../Header/Header';
import { gameActions } from '../../action/GameAction';
import { history } from '../../helpers/history';
import { alertActions } from '../../action/AlertAction';
import CommonPopup from './WinnerPopup';

class ViewDetailedResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawtype: props.drawtype,
            PriceBreakDown: [],
            fromDate: '',
            toDate: '',
            serverDate: new Date(),
            PastDrawStateObj: {},
            currentPageNo: 0,
            NumberofRecord: 10,
            totalPage: 0,
            startinx: 0,
            endinx: 10,
            lfilterdt: [],
            IsValidData: false,
            _dbdrawType: props.send_db_id
        }

    }
    static getDerivedStateFromProps(props, state) {
        return {
            breakdownlist: props.breakdownlist,
            previousResult: props.previousResult
        };
    };

    GetLatestWinner = (result) => {
        this.setState({ PastDrawStateObj: result })
        this.props.GetRaffleResults(this.state._dbdrawType, result.draw_id, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.props.previousResult !== prevProps.previousResult && this.props.previousResult !== null && this.props.previousResult !== undefined && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            this.setState({ IsValidData: true })
            this.LoadTransactionData()
            // window.allDrawResultPopUp();
        }
    }

    componentDidMount() {
        window.Loaddaterange(1);
        var lstrdate = document.getElementById('mydaterange').value;
        this.onchangeDate(lstrdate);
        window.getdateFromJS(this.onchangeDate);
    }

    onchangeDate = (ldate) => {
        var fdate = ldate.split('-')[0].trim();
        var tdate = ldate.split('-')[1].trim();
        this.setState({ IsValidData: false })
        this.setState({ fromDate: fdate })
        this.setState({ toDate: tdate })
        let checkdate = this.validatedate(fdate, tdate);
        if (checkdate) {
            this.props.GetPreviousResult(this.state._dbdrawType, fdate, tdate, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, 0);
        }
    }

    validatedate = (fromDate, toDate) => {
        let datepattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var fr = (new Date(fromDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var to = (new Date(toDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var currDate = ("0" + this.state.serverDate.getDate()).slice(-2) + "/" + ("0" + (this.state.serverDate.getMonth() + 1)).slice(-2) + "/" + this.state.serverDate.getFullYear();
        var lserverDate = (new Date(currDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        if (fr > lserverDate || to > lserverDate) {
            this.showMsg('lblNoFuturedate');
            return false;
        }
        return true;
        if (fr > to) {
            this.showMsg('lblFrdateGreater');
            return false;
        }
        fr.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        if (fr > to || fr < to) {
            var date = this.state.serverDate;
            var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
            var cdate = new Date(new Date(ss.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var lfr = ("0" + fr.getDate()).slice(-2) + "/" + ("0" + (fr.getMonth() + 1)).slice(-2) + "/" + fr.getFullYear();
            var lto = ("0" + to.getDate()).slice(-2) + "/" + ("0" + (to.getMonth() + 1)).slice(-2) + "/" + to.getFullYear();
            var fdate = new Date(new Date(lfr.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var tdate = new Date(new Date(lto.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            cdate.setHours(0, 0, 0, 0);
            fdate.setHours(0, 0, 0, 0);
            tdate.setHours(0, 0, 0, 0);
            if (fdate > cdate && tdate > cdate) {
                this.showMsg('lblCurrentDate');
                return false;
            }
        }
        return true;
    }

    LoadTransactionData = () => {
        if (this.state.previousResult && this.state.previousResult && this.state.previousResult.length > 0) {
            this.setState({ lfilterdt: this.state.previousResult });
            let ltotpage = Math.trunc(this.state.previousResult.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(this.state.previousResult.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }

    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }

    showMsg = (lstrError) => {
        this.props.alertError(this.props.t(lstrError));
    }

    render() {
        const { t,i18n } = this.props;
        return (
            <>
                <div className={`profileCont mydrawtabs ${this.state.drawtype == 10 ? "topAlign" : "margin-left-7"}`} >
                    <div className="pastHeaderAlign">
                        <span></span>
                        {/* {(this.state.drawtype == 9) ? <h3 className="pastHeadersMin">{t('lblFortune5')}</h3> : <h3 className="pastHeadersMin">{t('lblSuperSix')}</h3>} */}
                        {this.state._dbdrawType == 9 ?
                            <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt="Fortune 4 logo" className="h-10 w-24" /> :
                            this.state._dbdrawType == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt="super 6 logo" className="h-10 w-24" />
                                : this.state._dbdrawType == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" />
                                    : this.state._dbdrawType == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="lucky 1 logo" className="h-10 w-24" />
                                     : this.state._dbdrawType == 14 ? <img src="./assets/img/gamesBg/dream-5-logo.png?v0.2" alt="Dream-5-logo" /> 
                                        : <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune 5 logo" className="h-10 w-24" />}
                        <span></span>
                    </div>
                    <div className="maindrawContent">
                        <CommonPopup PastDrawStateObj={this.state.PastDrawStateObj} drawType={this.state.drawtype} />
                        <div className="allDrawContainer">
                            <button type="button" className="backBtn" onClick={() => { this.props.showClose() }}>
                                <img src="./assets/img/icons/Back-Icon.svg" alt='Back-Icon'/>
                                <span>{t('lblBack')}</span>
                            </button>

                            <div className="winnersList1 winnersListBorderRadius">
                                <div className="allDrawHead">{t('lblAllRes')}</div>
                                <div className="columns-2">
                                    <div className={`prizeBreakDownCol bg-game${this.state.drawtype}fade`}>
                                        <div className="prizeBreak">{t('lblPriceBreak')}</div>
                                        {this.state.drawtype == 13 ?
                                            <>
                                                <div className="matches">
                                                    <div className={`formatOutOf  !opacity-100`}>10x Entry Amount</div>
                                                </div>
                                            </>
                                            : <>
                                                <div>
                                                    {this.state.breakdownlist && this.state.breakdownlist.result && this.state.breakdownlist.result.filter(x => x.game_group_id == this.state._dbdrawType).map((obj, inx) =>
                                                        <div className="matches" key={`al_${inx}`}>
                                                            {i18n.language === 'ta' || i18n.language === 'hi'?  <div className={`formatOutOf  !opacity-100`}>{obj.max_ball} {t('lblOutOf')} {obj.matchingball}</div>
                                                            :<div className={`formatOutOf  !opacity-100`}>{obj.matchingball} {t('lblOutOf')} {obj.max_ball}</div>}
                                                            {/* <div className="font700"><span>{process.env.REACT_APP_CURRENCY}</span> {obj.winamt.split("|")[0]}{obj.winamt.indexOf("|") > -1 && obj.winamt.split("|")[1] == "1" ? "*" : ""}</div> */}
                                                            <div className="font700" style={{ textTransform: "uppercase" }}><span>{process.env.REACT_APP_CURRENCY}</span> {obj.winamt.replace('#', t('lblMillion'))}{obj.starFlag == "1" ? "*" : ""}</div>
                                                            {/* {obj.winamt}  {(inx <= 1 || obj.game_group_id == "10" && inx <= 2) && <>*</> } */}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="mainDrawCol flexCol itemsCenter">
                                        <button className="inputDateRangeBtn">
                                            <img src="./assets/img/icons/calendar.svg" alt='calendar icon'/>
                                            <input style={{ cursor: "pointer", backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" dir="ltr" readOnly />
                                        </button>
                                        <div className="matches grid-1-col margin-top2">
                                            <div className="formatOutOf formatSelectedDate text-uppercase">
                                                {t('lblCurrShwRes')}
                                            </div>
                                        </div>
                                        <div className="matches grid-1-col">
                                            <div className="formatOutOf cols2 displayFlex">
                                                <div>{t('lblFrom')}:</div>
                                                <div className="formatSelectedDate">{this.state.fromDate}</div>
                                            </div>
                                        </div>
                                        <div className="matches grid-1-col">
                                            <div className="formatOutOf cols2 displayFlex">
                                                <div>{t('lblTill')}:</div>
                                                <div className="formatSelectedDate">{this.state.toDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="allDrawHistoryTable">
                                    <thead>
                                        <tr className="tableHead tableHead2">
                                            <th className={`bg-game${this.state.drawtype}`}>{t('lblDrawDate')}</th>
                                            <th className={`bg-game${this.state.drawtype}`}>{t('lblTotalPrize')}</th>
                                            <th className={`bg-game${this.state.drawtype}`}>{t('lblTotwinner')}</th>
                                            <th className={`bg-game${this.state.drawtype}`}> {t('lblWinSeq')}</th>
                                            <th className={`bg-game${this.state.drawtype}`}>{t('lblDet')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.previousResult && this.state.previousResult.slice(this.state.startinx, this.state.endinx).map((prevresult,index) =>
                                            <tr key={`am_${index}`}>
                                                {/* <td> {prevresult.draw_date.split(',')[0].split(' ')[1]} {prevresult.draw_date.split(',')[0].split(' ')[2]} {prevresult.draw_date.split(',')[1].split(' ')[0]}</td> */}
                                                <td> {prevresult.draw_date.replace(',',' ')}</td>
                                                <td>{process.env.REACT_APP_CURRENCY} {prevresult.total_prize}</td>
                                                <td>{prevresult.total_winner}</td>
                                                <td>
                                                    {this.state.drawtype != 13 && <div className={`winningCombo bg-game${this.state.drawtype} marginAuto widthFit`} >
                                                        {prevresult.win_number && prevresult.win_number.split(';').length > 0 && prevresult.win_number.split(';').map((winNos,inx) =>
                                                            <span key={`an_${inx}`}>{winNos}</span>
                                                        )}
                                                    </div>}
                                                    {this.state.drawtype == 13 && prevresult.win_number && prevresult.win_number.split(';').length > 0 && prevresult.win_number.split(';').map((winNos,index) =>
                                                        <ul className="zodiacBallSec Latestlukyresult game-13-balls rowGap" key={`ao_${index}`}>
                                                            <li id="ball_526_2">
                                                                <a className={`game-13-balls-${parseInt(winNos)} game-13`}>
                                                                    <span>{winNos}</span>
                                                                </a></li>
                                                        </ul>
                                                    )}
                                                </td>
                                                <td>
                                                    <a className="show-popup1 bigpopupopen" onClick={() => { this.GetLatestWinner(prevresult) }}><img src="./assets/img/icons/Notes.svg" alt='Notes icon'/></a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {(this.state.previousResult == null || (this.state.previousResult && this.state.previousResult.length == 0)) &&
                                    <p className='marginAuto' style={{ marginBottom: "30px" }}>{t('lblNoRecordFound')}</p>
                                }
                                <div className="pagination paddingBottom2Rem">
                                    <div className="paginationBtn">
                                        <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                                        <div>
                                            <span>{this.state.previousResult && this.state.previousResult.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                            <span>/</span>
                                            <span>{this.state.previousResult && this.state.previousResult.length > 0 ? this.state.totalPage + 1 : 0}</span>
                                        </div>
                                        <button><img src="./assets/img/icons/right-arrow.png" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} alt="right arrow" onClick={() => { this.loadnxtdata("+") }} /></button>
                                    </div>
                                </div>

                                <div className="csvDownBtn">
                                    <button className="bg1 game2BorderColor">Download Results (CSV)</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { RaffleResult, previousResult } = state.gameReducer;
    const { breakdownlist } = state.accountReducer;
    const { user } = state.loginReducer;
    return { user, RaffleResult, breakdownlist, previousResult };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetPreviousResult: gameActions.GetPreviousResult,
    GetRaffleResults: gameActions.GetRaffleResults,
    ClearGetRaffleResults: gameActions.ClearGetRaffleResults,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ViewDetailedResults))
