import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Ourwinners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawtype: props.drawtype,
            pastDrawShow: [],
            currentPageNo: 0,
            NumberofRecord: 6,
            totalPage: 0,
            startinx: 0,
            endinx: 6,
            lfilterdt: [],
            IsValidData: false,
            _dbdrawType:props.send_db_id

        }
    }

    componentDidMount() {
        this.setjsondata();
        localStorage.setItem('drawtabId', 3);
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.state.pastDrawShow && this.state.pastDrawShow !== prevProps.pastDrawShow && this.state.pastDrawShow.length != 0 && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            this.setState({ IsValidData: true })
            this.LoadTransactionData()
            // window.allDrawResultPopUp();
        }
    }


    LoadTransactionData = () => {
        if (this.state.pastDrawShow && this.state.pastDrawShow.length > 0) {
            this.setState({ lfilterdt: this.state.pastDrawShow });
            let ltotpage = Math.trunc(this.state.pastDrawShow.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(this.state.pastDrawShow.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }



    setjsondata = () => {
        try {
            let hbanner = 0
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 2) {
                    hbanner = hbannerVer[1]
                }
            }
            fetch(process.env.REACT_APP_CDN_URL + 'Web/WEBWinners.json' + "?V" + hbanner)
                .then(response => response.json())
                .then(data => {
                    let Ourwinner = data.winnerList.filter(fObj => fObj.GameTypeId == this.state._dbdrawType);
                    if (data.baseurl) {
                        Ourwinner.forEach(element => {
                            element.imagPath = data.baseurl + element.imagPath;
                        });
                    }
                    this.setState({ pastDrawShow: Ourwinner })
                });
        }
        catch (ex) {
            console.log("setjsondata => " + ex)
        }
    };


    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }


    render() {
        const { t } = this.props;
        return (
            // <div className={this.props.drawType == 9 ? "profileCont2 drawResults2" : "profileCont topAlign"}>
            //     <div className="row">
            //         <h3 style={{ textAlign: "center", fontSize: "30px", padding: "20px", marginTop: "20px" }}>{t('lblUnderConst')}</h3>
            //     </div>
            // </div>
            <>
                <div className="profileCont topAlign pb-14 mydrawtabs">
                    <div className="mainDrawWinnerText">{t('lblOurWinnerheading')}</div>
                    <div className="text-2xl font-bold text-center">{t('lblOurWinner')}</div>

                    <div className="flex gap-14 flex-wrap justify-center mt-10">
                        {this.state.pastDrawShow && this.state.pastDrawShow.length == 0 && <p>{t('lblNoRecordFound')}</p>}
                        {this.state.IsValidData && this.state.pastDrawShow && this.state.pastDrawShow.length > 0 && this.state.pastDrawShow.slice(this.state.startinx, this.state.endinx).map((obj, inx) =>
                            <div className="flex gap-5 border-2 shadow-md items-center rounded-2xl bg-white" key={`ag_${inx}`}>
                                <div className="px-5 flex justify-center w-52">
                                    <img
                                        src={obj.imagPath + '?' + obj.version}
                                        alt="Winner 1"
                                        className="w-36 h-36"
                                    />
                                </div>
                                {/* winningTypeID = 1 Main draw ,winningTypeID = 2 raffle draw*/}
                                {/* <div className={`flex flex-col items-start gap-5 bg-game${this.state.drawtype}${this.state.drawtype == 10 && obj.winningTypeID == 2 ? "fade text-white" : ""} ${obj.winningTypeID == 2 ? "bg-opacity-30" : this.state.drawtype == 9 ? "!text-white" : this.state.drawtype == 10 ? "text-white" : ""} justify-center h-full rounded-r-2xl px-10 py-7`} > */}
                                <div className={`flex flex-col items-start gap-5 bg-game${this.state.drawtype}${obj.winningTypeID == 2 ? "fade text-[#1d1a1a]" : " text-white"} justify-center h-full rounded-r-2xl px-5 py-7 w-52`} >
                                    <div className="text-start w-full">
                                        <p className="font-bold text-lg uppercase">{obj.playername}</p>
                                        <p className="italic capitalize">{obj.cityname}</p>
                                    </div>
                                    <div className="text-nowrap">
                                        <p className="font-bold text-lg uppercase">
                                            <span className={`text-slate-${obj.winningTypeID == 2 ? '600' : '200'}`}>{process.env.REACT_APP_CURRENCY}</span>
                                            <span> {obj.amount}</span>
                                        </p>
                                        <p className="italic capitalize text-nowrap">{obj.date}</p>
                                        <p className="capitalize">{obj.winningTypeID == 1 ? <>{t('lblmainwinner')}</> : <>{t('lblrafflewinner')} </>}</p>
                                    </div>
                                </div>
                            </div>)}
                    </div>

                    <div className="pagination mt-16">
                        <div className="paginationBtn">
                            <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                            <div>
                                <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                <span>/</span>
                                <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.totalPage + 1 : 0}</span>
                            </div>
                            <button><img src="./assets/img/icons/right-arrow.png" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} alt="right arrow" onClick={() => { this.loadnxtdata("+") }} /></button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    return { appvariables };

}
export default connect(mapStateToProps)(withTranslation()(Ourwinners))
