import { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { accountActions } from '../../action/AccountAction';
import breakdownlst from './pricebreakdown.json';
class PriceBreakdown extends Component {
    constructor(props) {
        super(props);
        this.state = { dataLoadCompleted: false };
        this.props.GetPriceBreakdownlst(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.state.dataLoadCompleted == false && this.state.breakdownlst && this.state.breakdownlst.result && Array.isArray(this.state.breakdownlst.result)) {
            this.state.dataLoadCompleted = true;
            setTimeout(() => { window.PriceBreakdownflex() }, 300);
        }
    }

    createBall = (x) => {
        let ballLst = [];
        for (let i = 1; i <= x.max_ball; i++) {
            ballLst.push(<span className={`bg-game${x.game_group_id}`}>{i}</span>)
        }
        return ballLst;
    }

    createTickBall = (x) => {
        let ballLst = [];
        for (let i = 1; i <= x.max_ball; i++) {
            if (x.matchingball >= i)
                ballLst.push(<span className={`bg-game${x.game_group_id}`}><img src="./assets/img/icons/star.png" /></span>)
            else
                ballLst.push(<span className={`bg-white br-game${x.game_group_id}`}></span>)
        }
        return ballLst;
    }
    static getDerivedStateFromProps(props, state) {
        return {
            breakdownlst: props.breakdownlist
        }
    }
    GenerateRandom = () => {
        let Rnumber = Math.random() * (1 - 1000);
        return Rnumber;
    }
    render() {
        const { t,i18n } = this.props;
        return (
            <>
                <section className="priceBraDwn padd-Top-0">
                    <div className="wrap1">
                        <div className="priceBraDwnTitle w-100">
                            {/* <img src="./assets/img/icons/money-bag.webp" /> */}
                            <h2>{t('lblPriceBreak')}</h2>
                        </div>
                        <div className='priceBraDwnCont'>
                            <div className="pBrDnDls">
                                <ul className="slides">
                                    {this.state.breakdownlst && this.state.breakdownlst.result && Array.isArray(this.state.breakdownlst.result)
                                        && [14, 11, 9, 10].map((gObj, ginx) =>
                                            <>
                                                {this.state.breakdownlst.result.filter(a => a.game_group_id == gObj).length > 0 &&
                                                    <li key={`e_${ginx}b`} className={`br-game${gObj}`} >
                                                        <div className='w-100 alignCenter'>
                                                            <>
                                                                <div className="pBrLft">
                                                                    <div className="pBrDnGameDlsCont">
                                                                        <div>
                                                                            {gObj == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt="fortune 5 logo" className="w-30" /> :
                                                                                gObj == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt="super 6 logo" className="w-30" /> :
                                                                                    gObj == 14 ? <img src="./assets/img/gamesBg/dream-5-logo.png?v0.2" alt="super 6 logo" className="w-30" /> :
                                                                                        <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="w-30" />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {this.state.breakdownlst.result.filter(a => a.game_group_id == gObj).length > 0 && this.createBall(this.state.breakdownlst.result.filter(a => a.game_group_id == gObj)[0])}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`pBrRgt borRht-game${gObj}`}>
                                                                    {this.state.breakdownlst && this.state.breakdownlst.result && Array.isArray(this.state.breakdownlst.result) 
                                                                    && this.state.breakdownlst.result.filter(a => a.game_group_id == gObj).map((fobj, inxx) =>                                                                       
                                                                            <div className='pBrDnDlsCont' key={`e_${inxx}ss_${gObj}_${ginx}`}>
                                                                                {i18n.language === 'ta' || i18n.language === 'hi' ?  <div className='pBrDnOutPfDls'>  {fobj.max_ball} {t('lblOutOf')} {fobj.matchingball}</div>:
                                                                                <div className='pBrDnOutPfDls'>  {fobj.matchingball} {t('lblOutOf')} {fobj.max_ball}</div>}
                                                                                <div className="pBrDnBalls">
                                                                                    {this.createTickBall(fobj)}
                                                                                </div>
                                                                                <div className="pBrDnPriDls">
                                                                                    <div className={`bg-game${gObj}`}>
                                                                                        <p className={`text-game${gObj}`}>{fobj.pricetext}</p>
                                                                                        <span className='uppercase'>{process.env.REACT_APP_CURRENCY} {fobj.winamt.replace('#', t('lblMillion'))}{fobj.starFlag && fobj.starFlag == "1" ? "*" : ""} </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        </div>
                                                        <div className="priBrDownLbl">
                                                            <p>*</p>
                                                            <span>{t('lblPriceShared')}</span>
                                                            {/* <span className={`bg-game${gObj}fade text-white rounded-full h-5 w-5 flex justify-center items-center font-[quinn-bold]`}>*</span> */}
                                                        </div>
                                                    </li>
                                                }
                                            </>
                                        )}


                                    {/* <div className="grid3Cols gamePrizeContainer">
                                            <div>&nbsp;</div>
                                            <div>
                                                <div className="w-full flex justify-center py-2 rounded-full">
                                                    <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-12 w-28" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid3Cols numbersContainer">
                                            <div>  1 {t('lblOutOf')} 12 zodiac sign</div>
                                             <div className="grid5Cols"></div>

                                            <div className="flex items-center gap-1 w-full">
                                                <div className={`bg-game13 winningTextSection`}>
                                                    <span>Prize</span>
                                                    <p style={{ textTransform: "uppercase" }}>10x Entry Amount  </p>
                                                </div>

                                            </div>
                                        </div> */}
                                    {/* </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}
function mapStateToProps(state) {
    const { breakdownlist } = state.accountReducer;
    const { user } = state.loginReducer;
    return { user, breakdownlist };
}
const mapActionToProps = {
    GetPriceBreakdownlst: accountActions.GetPriceBreakdownlst
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PriceBreakdown))

