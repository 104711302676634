import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
class AboutUs extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() { }

    loadfaq = (evt, e) => {
        window.toggleAccordion(evt, e);
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title>Gulf Ticket: Sharing Million Smiles</title>
                    <meta name="description" content="Discover Gulf Ticket, the UAE's leading online draw, committed to sharing million smiles and fostering a vibrant community." />
                    <meta name="keywords" content="Gulf Ticket, lottery, UAE, responsible gaming, community engagement, raffle, winners, draw, winning numbers, mega prize" />

                    <meta property="og:title" content="Gulf Ticket: Sharing Million Smiles" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Discover Gulf Ticket, the UAE's leading online draw, committed to sharing million smiles and fostering a vibrant community." />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />

                </Helmet>}
                < >
                    <h2 className="headding3 floatNone">{t('lblAbout')}</h2>
                    {
                        i18n.language === "ur" ?
                            <div className="faqsContainer marginTop3Rem">
                                <div className="faqsFirstCont">
                                    <img src="./assets/img/circle_about_us.png" alt="circle_about_us icon" />
                                </div>
                                <div>
                                    <div className="aboutusHeading">ہمارے بارے میں
                                        خواب بننا، مسکراہٹیں بکھیرنا</div>
                                    <div className="redDivider"></div>
                                    <div className="aboutusTextCont">
                                        گلف ٹکٹ میں خوش آمدید، جہاں خوابوں کو پر ملتے ہیں اور ہر موقع پر مسکراہٹیں بکھیری جاتی ہیں۔ ہم صرف پلیٹ فارم نہیں ہیں؛ بلکہ ہم امید کا ترانہ، امکانات کا کینوس، اور خوابوں کی طاقت کا ثبوت ہیں۔.
                                    </div>
                                    <div className="container-accordian">
                                        <div className="accordion-cont">
                                            <div className="accordion-item-cont">
                                                <button id="accordion-button-1" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 1)}>
                                                    <span className="accordion-title">پر امید اوڈیسی: حدود سے آگے بڑھنا</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>ہماری کہانی رجحانات کی حدود میں قید نہیں ہے؛ یہ دلیر امنگوں کی علامت والا بحری سفر ہے۔ غیر معمولی صلاحیت والے انسانوں سے بھری دنیا میں ہم صرف مقابلہ کرنے کے لیے نہیں، بلکہ خوابوں کے بے مثال رہنما کے طور پر کھڑے ہونے کے لیے آگے بڑھتے ہیں۔ کیوں؟ کیونکہ امید وہ بنیاد ہے جس پر گلف ٹکٹ کی عمارت کھڑی ہے – ایک ایسا مینار جو ہر راستے کو روشن کرتا ہے، رکاوٹوں کو عبور کرتا ہے اور مستقبل کو روشن کرتا ہے۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-2" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 2)}>
                                                    <span className="accordion-title">انعامات سے بھی زیادہ: مسکراہٹوں کے بیج لگانا</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>ہمارے لیے لاٹریز صرف نمبروں کا کھیل نہیں ہے بلکہ یہ اس سے کہیں زیادہ اہمیت رکھتی ہیں؛ یہ امید کے بیج بونے کا ذریعہ ہیں۔ ہر ٹکٹ صرف جیتنے کا موقع نہیں ہے؛ بلکہ یہ یقین کرنے، توقع کرنے اور مسکراہٹیں پھیلانے کا موقع ہوتا ہے۔ ہر لاٹری ٹکٹ کے فروخت کے ساتھ، ہم صرف ممکنہ انعامات سے پردہ نہیں ہٹا رہے ہیں۔ ہم خوابوں سے پردہ ہٹا رہے ہیں - ان خوابوں سے جو زندگی کے رخ کو بدلنے اور تقدیر کو نئے سرے سے متعین کرنے کی طاقت رکھتے ہیں۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-3" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 3)}>
                                                    <span className="accordion-title">مسکراہٹیں پھیلانا، ایک وقت میں ایک ٹکٹ</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>ہرے بھرے مناظر کے لیے ہمارے عزم کے پیچھے ایک اور بھی بڑا عزم پوشیدہ ہے – مسکراہٹیں پھیلانے کا عزم۔ ہمارے کھجور کے درخت لگانے کے پروجیکٹ کے ذریعے، ہم صرف درخت نہیں لگا رہے ہیں؛ بلکہ ہم امید، ترقی، اور خوشی کے پودے لگا رہے ہیں۔ ہر درخت ہمارے اس یقین کے ثبوت کے طور پر کھڑا ہے کہ ایک وقت میں ایک مسکراہٹ کے ساتھ مثبت تبدیلی کی پرورش کی جا سکتی ہے.</p><br /><br />
                                                    <p>بن راشد المکتوم کے تصور سے متاثر ہو کر، ہمارا پراجیکٹ ان کی امنگوں کے لیے ایک چھوٹا لیکن پرخلوص خراج تحسین ہے۔ ہم متحدہ عرب امارات کو سرسبز بنانے کے ان کے عزم کی آواز سے آواز ملا کر، زیادہ پائیدار، خوبصورت دنیا کے لیے ان کی کوشش پر لبیک کہتے ہیں۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-4" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 4)}>
                                                    <span className="accordion-title">گلف ٹکٹ کے دل کی دھڑکن: امید، خواب، مسکراہٹیں</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>گلف ٹکٹ صرف آن لائن لاٹری نہیں ہے۔ یہ امید، خواب، اور مسکراہٹیں پھیلانے کی طاقت سے بھرا ہوا سفر ہے۔ ہم یہاں یہ ثابت کرنے کے لیے موجود ہیں کہ ہر لاٹری صرف جیتنے کا ٹکٹ نہیں ہے؛ یہ یقین کرنے، خواہش کرنے اور خوشی کی لہریں پیدا کرنے کا ٹکٹ ہے۔ جیسے جیسے ہم آگے بڑھتے ہیں، ہم آپ کو صرف شرکاء کے طور پر نہیں، بلکہ ایک ایسی دنیا کے ماننے والوں کے طور پر ہمارے ساتھ شامل ہونے کی دعوت دیتے ہیں جہاں ہر خواب روشن کل کو تشکیل دینے کی صلاحیت رکھتا ہے۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-5" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 5)}>
                                                    <span className="accordion-title">مشن اور تصور</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>گلف ٹکٹ ممتاز آن لائن پلیٹ فارم ہے جو انتہائی غیر معمولی ہفتہ وار قرعہ اندازی کی میزبانی کرتا ہے اور شرکاء کے خوابوں کو حقیقت میں تبدیل کرنے کے لیے انہیں زندگی بدلنے والا موقع فراہم کرتا ہے۔ ہمیں اس بات پر فخر محسوس ہور ہا ہے کہ متحدہ عرب امارات میں مقیم گلف ٹکٹ صرف سنسنی خیز تفریحی مرکز ہی نہیں بلکہ اس کی حیثیت اس سے کہیں زيادہ ہے؛ ;</p><br /><br />
                                                    <p>یہ سی ایس آر کی پہلی تنظیم ہے جس نے معاشرے کی خدمت کرنے کا مصمم عزم کیا ہے۔ ہماری بنیادی اقدار اس بات پر مرکوز ہیں کہ افراد کو صحت مند زندگی گزارنے اور خوشیوں اور آسائشوں کا تجربہ کرنے کے لیے بااختیار بنایا جائے، لیکن سب سے اہم بات یہ ہے کہ لوگوں کے چہروں پر مسکراہٹ لائی جائے۔.</p><br /><br />
                                                    <p>ہم نیے طرز کے مواد اور جدید ٹیکنالوجی کے ذریعے حدود کو آگے بڑھانے میں یقین رکھتے ہیں اور متحدہ عرب امارات کے صارفین کے لیے واقعی فائدہ مند تجربہ فراہم کرتے ہیں۔ سماج کے حامی اور ماحول دوست اخلاقیات پر عمل کرتے ہوئے گلف ٹکٹ ہم خیال قومی اور علاقائی پروگراموں میں فعال طور پر حصہ لیتا ہے، جس سے بہتر کل کی تعمیر کی کوشش کے لیے اتحاد میں جوش پیدا ہوتا ہے۔.</p><br /><br />
                                                    <p>ہمارے سفر کو معاشرے پر گہرا اثر ڈالنے اور ذمہ دار اور باشعور کاروباری طریقوں کی رہنمائی کرنے کی خواہش سے تحریک ملتی ہے۔ گلف ٹکٹ کے ساتھ، آپ کی تبدیلی کا تجربہ شروع ہوجاتا ہے جس سے زندگیوں کو تقویت ملتی ہے اور خواب حقیقت میں ڈھلتے ہیں۔ اس غیر معمولی سفر میں ہمارے ساتھ شامل ہوجائیں کیونکہ ہم ناقابل فراموش لمحات تخلیق کرتے ہیں اور سب کے لیے روشن مستقبل کو فروغ دیتے ہیں۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-6" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 6)}>
                                                    <span className="accordion-title">ہمارا مقصد</span>
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>گلف ٹکٹ میں، ہم سماجی ذمہ داری کو فروغ دیتے ہوئے سنسنی خیز تفریح اور زندگی بدل دینے والے مواقع کے لیے بے مثال پلیٹ فارم فراہم کرنے کی کوشش کرتے ہیں۔ دلکش واقعات اور قرعہ اندازی کے ذریعے، ہمارا مقصد یہ ہے کہ مشترکہ ذمہ داری کے احساس کی حوصلہ افزائی کرتے ہوئے افراد اور برادریوں کو اپنے سیارے کے مستقبل پر بامعنی اثر ڈالنے کے لیے بااختیار بنایا جائے۔.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <button id="accordion-button-7" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 7)}>
                                                    <span className="accordion-title">ہمارا تصور</span >
                                                    <span className="icon" aria-hidden="true"></span>
                                                </button>
                                                <div className="accordion-content">
                                                    <p>گلف ٹکٹ ایسے مستقبل کا تصور کرتا ہے جہاں خوشی اور ذمہ داری ایک ساتھ ہوجائیں۔ ہم پائیدار ترقی کو بڑھاوا دے کر نیے معیارات مقرر کرتے ہوئے سب سے زیادہ آن لائن ہفتہ وار قرعہ اندازی کی خواہش رکھتے ہیں۔ ہمارا تصور اجتماعی شعور کی ثقافت کو فروغ دینا، ایک سرسبز دنیا میں تعاون کرنا اور آنے والی نسلوں کے لیے مثبت میراث چھوڑنا ہے۔.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : i18n.language === 'ml' ?
                                <div className="faqsContainer marginTop3Rem">
                                    <div className="faqsFirstCont">
                                        <img src="./assets/img/circle_about_us.png" alt="circle_about_us icon" />
                                    </div>
                                    <div>
                                        <div className="aboutusHeading">സ്വ പ്നങ്ങൾ സൃഷ്ടി ക്കുന്നു, പുഞ്ചി രി വി ടർത്തു ന</div>
                                        <div className="redDivider"></div>
                                        <div className="aboutusTextCont">
                                            ഗൾഫ് ടി ക്കറ്റി ലേ ക്ക് സ്വാ ഗതം , അവി ടെ സ്വ പ്നങ്ങൾ ചി റകുകൾ കണ്ടെ ത്തുന്നു, ഓരോ അവസരത്തി ലും പുഞ്ചി ര
                                            നെ യ്തി രി ക്കുന്നു. ഞങ്ങൾ ഒരു പ്ലാ റ്റ്ഫോം മാ ത്രമല്ല; ഞങ്ങൾ പ്രതീ ക്ഷയുടെ ഒരു സിം ഫണി യാ ണ്, സാ ധ്യ തകളുടെ ക്യാ ൻവാ സ
                                            ആണ്, സ്വ പ്നങ്ങളുടെ ശക്തി യുടെ സാ ക്ഷ്യ മാ ണ
                                        </div>
                                        <div className="container-accordian">
                                            <div className="accordion-cont">
                                                <div className="accordion-item-cont">
                                                    <button
                                                        id="accordion-button-1"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 1)}
                                                    >
                                                        <span className="accordion-title">ഒരുപ്രതീക്ഷാജനകമായഇതിഹാസകാവ്യം: പരിധിക്കപ്പുറമുള്ളശക്തിപ്പെടുത്തൽ</span>
                                                        <span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            ഞങ്ങളുടെകഥപ്രവണതകളുടെ അതിരുകളാൽ ഒതുങ്ങുന്നില്ല; ധീരമായഅഭിലാഷങ്ങളാൽഅടയാളപ്പെടുത്തിയഒരുയാത്രയാണിത്.അതികായന്മാർതിങ്ങിനിറഞ്ഞലോകത്ത്, മത്സരിക്കാൻമാത്രമല്ല, സ്വപ്നങ്ങളുടെസമാനതകളില്ലാത്ത ചാലകമായിനിൽക്കാനാണ്നാംമുന്നോട്ട്പോകുന്നത്.എന്തുകൊണ്ട്? കാരണംപ്രതീക്ഷയാണ്ഗൾഫ്ടിക്കറ്റിന്റെഅടിത്തറ – എല്ലാവഴികളെയുംപ്രകാശിപ്പിക്കുന്ന, പ്രതിബന്ധങ്ങളെമറികടന്ന്ഭാവിയെപ്രകാശിപ്പിക്കുന്നഒരുവെളിച്ചം.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-2"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 2)}
                                                    >
                                                        <span className="accordion-title">സമ്മാനങ്ങളേക്കാൾകൂടുതൽ: പുഞ്ചിരിയുടെവിത്തുകൾപാകുന്നു</span><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            ലോട്ടറികൾ, ഞങ്ങളെസംബന്ധിച്ചിടത്തോളംകേവലംഅക്കങ്ങളുടെകളിമാത്രമല്ല; അവർപ്രത്യാശയുടെവിത്തുകൾപാകാനുള്ളഒരുമാധ്യമമാണ്.ഓരോടിക്കറ്റുംവിജയിക്കാനുള്ളഅവസരംമാത്രമല്ല; വിശ്വസിക്കാനുംപ്രതീക്ഷിക്കാനുംപുഞ്ചിരിവിടർത്താനുമുള്ളഅവസരമാണിത്.ഓരോലോട്ടറിടിക്കറ്റ്വിറ്റഴിക്കുമ്പോഴും, ഞങ്ങൾസാധ്യതയുള്ളഅനാവരണം ചെയ്യുക മാത്രമല്ല; ഞങ്ങൾസ്വപ്നങ്ങൾഅനാവരണംചെയ്യുകയാണ് - ജീവിതത്തെമാറ്റിമറിക്കാനുംവിധികളെപുനർനിർവചിക്കാനുംശക്തിയുള്ളസ്വപ്നങ്ങൾ.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-3"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 3)}
                                                    >
                                                        <span className="accordion-title">പുഞ്ചിരിവിടർത്തുന്നു, ഒരുസമയംഒരുടിക്കറ്റ്</span ><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            പ്രകൃതി ദൃശ്യ ങ്ങൾ ഹരി താ ഭമാ ക്കാ നുള്ള ഞങ്ങളുടെ പ്രതി ബദ്ധതയ്ക്ക് പി ന്നി ൽ അതി ലും വലി യ പ്രതി ബദ്ധതയുണ്ട് - പുഞ്ചി രി
                                                            വി ടർത്താ നു ള്ള പ്രതി ബദ്ധത. ഞങ്ങളുടെ ഈന്തപ്പന നടീ ൽ പദ്ധതി യി ലൂടെ , ഞങ്ങൾ മരങ്ങൾ നടുക മാ ത്രമല്ല; ഞങ്ങൾ
                                                            പ്രതീ ക്ഷയും വളർച്ചയും സന്തോ ഷവും നട്ടുവളർത്തു കയാ ണ്. ഓരോ മരവും ഒരു സമയത്ത് ഒരു പുഞ്ചി
                                                            <br /><br />രി യോ ടെ പോ സി റ്റീ വ്
                                                            മാ റ്റത്തെ പരി പോ ഷി പ്പി ക്കാ ൻ കഴി യുമെ ന്ന ഞങ്ങളുടെ വി ശ്വാ സത്തി ന്റെ തെ ളി വാ യി നി ലകൊ ള്ളുന്നു. ഹി സ് ഹൈ നസ്
                                                            മുഹമ്മദ് ബി ൻ റാ ഷി ദ് അൽ മക്തൂമി ന്റെ ദർശനത്തിൽ നി ന്ന് പ്രചോ ദനം ഉൾക്കൊ ണ്ട്, ഞങ്ങളുടെ പ്രോ ജക്റ്റ് അദ്ദേ ഹത്തി ന്റെ
                                                            അഭി ലാ ഷങ്ങൾക്കു ള്ള ചെ റുതെ ങ്കി ലും ആത്മാ ർത്ഥമാ യ ആദരാ ഞ്ജലി യാ ണ്. കൂടുതൽ സുസ്ഥി രവും മനോ ഹരവുമാ യ ഒരു
                                                            ലോ കത്തി നാ യുള്ള അദ്ദേ ഹത്തി ന്റെ പരി ശ്രമത്തെ പ്രതി ധ്വ നി പ്പി ച്ചുകൊ ണ്ട് യുഎഇയെ ഹരി തവൽക്കരി ക്കാ നു ള്ള
                                                            അദ്ദേ ഹത്തി ന്റെ പ്രതി ബദ്ധത ഞങ്ങൾ അനുകരി ക്കുന്നു.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-4"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 4)}
                                                    >
                                                        <span className="accordion-title">ഗൾഫ്ടിക്കറ്റിന്റെഹൃദയമിടിപ്പ്: പ്രതീക്ഷ, സ്വപ്നങ്ങൾ, പുഞ്ചിരി</span><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            ഗൾഫ് ടി ക്കറ്റ് ഒരു ഓൺലൈ ൻ ലോ ട്ടറി മാ ത്രമല്ല; പ്രതീ ക്ഷകളും സ്വ പ്നങ്ങളും പുഞ്ചി രി വി ടർത്താ നു ള്ള ശക്തി യും നി റഞ്ഞ
                                                            ഒരു യാ ത്രയാ ണി ത്. ഓരോ ലോ ട്ടറി യും വി ജയി ക്കാ നുള്ള ടി ക്കറ്റ് മാ ത്രമല്ലെ ന്ന് തെ ളി യി ക്കാ ൻ ഞങ്ങൾ ഇവി ടെ യുണ്ട്;
                                                            വി ശ്വ സി ക്കാ നും ആഗ്രഹി ക്കാ നും സന്തോ ഷത്തി ന്റെ അലകൾ സൃഷ്ടി ക്കാ നുമുള്ള ടി ക്കറ്റാ ണി ത്. ഞങ്ങൾ മുന്നോ ട്ട് പോ കുമ്പോ ൾ,
                                                            പങ്കാ ളി കൾ എന്ന നി ലയി ൽ മാ ത്രമല്ല, ഓരോ സ്വ പ്നത്തി നും ശോ ഭനമാ യ നാ ളെ യെ രൂപപ്പെ ടുത്താ ൻ കഴി വുള്ള ഒരു
                                                            ലോ കത്തി ലെ വി ശ്വാ സി കൾ എന്ന നി ലയി ൽ ഞങ്ങളോ ടൊ പ്പം ചേ രാ ൻ ഞങ്ങൾ നി ങ്ങളെ ക്ഷണി ക്കുന്നു.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-5"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 5)}
                                                    >
                                                        <span className="accordion-title">ദൗത്യവുംകാഴ്ചപ്പാടും</span><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        ഗൾഫ് ടി ക്കറ്റ് ഏറ്റവും അസാ ധാ രണമാ യ പ്രതി വാ ര നറുക്കെ ടുപ്പ് നടത്തുന്ന ഒരു വി ശി ഷ്ട ഓൺലൈ ൻ പ്ലാ റ്റ്ഫോ മാ ണ്,
                                                        പങ്കെ ടുക്കുന്നവർക്ക് അവരുടെ സ്വ പ്നങ്ങൾ യാ ഥാ ർത്ഥ്യ മാ ക്കാ നു ള്ള ജീ വി തം മാ റ്റി മറി ക്കുന്ന അവസരം വാ ഗ്ദാ നം ചെ യ്യുന്നു.
                                                        അഭി മാ നത്തോ ടെ യു.എ.ഇ ആസ്ഥാ നമാ യി പ്രവർത്തിക്കു ന്ന ഗൾഫ് ടി ക്കറ്റ് ഒരു ആവേ ശകരമാ യ വി നോ ദ കേ ന്ദ്രം

                                                        എന്നതി നപ്പുറം ; സമൂഹത്തെ സേ വി ക്കുന്നതി ൽ അഗാ ധമാ യ പ്രതി ബദ്ധതയുള്ള ഒരു സി എസ്ആർ- ആദ്യ സ്ഥാ പനമാ ണി ത്.
                                                        ആരോ ഗ്യ കരമാ യ ജീ വി തം നയി ക്കാ നും സന്തോ ഷങ്ങളും ആഡം ബരങ്ങളും അനുഭവി ക്കാ നും വ്യ ക്തി കളെ
                                                        ശാ ക്തീ കരി ക്കുന്നതി ലാ ണ് ഞങ്ങളുടെ പ്രധാ ന മൂല്യ ങ്ങൾ കേ ന്ദ്രീ കരി ച്ചി രി ക്കുന്നത്, എന്നാ ൽ ഏറ്റവും പ്രധാ നമാ യി , ആളുകളുടെ
                                                        മുഖത്ത് പുഞ്ചി രി കൊ ണ്ടുവരുന്നു.
                                                        <br /><br />
                                                        ഉള്ളടക്ക നവീ കരണത്തി ലൂടെ യും അത്യാ ധുനി ക സാ ങ്കേ തി കവി ദ്യ യി ലൂടെ യും അതി രുകൾ നീ ക്കുന്നതി ൽ ഞങ്ങൾ
                                                        വി ശ്വ സി ക്കുന്നു, യുഎഇയി ലുടനീ ളമുള്ള ഉപഭോ ക്താ ക്കൾക്ക് ശരി ക്കും പ്രതി ഫലദാ യകമാ യ അനുഭവം നൽകു ന്നു . സാ മൂഹി ക
                                                        അനുകൂലവും പരി സ്ഥി തിാ ധി ഷ്ഠി തവുമാ യ ധാ ർമ്മി കതയു മാ യി യോ ജി ച്ച ഗൾഫ് ടി ക്കറ്റ് സമാ ന ചി ന്താ ഗതി ക്കാ രാ യ ദേ ശീ യ,
                                                        പ്രാ ദേ ശി ക പരി പാ ടി കളി ൽ സജീ വമാ യി പങ്കെ ടുക്കുന്നു, മി കച്ച നാ ളെ കെ ട്ടി പ്പടുക്കുന്നതി നുള്ള ശ്രമത്തി ൽ ഐക്യ ത്തി ന്
                                                        പ്രചോ ദനം നൽകു ന്നു ..
                                                        ഉത്തരവാ ദി ത്തമുള്ളതും ബോ ധപൂർണ്ണ വു മാ യ ബി സി നസ്സ് സമ്പ്രദാ യങ്ങളി ലേ ക്ക് നയി ക്കുന്ന സമൂഹത്തി ൽ
                                                        അനുരണനാ ത്മകമാ യ സ്വാ ധീ നം ചെ ലുത്താ നുള്ള ആഗ്രഹമാ ണ് ഞങ്ങളുടെ യാ ത്രയ്ക്ക് ഊർജ്ജം പകരുന്നത്. ഗൾഫ്
                                                        ടി ക്കറ്റി ലൂടെ , ജീ വി തത്തെ സമ്പന്നമാ ക്കുകയും സ്വ പ്നങ്ങൾ സാ ക്ഷാ ത്കരി ക്കുകയും ചെ യ്യുന്ന ഒരു പരി വർത്തന അനുഭവം
                                                        നി ങ്ങൾ ആരം ഭി ക്കുന്നു. അവി സ്മരണീ യമാ യ നി മി ഷങ്ങൾ സൃഷ്ടി ക്കുകയും എല്ലാ വർക്കും ശോ ഭനമാ യ ഭാ വി വളർത്തു കയും
                                                        ചെ യ്യുമ്പോ ൾഈഅസാ ധാ രണമാ യ യാ ത്രയി ൽ ഞങ്ങളോ ടൊ പ്പം ചേ രുക
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-6"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 6)}
                                                    >
                                                        <span className="accordion-title">ഞങ്ങളുടെദൗത്യം</span><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            ഗൾഫ് ടി ക്കറ്റി ൽ, സാ മൂഹി ക ഉത്തരവാ ദി ത്തം പ്രോ ത്സാ ഹി പ്പി ക്കുന്നതി നൊ പ്പം ആവേ ശകരമാ യ വി നോ ദത്തി നും ജീ വി തം
                                                            മാ റ്റി മറി ക്കുന്ന അവസരങ്ങൾക്കും സമാ നതകളി ല്ലാ ത്ത ഒരു പ്ലാ റ്റ്ഫോം നൽകാ ൻ ഞങ്ങൾ ശ്രമി ക്കുന്നു. ആകർഷകമാ യ
                                                            ഇവന്റുകളി ലൂടെ യും നറുക്കെ ടുപ്പുകളി ലൂടെ യും , പങ്കി ട്ട ഉത്തരവാ ദി ത്തബോ ധം പ്രചോ ദി പ്പി ക്കാ നും നമ്മുടെ ലോ കത്തി ന്റെ
                                                            ഭാ വി യി ൽ അർത്ഥവത്താ യ സ്വാ ധീ നം ചെ ലുത്താ ൻ വ്യ ക്തി കളെ യും കമ്മ്യൂ ണി റ്റി കളെ യും ശാ ക്തീ കരി ക്കാ നും ഞങ്ങൾ
                                                            ലക്ഷ്യ മി ടുന
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button
                                                        id="accordion-button-7"
                                                        aria-expanded="false"
                                                        className="accordian-cont-button"
                                                        onClick={() => this.loadfaq(this, 7)}
                                                    >
                                                        <span className="accordion-title">ഞങ്ങളുടെകാഴ്ചപ്പാട്</span><span className="icon" aria-hidden="true"></span>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>
                                                            സന്തോ ഷവും ഉത്തരവാ ദി ത്തവും ഒത്തുചേ രുന്ന ഒരു ഭാ വി യാ ണ് ഗൾഫ് ടി ക്കറ്റ് വി ഭാ വനം ചെ യ്യുന്നത്. സുസ്ഥി ര വി കസനം
                                                            ഉയർത്തിക്കാ ട്ടി ക്കൊ ണ്ട് പുതി യ മാ നദണ്ഡങ്ങൾ സ്ഥാ പി ച്ചുകൊ ണ്ട് മുൻനി ര ഓൺലൈ ൻ പ്രതി വാ ര നറുക്കെ ടുപ്പ് നടത്താ ൻ
                                                            ഞങ്ങൾ ആഗ്രഹി ക്കുന്നു. കൂട്ടാ യ ബോ ധത്തി ന്റെ ഒരു സം സ്കാ രം പരി പോ ഷി പ്പി ക്കുക, ഹരി ത ലോ കത്തി ന് സം ഭാ വന
                                                            നൽകു ക, വരും തലമുറകൾക്കാ യി ക്രി യാ ത്മക പാ രമ്പര്യം നൽകു ക എന്നി വയാ ണ് ഞങ്ങളുടെ കാ ഴ്ചപ്പാ ട്.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : i18n.language === 'ar' ?
                                    <div className="faqsContainer marginTop3Rem">
                                        <div className="faqsFirstCont">
                                            <img src="./assets/img/circle_about_us.png" alt="circle_about_us icon" />
                                        </div>
                                        <div>
                                            <div className="aboutusHeading">من نحن
                                                صياغة الأحلام، ونشر الابتسامات</div>
                                            <div className="redDivider"></div>
                                            <div className="aboutusTextCont">
                                                مرحبًا بك في غالف تيكيت، حيث تتحقق الأحلام وتبتسم لك الفرص. نحن لسنا مجرد منصة. نحن سيمفونية من الأمل، لوحة من الاحتمالات، وشهادة على قوة الأحلام.
                                            </div>
                                            <div className="container-accordian">
                                                <div className="accordion-cont">
                                                    <div className="accordion-item-cont">
                                                        <button id="accordion-button-1" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 1)}>
                                                            <span className="accordion-title">رحلةطويلةمفعمةبالأمل: بناءيتجاوزالحدود</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>قصتنالاتقفعندحدودالتوجهات. إنهارحلةتتسمبالطموحاتالجريئة. فيعالممليءبالعمالقة،نحننخطوإلىالأمام،ليسمنأجلالمنافسةفقط،بلولنكونقادةالأحلامبلامنازع. لماذا؟لأنالأملهوالأساسالذيتقومعليهغالفتيكيت–فهيمنارةتنيركلطريق،وتتجاوزالعقباتوتضيءالمستقبل.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-2" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 2)}>
                                                            <span className="accordion-title">ليستمجردجوائز: فالهدف أيضًازراعة بذور الابتسامات</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>اليانصيب بالنسبة لنا هي أكثر منمجرد لعبة أرقام؛ فهي وسيلة لزرع بذور الأمل. فالتذكرة ليست مجرد فرصة للفوز؛ بل هي فرصة للإيمان والتنبؤ ونشر الابتسامات. مع كل تذكرة يانصيب تُباع، لا نكشف عن الجوائز المحتملةفقط؛ بل نكشف عن الأحلام – الأحلام التي لديها القدرة على تغيير الحياة وإعادة تحديد المصائر.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-3" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 3)}>
                                                            <span className="accordion-title">نشرالابتسامات،من خلالتذكرةواحدةفي كلمرة</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>نشرالابتسامات،من خلالتذكرةواحدةفي كلمرة
                                                                ووراء التزامنا بتخضير المناظر الطبيعية يكمن التزام أكبر –وهو التزامنا بنشر الابتسامات. من خلال مشروعنا لزراعة أشجار النخيل، نحن لا نزرع الأشجار فحسب؛ بل ونزرع الأمل والنمو والسعادة. تقف كل شجرة بمثابة شهادة على إيماننا بإمكانية تعزيز التغيير الإيجابي، بنشر ابتسامة واحدة في كل مرة.</p><br /><br />
                                                            <p>وهذا مستوحى من رؤية صاحب السمو محمد بن راشد آل مكتوم، يعد مشروعنا بمثابة تقدير صغير ولكنه جاد لتطلعاته. ونكرر التزامه بتخضير دولة الإمارات العربية المتحدة، ونقلده في سعيه لتحقيق عالم أكثر استدامة وجمالاً.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-4" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 4)}>
                                                            <span className="accordion-title">نبضغالف تيكت: أمل،أحلام،ابتسامات</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>غالف تيكتليست مجرديانصيب عبر الإنترنت؛ إنها رحلة مليئة بالأمل والأحلام والقدرة على نشر الابتسامات. نحن هنا لنثبت أن تذكرة اليانصيب ليست مجرد تذكرة للفوز؛ بل هي تذكرة للإيمان والطموح ونشر الفرح. وبينما نمضي قدمًا، ندعوكم للانضمام إلينا، ليس بصفتكم مشاركين فقط، ولكن بصفتكم أيضًا مؤمنين بعالم قد يؤدي كل حلم فيه إلى غد أكثر إشراقًا.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-5" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 5)}>
                                                            <span className="accordion-title">الرسالةوالرؤية</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>غالف تيكيتهي منصة إلكترونية متميزة تستضيف السحب الأسبوعي الأكثر استثنائية، حيث تقدم للمشاركين فرصة لتغيير حياتهم لتحويل أحلامهم إلى حقيقة. يقع مقر غالف تيكيتبكل فخر في دولة الإمارات العربية المتحدة، وهو يتجاوز كونه مركزًا ترفيهيًّا مثيرًا;</p><br /><br />
                                                            <p>فهو يمثل منظمة تعطي أولوية للسي إس آرمع التزام عميق بخدمة المجتمع. تتمحور قيمنا الأساسية حول تمكين الأفراد من عيش حياة صحيةوعيش حياةالفرحوالرفاهية، ولكن الأهم من ذلك هو رسم البسمة على وجوه الناس.</p><br /><br />
                                                            <p>نحن نؤمن بأهمية تخطي الحدود من خلال توفير محتوى مبتكر واستخدام تكنولوجيا متطورة، مما يزود المستهلكين بتجربة مفيدةجدًا في جميع أنحاء دولة الإمارات العربية المتحدة. تماشيًا مع الأخلاقياتالداعمة للمجتمع والبيئة، تشارك شركةغالف تيكيتبنشاط في البرامج الوطنية والإقليمية المشابهة، مما يلهم الوحدة في السعي لبناء غد أفضل.</p><br /><br />
                                                            <p>رحلتنا تقوم علىرغبتنا في إحداث تأثير ملموس على المجتمع، والريادة في تبني الممارسات التجارية المسؤولة والواعية. معغالف تيكيت، ستخوض تجربة تغيرحياتكبشكل جذري وتحقق أحلامك. انضم إلينا في هذه الرحلة الاستثنائية التينصنع فيها لحظات لا تُنسى ونبني مستقبلًا مشرقًا أكثر للجميع.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-6" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 6)}>
                                                            <span className="accordion-title">مهمتنا</span>
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>فيغالف تيكيت، نسعى جاهدين لتقديم منصة لا مثيل لها لتوفير تسلية مثيرة وفرص لتغيير حياة المشاركين مع تعزيز المسؤولية الاجتماعية. من خلال الأحداث والسحوبات الجذابة، نهدف إلى بعث الشعور بالمسؤولية المشتركة، وتمكين الأفراد والمجتمعات من إحداث تأثير إيجابي من أجل مستقبل كوكبنا.</p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button id="accordion-button-7" aria-expanded="false" className="accordian-cont-button" onClick={() => this.loadfaq(this, 7)}>
                                                            <span className="accordion-title">رؤيتنا</span >
                                                            <span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p> تتصور غالف تيكيتمستقبلًا يجمع بينالمتعة وتحمل المسؤولية. نطمح لأن نصبح السحب الأسبوعي الأول عبر الإنترنت، وأن نضع معايير جديدة وندعم التنمية المستدامة. رؤيتنا هي تعزيز ثقافة الوعي الجماعي، والمساهمة في بناء عالم أكثر خضرة وترك إرث إيجابي للأجيال القادمة.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="faqsContainer marginTop3Rem">
                                        <div className="faqsFirstCont">
                                            <img src="./assets/img/circle_about_us.png" alt="circle_about_us icon" />
                                        </div>
                                        <div>
                                            <div className="aboutusHeading">Crafting Dreams, Spreading Smiles</div>
                                            <div className="redDivider"></div>
                                            <div className="aboutusTextCont">
                                                Welcome to Gulf Ticket, where dreams find their wings and smiles are woven into every
                                                opportunity. We aren't just a platform; we're a symphony of hope, a canvas of
                                                possibilities, and a testament to the power of dreams.
                                            </div>
                                            <div className="container-accordian">
                                                <div className="accordion-cont">
                                                    <div className="accordion-item-cont">
                                                        <button
                                                            id="accordion-button-1"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 1)}
                                                        >
                                                            <span className="accordion-title">A Hopeful Odyssey: Forging Beyond Limits</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Our story isn't confined by the boundaries of trends; it's a voyage marked by
                                                                audacious aspirations. In a world brimming with giants, we step forward, not
                                                                just to compete, but to stand as the unrivaled conductor of dreams. Why?
                                                                Because hope is the foundation upon which Gulf Ticket stands – a beacon that
                                                                illuminates every path, transcending obstacles and lighting up futures.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-2"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 2)}
                                                        >
                                                            <span className="accordion-title">More Than Prizes: Planting Seeds of Smiles</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Online Draws, for us, are more than just a game of numbers; they're a medium to sow the seeds of hope. Each ticket isn't just a chance to win; it's a chance to believe, to anticipate, and to spread smiles. With every draw ticket sold, we're not just unveiling potential prizes; we're unveiling dreams – dreams that have the power to change lives and redefine destinies.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-3"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 3)}
                                                        >
                                                            <span className="accordion-title">Spreading Smiles, One Ticket at a Time</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Behind our commitment to greening landscapes lies an even greater commitment –
                                                                the commitment to spread smiles. Through our Palm Tree Plantation Project,
                                                                we're not just planting trees; we're planting hope, growth, and happiness.
                                                                Every tree stands as a testament to our belief that positive change can be
                                                                nurtured, one smile at a time.
                                                                <br /><br />Inspired by the vision of His Highness Mohammed bin Rashid Al
                                                                Maktoum, our project is a small yet earnest tribute to his aspirations. We
                                                                echo his commitment to greening the UAE, echoing his pursuit of a more
                                                                sustainable, beautiful world.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-4"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 4)}
                                                        >
                                                            <span className="accordion-title"
                                                            >The Heartbeat of Gulf Ticket: Hope, Dreams, Smiles</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Gulf Ticket isn't just an online draw; it's a journey infused with hope, dreams, and the power to spread smiles. We're here to prove that every draw isn't just a ticket to win; it's a ticket to believe, to aspire, and to create ripples of joy. As we move forward, we invite you to join us, not just as participants, but as believers in a world where every dream has the potential to shape a brighter tomorrow.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-5"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 5)}
                                                        >
                                                            <span className="accordion-title">Mission and Vision</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Gulf Ticket is a unique online platform that conducts the most unusual weekly
                                                                draws,It offers participants a life-changing opportunity to make their dreams
                                                                come true.Proudly serving as the UAE capital, Gulf Ticket is more than just an
                                                                exciting entertainment destination. <br /><br />It is a CSR-first organization
                                                                with a deep commitment to serving society. Our core values ​​are centered on
                                                                empowering individuals to lead healthy lives, experience joys and luxuries,
                                                                but most importantly, bring smiles to people's faces.<br /><br />We believe in
                                                                pushing the boundaries through content innovation and cutting-edge technology,
                                                                delivering a truly rewarding experience to customers across the UAE. Aligned
                                                                with a pro-social and eco-friendly ethos, Gulf Ticket actively participates in
                                                                like-minded national and regional events, united in striving to build a better
                                                                tomorrow.<br /><br />Our journey is based on our desire to make a tangible
                                                                impact on society, and pioneer the adoption of responsible and conscious
                                                                business practices. With Gulf Ticket, you will have an experience that will
                                                                radically change your life and make your dreams come true. Join us on this
                                                                extraordinary journey as we create unforgettable moments and build a brighter
                                                                future for everyone.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-6"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 6)}
                                                        >
                                                            <span className="accordion-title">Our mission</span><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                At Gulf Ticket, we strive to provide an unparalleled platform for exciting
                                                                entertainment and life-changing opportunities while promoting social
                                                                responsibility. Through engaging events and sweepstakes, we aim to inspire a
                                                                sense of shared responsibility and empower individuals and communities to make
                                                                a meaningful impact on the future of our world.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <button
                                                            id="accordion-button-7"
                                                            aria-expanded="false"
                                                            className="accordian-cont-button"
                                                            onClick={() => this.loadfaq(this, 7)}
                                                        >
                                                            <span className="accordion-title">Our vision</span
                                                            ><span className="icon" aria-hidden="true"></span>
                                                        </button>
                                                        <div className="accordion-content">
                                                            <p>
                                                                Gulf Ticket envisions a future where happiness and responsibility come
                                                                together. We aspire to be the leading online weekly Online draw, setting new
                                                                standards by highlighting sustainable development. Our vision is to foster a
                                                                culture of collective consciousness, contribute to a greener world and leave a
                                                                creative legacy for generations to come.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                    }
                </>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutUs))