import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gameActions } from '../../action/GameAction';
import Header from '../../Header/Header';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';
import { history } from '../../helpers/history';
import { commonClass } from "../../helpers/commonClass";
import { Helmet } from "react-helmet";
import AboutFortureFive from './AboutFortureFive';
import AboutSuperSix from './AboutSuperSix';
import { appVariables } from '../../helpers/appVariables';
import AboutMgicFive from './AboutMgicFive';
import SaleClose from '../SaleClosed/SaleClose';
import AboutLuckyOne from './AboutLuckyOne';
let refecthgamecnt = 0;
let refecthgameallcnt = 1;
const locky1Arr = [1, 2, 5, 10];
let lastCountDownTime = Date.now();
class Lotto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawCompleted: false,
            cartItems: [],
            padCount: 2,//default pad count
            padCountmax: 31,//max pad count
            lottoType: props.lottotype, //9-fortune5, 10-super6
            random: [],
            myfavNoID: 0,
            otherGameCount: 0,
            OldcartItems: [],
            datafatchCompleted: false,
            CartmaxLength: 50,
            showSaleCloseInfo: false,
            myUCDraw: []
        };
        const did = localStorage.getItem('lid');
        //props.ClearCart();
        props.closeBetSlip();
        let tmr = setInterval(
            () => {
                if (this.props.serverTime && this.props.serverTime > 0) {
                    clearInterval(tmr);
                    props.GameAvailableGames(props.user.mobileNo, this.state.lottoType, props.i18n.language, this.props.serverTime, this.props.user.loginKey);
                    if (props.user.mobileNo != 0) {
                        props.GetMyFavNumbers(this.state.lottoType, props.user.mobileNo, props.i18n.language, this.props.user.loginKey);
                    }
                }
            }
            , 1000)
    }

    static getDerivedStateFromProps(props, state) {
        return {
            currentAvailableDraws: props.currentAvailableDraws, // game loading performance changes.
            currentDraw: props.currentDraw,
            MyFavNos: props.MyFavNos,
        }
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0 && this.state.datafatchCompleted == false) {
                this.state.datafatchCompleted = true;
            }
        }, 1000); //5000

        let Timeinntervl = setInterval(() => {
            if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.lottoType && a.SaleStatus != 1).length <= 1 && this.state.lottoType == 13 && refecthgameallcnt < 3) {
                this.reloadNextDraw();
                if (this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.lottoType && a.SaleStatus != 1).length > 0) {
                    clearInterval(Timeinntervl);
                }
            }
            else {
                clearInterval(Timeinntervl);
            }
            refecthgameallcnt++;
        }, 1500 * refecthgameallcnt);
        //let mythis = this;
        // window.addEventListener("visibilitychange", function () { mythis.handleFocus(); })
    };
    handleFocus = () => {
        try {
            if (document.visibilityState == "visible") {
                console.log("lastCountDownTime", lastCountDownTime)
                let newCountDownTime = Date.now();
                let diffCountDownTime = newCountDownTime - lastCountDownTime
                console.log('diffCountDownTime', diffCountDownTime)
                if (diffCountDownTime > 300000) { //time difference in 5min (300000)           
                    window.location.reload();
                }
            }
        }
        catch (ex) {
            console.log("document visibilityState", ex)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0 && this.state.currentDraw.SaleStatus == 0 && this.state.datafatchCompleted == false) {
            this.state.datafatchCompleted = true;
            this.createGamepad(this.state.padCount);
            this.LoadReBetNumber();
            this.loadPrevCartItem();
            this.loadSelectFavNo();
            let cobj = {};
            let Cdraw = [this.state.currentDraw];
            // if (!this.state.myUCDraw || this.state.myUCDraw.length == 0) {
            //     this.state.myUCDraw = [this.state.currentDraw.GameId];
            // }
            commonClass.SendEventTracking(11, cobj, false, Cdraw);
        }
        else {
            this.reFetchTheGameData(); // game loading performance changes.
        }
        //default draw selection issue solved
        if (this.state.currentDraw && this.state.currentDraw.gamegroupID == 13 && this.state.currentDraw.SaleStatus == 0) {
            if (!this.state.myUCDraw || this.state.myUCDraw.length == 0) {
                this.state.myUCDraw = [this.state.currentDraw.GameId];
            }
        }
    }
    reFetchTheGameData = () => {
        if (this.state.datafatchCompleted == false) {
            if ((!this.state.currentDraw || (Object.keys(this.state.currentDraw).length == 0) || this.state.currentDraw.SaleStatus == 1) && refecthgamecnt < 2) {
                console.log("refecthgamecnt " + refecthgamecnt);
                let mythis = this;
                refecthgamecnt++;
                let regame = setInterval(() => {
                    mythis.props.GameAvailableGames(mythis.props.user.mobileNo, this.state.lottoType, mythis.props.i18n.language, mythis.props.serverTime, mythis.props.user.loginKey);
                    clearInterval(regame);
                }, (1000 * refecthgamecnt));
            }
            else {
                this.state.datafatchCompleted = true;
                this.setState({ datafatchCompleted: true });
            }
        }
    };
    createGamepad = (addpadcount) => {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0) {
            if (addpadcount > 0) {
                let _draw = this.state.currentDraw;
                //let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;

                for (let i = 1; i <= addpadcount; i++) {
                    let tarr = []
                    for (let i = 1; i <= _draw.no_of_balls.split('~')[2]; i++) {
                        tarr.push('?');
                    }
                    //_maxid++;
                    const min = 0;
                    const max = 99999;
                    let _maxid = min + (Math.random() * (max - min));
                    let mybet = {
                        GamePadId: _maxid, BetSelection: tarr, stakeValue: 0, GameId: _draw.GameId,
                        RateInPs: _draw.RateInPs, gamegroupID: _draw.gamegroupID, StakeMulti: _draw.StakeMulti,
                        total: (_draw.minStake * _draw.RateInPs), no_of_balls: _draw.no_of_balls,
                        LotName: _draw.LotName, DrawDateTime: _draw.DrawDateTime, DrawTime: _draw.DrawTime,
                        minStake: _draw.minStake, maxStake: _draw.maxStake, betComplete: false, DrawShowTime: _draw.DrawDate + " " + _draw.DrawShowTime,
                        SaleCloseTimeMSec: _draw.SaleCloseTimeMSec, OddsVal: (_draw.gamegroupID == 13 ? 1 : 0)
                    };
                    this.state.cartItems.push(mybet)
                }
            }
            else {
                let dd = this.state.cartItems.pop();
                for (let i = 1; i < Math.abs(addpadcount); i++) {
                    this.state.cartItems.pop();
                }
                this.state.cartItems[this.state.cartItems.length - 1] = dd;
            }
        }
    }
    showMsg = (msg, flag) => {
        this.props.alertError(this.props.t(msg), flag);
    }
    createBall = (x) => {
        let darr = x.no_of_balls.split('~');
        let GameId = x.GamePadId;
        // let sballno = darr[0];
        // let eballno = darr[1];
        let sballno = parseInt(darr[0]);
        let eballno = parseInt(darr[1]);
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            //i = ("0" + i).slice(-2);
            let b = "" + i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall(i, x) }}><a className={`${x.BetSelection.filter(y => y == i).length > 0 ? `game-${this.state.lottoType}-active` : ''}`}>{i}</a></li>)
        }
        return ballLst;
    }

    selMulti_lucy1 = (x, mval, fromoval) => {
        x.OddsVal = mval;
        if (fromoval == 1) {
            document.getElementById('txtmtpl').value = '';
        }
    }
    selMulti_lucy1_sub = (x) => {
        const mval = locky1Arr[0];
        document.getElementById('txtmtpl').value = '';
        this.selMulti_lucy1(x, mval);
    }
    multiKup = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    multiKyblur = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    createBall_lucy1 = (x) => {
        // <li><a href="#" className="game-13-balls-1"><span>1</span></a></li>
        // <li><a href="#" className="game-13-balls-2 game-13-active"><span>2</span></a></li>
        let darr = x.no_of_balls.split('~');
        let GameId = x.GamePadId;
        let sballno = parseInt(darr[0]);
        let eballno = parseInt(darr[1]);
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            let b = "" + i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall_lucy1(i, x) }}><a className={`game-13-balls-${i} ${x.BetSelection.filter(y => y == i).length > 0 ? `game-${this.state.lottoType}-active` : ''}`}><span>{i}</span></a></li>)
        }
        return ballLst;
    }
    selectBall_lucy1 = (b_id, x) => {
        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                // if (x.BetSelection[inx] == b_id) { //old lotto choose Per ticket One Zodiac Symbol.
                //     x.BetSelection[inx] = '?';
                //     x.BetSelectionSort = '' // qucik pick selection duplicate number issue.
                //     break;
                // }
                if (x.BetSelection[inx] == b_id) {
                    if (x.BetSelection.length == 1) {
                        x.BetSelection[inx] = '?';
                    }
                    else {
                        x.BetSelection.splice(inx, 1);
                    }
                    break;
                }
            };
        }
        else {
            // for (let inx = 0; inx < x.BetSelection.length; inx++) {
            //     x.BetSelection[inx] = b_id;
            // } //old lotto choose Per ticket One Zodiac Symbol.
            if (x.BetSelection[0] == "?") {
                x.BetSelection[0] = b_id;
            }
            else {
                x.BetSelection.push(b_id);
            }
        }
        if (x.BetSelection.filter(a => a == '?').length <= 0) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            x.BetSelectionSort = jnew;
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.cartItems;
        this.setState({ cartItems: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
        }
        if (x.stakeValue > 0) {
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }
    quickPick_lucy1 = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        // x.BetSelection = ['?']
        // this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        let samel = [];
        // Per Total sign  selected Not working.
        if (x.BetSelection.length < eballno) {
            for (var k = sballno; k <= QPBallCount; k++) {
                let _bal = this.generateRandomInteger(x);
                samel = x.BetSelection.filter(a => a == _bal)
                if (samel.length > 0) {
                    QPBallCount = parseInt(QPBallCount) + 1;
                    samel = [];
                }
                else {
                    this.selectBall_lucy1(_bal, x);
                }
            }
        }
    }
    selectBall = (b_id, x) => {
        // let x=this.state.cartItems.filter(a=>a.GamePadId=x1.GamePadId && a.gamegroupID==x1.gamegroupID);

        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                if (x.BetSelection[inx] == b_id) {
                    x.BetSelection[inx] = '?';
                    x.BetSelectionSort = '' // qucik pick selection duplicate number issue.
                    break;
                }
            };
            //x.BetSelection = x.BetSelection.filter(a => (a != b_id));
        }
        else {
            if (x.BetSelection.filter(a => a != '?').length >= darr) {
                this.props.alertError(this.props.t("lblMaxSelect") + darr + '.');
                // alert(this.props.t('lblMaxSelect') + darr);
                return;
            }
            else {
                for (let inx = 0; inx < x.BetSelection.length; inx++) {
                    if (x.BetSelection[inx] == "?") {
                        x.BetSelection[inx] = b_id;
                        break;
                    }
                };
            }
        }
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            let noexits = this.state.cartItems.filter(a => a.BetSelectionSort == jnew);
            if (noexits.length <= 0) {
                let prevCartItem = localStorage.getItem('mycart') != '' ? JSON.parse(localStorage.getItem('mycart')) : null;
                if (prevCartItem) {
                    let noexits1 = prevCartItem.filter(a => a.BetSelectionSort == jnew && a.gamegroupID == this.state.lottoType);
                    if (noexits1.length <= 0) {
                        x.BetSelectionSort = jnew;
                        x.stakeValue = x.minStake;
                    }
                    else {
                        for (let inx = 0; inx < x.BetSelection.length; inx++) {
                            if (x.BetSelection[inx] == b_id) {
                                x.BetSelection[inx] = '?';
                                break;
                            }
                        };
                        this.showMsg(this.props.t('lblDuplicateNoSel'));
                        return;
                    }
                }
                else {
                    x.BetSelectionSort = jnew;
                    x.stakeValue = x.minStake;
                }
            }
            else {
                for (let inx = 0; inx < x.BetSelection.length; inx++) {
                    if (x.BetSelection[inx] == b_id) {
                        x.BetSelection[inx] = '?';
                        break;
                    }
                };
                this.showMsg(this.props.t('lblDuplicateNoSel'))
            }
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.cartItems;
        this.setState({ cartItems: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
        }
        if (x.stakeValue > 0) {
            // this.showMsg('lblnumberselsuc'); //Show After Number Select Msg
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }

    reCount = (rundraw, iSdraw) => {
        if (iSdraw == 0 && !this.state.datafatchCompleted) {
            return <div className='text-white'>{this.props.t('lblLoading')}</div>;
        }
        if (rundraw == null || Object.keys(rundraw).length == 0) {
            return <div className='text-white'>{this.props.t('lblDrawClosed')}</div>;
        }
        else {
            this.state.coundownStart = 1;
            return <><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime(rundraw)} onComplete={this.countdownCompleted} renderer={this.renderer} /> </>;
        }
    };

    getGameTime = (rundraw) => {
        lastCountDownTime = Date.now();
        let _date = rundraw ? rundraw.DrawDateTime : "";
        if (_date != undefined && _date != "") {
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
            //console.log("getGameTime : " + sTime);
            //console.log("DateNow : " + Date.now());
            //if (rundraw.SaleStatus == 0) { ////0-open,1-closed, sale closeTime implemented
            let SaleCloseTime = sTime - rundraw.SaleCloseTimeMSec;
            let SaleStatusRemaining = SaleCloseTime - this.props.serverTime;
            let SalesClo = (SaleStatusRemaining >= 0 ? 0 : 1);
            if (SalesClo != rundraw.SaleStatus) {
                rundraw.SaleStatus = SalesClo;
                if (rundraw.gamegroupID == 13) {
                    setTimeout(() => {
                        this.reloadNextDraw()
                    }, 1500);
                }
            }
            //}
            return sTime;
        }
        else {
            return 0;
        }
    };

    reloadNextDraw = () => {
        refecthgamecnt = 0;
        this.state.datafatchCompleted = false
        this.state.myUCDraw = [];
        this.reFetchTheGameData();
        setTimeout(() => {
            let mythis = this;
            mythis.props.GameAvailableGames(mythis.props.user.mobileNo, 0, mythis.props.i18n.language, mythis.props.serverTime, mythis.props.user.loginKey);
        }, (500));
    }

    renderer = ({ total, days, hours, minutes, seconds, completed }) => {
        let retval
        if (completed) {
            // retval = <li><div>{this.props.t('lblDrawClosed')}</div></li>; After Game Close Text Large Problem
            retval = <div className='text-white'>{this.props.t('lblDrawClosed')}</div>;
        } else {
            if (days > 0) {
                retval = <>
                    <li><div>{zeroPad(days)}</div><span>:</span><p>Days</p></li>
                    <li><div>{zeroPad(hours)}</div><span>:</span><p>Hours</p></li>
                    <li><div>{zeroPad(minutes)}</div><span>:</span><p>Minutes</p></li>
                    <li><div>{zeroPad(seconds)}</div><span></span><p>Seconds</p></li>
                </>;
            }
            else {
                retval = <>
                    <li><div>{zeroPad(hours)}</div><span>:</span><p>Hours</p></li>
                    <li><div>{zeroPad(minutes)}</div><span>:</span><p>Minutes</p></li>
                    <li><div>{zeroPad(seconds)}</div><span></span><p>Seconds</p></li>
                </>;
                if (this.state.showSaleCloseInfo == false) { this.state.showSaleCloseInfo = true }
            }
        }
        return retval;
    };
    countdownCompleted = () => {
        //this.RefreshAfterGameClose();        
        this.state.isDrawCompleted = true;
    };

    clearCart = (x) => {
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
        this.setState({ myfavNoID: 0 });
    };

    clearCartAll = (x) => {
        this.state.cartItems = [];
        if (this.state.currentDraw && this.state.cartItems.length == 0) {
            this.createGamepad(this.state.padCount);
        }
        this.setState({ myfavNoID: 0 })
    };

    ballPadGenerate = (flog, cnt) => {
        //unuse code
        // if (this.state.cartItems && this.state.cartItems.SaleStatus == 1) {
        //     return;
        // }
        if (flog == "+") {
            cnt = (cnt ? cnt : 1);
            let MyCartTicket = JSON.parse(localStorage.getItem('mycart'));
            let MyCartCount = MyCartTicket == null ? 0 : MyCartTicket.filter(Obj => Obj.gamegroupID == this.state.lottoType).length
            let MaxTicketCount = this.state.padCountmax - MyCartCount
            if (this.state.cartItems.length + 1 > MaxTicketCount) {
                // if (this.state.cartItems.length + 1 > this.state.padCountmax) {
                this.props.alertError(this.props.t('lblMaximumstake'));
                // return;
            }
            else { this.createGamepad(cnt) }
        }
        else if (flog == "-") {
            cnt = (cnt ? cnt : -1)
            if (this.state.cartItems.length - 1 < this.state.padCount) {
                //this.props.alertError(this.props.t('lblMinimumstake') + " : " + padCountmax);
                return
            }
            else { this.createGamepad(cnt) }
        }
    };
    gotoMyCart = () => {
        if (this.state.otherGameCount > 0) {
            history.push('/mycart');
        }
        else {
            this.showMsg('lblCartempty');
        }
    }
    addMyCart = () => {
        if (this.state.currentDraw && this.state.currentDraw.SaleStatus == 1) {
            this.showMsg('lblclosed');
            return;
        }
        let bervalidate = true;
        // if (this.props.user == undefined || this.props.user.mobileNo == "0") {
        //     this.showMsg('lblPlsLoginOrSignUp', 1);
        //     bervalidate = false;
        //     return;
        // }
        //due to user can view empty cart.
        // if (this.state.cartItems.length == 0 && this.state.otherGameCount == 0) {
        //     bervalidate = false;
        //     this.showMsg(this.props.t('lblplcurbet'));
        //     return;
        // }
        for (let i = 0; i < this.state.cartItems.length; i++) {
            let x = this.state.cartItems[i];
            if (i == this.state.cartItems.length - 1) { // to skip last one
                continue;
            }
            let ecnt = x.BetSelection.filter(a => a != '?').length;
            if (ecnt > 0 && ecnt != x.no_of_balls.split('~')[2] && x.gamegroupID != 13) {
                this.showMsg('lblplcurbet');
                bervalidate = false;
            }
        }
        if (bervalidate) {
            if (this.state.otherGameCount < this.state.CartmaxLength) {
                let ccn = this.state.cartItems.filter(x => x.BetSelection.filter(a => a != '?').length > 0)
                if (ccn == 0) {
                    {
                        this.showMsg('lblplcurbet');
                        return;
                    }
                }
                let mycart = null;
                for (let i = 0; i < this.state.cartItems.length; i++) {
                    if (i == this.state.cartItems.length - 1) { // to skip last one
                        continue;
                    }
                    else if (this.state.cartItems[i].BetSelection.filter(a => a == '?').length > 0) {  // to skip uncompleted bet
                        continue;
                    }
                    if (this.state.cartItems[i].OddsVal <= 0 && this.state.cartItems[i].gamegroupID == 13) {
                        continue;
                    }
                    let oitem = this.state.OldcartItems.filter(a => a.GamePadId == this.state.cartItems[i].GamePadId); if (oitem && oitem.length > 0) {
                        this.props.UpdateCartAll(this.state.cartItems[i]);
                        //this.props.AddToCart(this.state.cartItems[i]);
                    }
                    else {
                        if (mycart == null) {
                            mycart = JSON.parse(localStorage.getItem('mycart'));
                        }
                        let item = mycart && mycart.filter(Fobj => Fobj.BetSelectionSort == this.state.cartItems[i].BetSelectionSort && Fobj.gamegroupID == this.state.cartItems[i].gamegroupID).length;
                        if (item == null || item == undefined || item == 0 || this.state.cartItems[i].gamegroupID == 13) {
                            if (this.state.cartItems[i].gamegroupID == 13) {
                                this.state.cartItems[i].stakeValue = this.state.cartItems[i].minStake * this.state.cartItems[i].OddsVal;
                                //multi draw implementation
                                if (this.state.lottoType == 13 && this.state.myUCDraw && this.state.myUCDraw.length == 1) {
                                    let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                                    if (fucd && fucd.length > 0) {
                                        let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                                        if (_draw && _draw.length > 0) {
                                            _draw = _draw[0];
                                            this.state.cartItems[i].GameId = _draw.GameId;
                                            this.state.cartItems[i].DrawDateTime = _draw.DrawDateTime;
                                            this.state.cartItems[i].DrawTime = _draw.DrawTime;
                                            this.state.cartItems[i].DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                                            this.state.cartItems[i].SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                                        }
                                    }

                                }
                            }
                            if (this.state.cartItems[i].gamegroupID != 13) {
                                this.props.AddToCart(this.state.cartItems[i]);
                            }
                            else {
                                this.AddCartLucky1(this.state.cartItems[i])
                            }
                            //multi draw implementaion
                            if (this.state.lottoType == 13 && this.state.myUCDraw && this.state.myUCDraw.length > 1) {
                                let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                                if (fucd && fucd.length > 0) {
                                    let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                                    if (_draw && _draw.length > 0) {
                                        let cartItemsM = { ...this.state.cartItems[i] }
                                        _draw = _draw[0];
                                        let _maxid = 0 + (Math.random() * (99999 - 0));
                                        // cartItemsM.GamePadId = _maxid;
                                        cartItemsM.GameId = _draw.GameId;
                                        cartItemsM.DrawDateTime = _draw.DrawDateTime;
                                        cartItemsM.DrawTime = _draw.DrawTime;
                                        cartItemsM.DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                                        cartItemsM.SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                                        this.AddCartLucky1(cartItemsM)
                                        // this.props.AddToCart(cartItemsM);
                                    }
                                }

                            }
                        }
                    }
                }
                let cobj = {};
                let tcartitem = this.state.cartItems.filter(Fobj => Fobj.BetSelectionSort != undefined)
                commonClass.SendEventTracking(9, cobj, false, tcartitem);
                let prev_cartItem = [];
                localStorage.setItem('prevCartItem', prev_cartItem);
                this.showMsg('lblAddCartsucss');
                if (this.props.user == undefined || this.props.user.mobileNo == "0") {
                    let mobno = 0;
                    localStorage.setItem('lastloginuser', mobno);
                    setTimeout(() => {
                        this.showMsg('lblPlsLoginOrSignUp', 1);
                    }, 500);
                    bervalidate = false;
                    return;
                }
                else {
                    setTimeout(() => {
                        history.push('/mycart');
                    }, 1000);
                }
            }
            else {
                this.showMsg('lblMaxLen')
            }
        }
    }


    quickPick = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        for (var k = sballno; k <= QPBallCount; k++) {
            let _bal = this.generateRandomInteger(x);
            this.selectBall(_bal, x);
        }
    }

    AddCartLucky1 = (Obj) => {
        let lcartl = Obj.BetSelection.length;
        for (var j = 0; j <= lcartl - 1; j++) {
            let _maxid = 0 + (Math.random() * (99999 - 0));
            let storecart = { ...Obj };
            storecart.BetSelection = [storecart.BetSelection[j]];
            storecart.GamePadId = _maxid;
            storecart.BetSelectionSort = [storecart.BetSelection[j]];
            this.props.AddToCart(storecart);
        }
    }

    //  Only Increase Odds Value 
    // MultiSetOddsValue = (Obj) => {
    //     let lcartl = Obj.BetSelection.length;
    //     let Existingcart = JSON.parse(localStorage.getItem('mycart'));
    //     Existingcart = Existingcart.filter(fobj => fobj.gamegroupID == Obj.gamegroupID && fobj.GameId == Obj.GameId);
    //     for (var j = 0; j <= lcartl - 1; j++) {
    //         let _maxid = 0 + (Math.random() * (99999 - 0));
    //         let storecart = { ...Obj };
    //         let _stored_inx = null;
    //         let _update_cart = []
    //         Existingcart.forEach((element, inx) => {
    //             if (element.BetSelection.filter(a => a == storecart.BetSelection[j]).length > 0) {
    //                 _stored_inx = inx;
    //             }
    //         });
    //         if (_stored_inx != null) {
    //             _update_cart = Existingcart[_stored_inx];
    //             _update_cart.OddsVal = storecart.OddsVal + _update_cart.OddsVal;
    //             this.props.UpdateCartAll(_update_cart);
    //         }
    //         else {
    //             storecart.BetSelection = [storecart.BetSelection[j]];
    //             storecart.GamePadId = _maxid;
    //             storecart.BetSelectionSort = [storecart.BetSelection[j]];
    //             this.props.AddToCart(storecart);
    //         }
    //     }
    // }


    generateRandomInteger = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        var min = sballno;
        var max = parseInt(eballno) + 1;
        if (this.state.random.length >= maxballno) {
            this.state.random = [];
        }
        let valFound = false;
        while (valFound == false) {
            for (var i = min; i <= max; i++) {
                var temp = Math.floor(max - Math.random() * (max - min));
                //temp = ("0" + temp).slice(-2);
                temp = "" + temp;
                if (temp && this.state.random.indexOf(temp) == -1) {
                    this.state.random.push(temp);
                    valFound = true;
                    return temp;
                }
            }
        }
    };
    setIsShown = (x, flog) => {
        if (x.BetSelection && x.BetSelection.filter(a => a != '?').length == x.no_of_balls.split('~')[2]) {
            x.betComplete = flog;
        }
        else {
            x.betComplete = false;
        }
    }
    saveMyFavNo = (x) => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblplsloginTry', 1);
            return;
        }
        if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
            this.showMsg('lblplcurbet');
            return;
        }
        let no = (x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')
        this.props.SaveMyFavNumbers(this.state.myfavNoID, this.state.currentDraw.gamegroupID, no, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
        let uObj = {};
        let SavemyNo = [x]
        commonClass.SendEventTracking(12, uObj, false, SavemyNo);
        this.setState({ myfavNoID: 0 })
        setTimeout(() => {
            this.props.GetMyFavNumbers(this.state.lottoType, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        }, 500)
    }
    setMyFavNo = (e, x) => {
        if (e.target.value == "-1") {
            this.clearCart(x)
            return;
        }
        let ss = this.state.MyFavNos[e.target.value];
        let no = ss.numbers.split(';');
        x.BetSelection = no.filter(a => a != '?').map((_no, inx) => "" + parseInt(_no));
        let darr = x.no_of_balls.split('~')[2];
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        this.setState({ myfavNoID: ss.favorite_id })
    }
    LoadReBetNumber = () => {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0) {
            let _no = localStorage.getItem('rebetno');
            if (_no) {
                if (_no.split("~")[0] != this.state.currentDraw.gamegroupID) {
                    localStorage.removeItem('rebetno')
                    return;
                }
                let _noArr = _no.split("~")[1]
                _noArr = _noArr.split("|")
                // let _noArr = _no.split("|")
                let len = _noArr.length - (this.state.cartItems.length - 1);
                for (let i = 0; i < len; i++) {
                    this.createGamepad(1);
                }
                len = this.state.cartItems.length - 1;
                for (let i = 0; i < len; i++) {
                    this.state.cartItems[i].BetSelection = _noArr[i].split(';').map(Obj => parseInt(Obj) + "");
                    this.state.cartItems[i].stakeValue = this.state.currentDraw.minStake;
                    this.state.cartItems[i].betComplete = true;
                    // Multiple Rebet issue 
                    var arr2 = JSON.stringify(this.state.cartItems[i].BetSelection);
                    arr2 = JSON.parse(arr2);
                    let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
                    let jnew = sbet.map((e, c) => e).join(';')
                    this.state.cartItems[i].BetSelectionSort = jnew;
                }
                localStorage.removeItem('rebetno')
            }
        }
    }
    removeGamePad = (removeinx) => {
        if (this.state.cartItems.length == 2) {
            return;
        }
        else {
            let updateCart = this.state.cartItems.filter((data, inx) => {
                return inx != removeinx;
            });
            this.setState({ cartItems: updateCart });
        }
    }
    loadPrevCartItem = () => {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0) {
            //let prevCartItem = localStorage.getItem('prevCartItem') != '' ? JSON.parse(localStorage.getItem('prevCartItem')) : null;
            let prevCartItem = localStorage.getItem('mycart') != '' ? JSON.parse(localStorage.getItem('mycart')) : null;
            if (prevCartItem) {
                this.state.otherGameCount = prevCartItem.length;
                return
                //for get the other game betslip value..                
                let prevCartItem1 = prevCartItem.filter(a => a.gamegroupID != this.state.lottoType);
                this.state.otherGameCount = prevCartItem1.length;
                localStorage.setItem('mybet', JSON.stringify(prevCartItem1));
                prevCartItem = prevCartItem.filter(a => a.gamegroupID == this.state.lottoType);

                let d = []; //this.state.cartItems;
                let d1 = this.state.cartItems.pop();
                for (let i = 0; i < prevCartItem.length; i++) {
                    d.push(prevCartItem[i]);
                }
                let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;
                d1.GamePadId = _maxid + 1;
                d.push(d1);
                this.setState({ cartItems: d });
                this.state.OldcartItems = prevCartItem;
            }
        }
    }
    loadSelectFavNo = () => {
        let gameNo = localStorage.getItem('setfavNo');
        gameNo = gameNo && gameNo.split('~')
        if (gameNo && gameNo[1] && this.state.cartItems && this.state.cartItems.length > 0 && gameNo[0] == this.state.currentDraw.gamegroupID) {
            this.state.cartItems[0].BetSelection = gameNo[1].split(';').map(Obj => parseInt(Obj) + "");
            this.state.cartItems[0].stakeValue = this.state.currentDraw.minStake;
            this.state.cartItems[0].betComplete = true;
            localStorage.removeItem('setfavNo')
        }
        if (gameNo && gameNo[0] && gameNo[0] != this.state.currentDraw.gamegroupID) {
            localStorage.removeItem('setfavNo')
        }
        //add more pad
        let gameNo1 = localStorage.getItem('setAddPad');
        if (gameNo1 && this.state.cartItems && this.state.cartItems.length > 0) {
            localStorage.removeItem('setAddPad')
            this.state.cartItems[0].BetSelection = gameNo1.split(';');
            this.state.cartItems[0].stakeValue = this.state.currentDraw.minStake;
            if (this.state.cartItems[0].BetSelection && this.state.cartItems[0].BetSelection.filter(a => a != '?').length == this.state.cartItems[0].no_of_balls.split('~')[2]) {
                this.state.cartItems[0].betComplete = true;
            }
            else {
                this.state.cartItems[0].betComplete = false;
            }
            let _mycat = this.state.cartItems;
            let d = { ...this.state.cartItems[this.state.cartItems.length - 1] };
            let d1 = _mycat.pop();
            let betball = [];
            for (let i = 1; i <= d.BetSelection.length; i++) {
                betball.push('?');
            }
            d.BetSelection = betball;
            _mycat.push(d);
            let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;
            d1.GamePadId = _maxid + 1;
            _mycat.push(d1);
            this.setState({ cartItems: _mycat });
        }

    }
    AboutGame = () => {
        document.getElementById('about-me').scrollIntoView({ behavior: 'smooth' });
    }

    addCustomizePad_b = (_txtthis) => {
        this.setState({ txtShow: false });
    }
    addCustomizePad_k = (_txtthis) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (this.state.padCountmax <= _txtthis.currentTarget.value) {
            _txtthis.currentTarget.value = this.state.cartItems.length - 1;
            this.state.txtPadCount = _txtthis.currentTarget.value;
        }
        else {
            this.state.txtPadCount = _txtthis.currentTarget.value;
        }
        if (0 < this.state.txtPadCount) {
            //this.state.txtPadCount = this.state.padCount;
            this.ballPadGenerate_temp();
        }
    }
    ballPadGenerate_temp = () => {
        if (this.state.txtPadCount > 0) {
            let dd = this.state.txtPadCount - (this.state.cartItems.length - 1);
            this.setState({ txtPadCount: 0 });
            if (dd > 0) {
                this.ballPadGenerate("+", dd);
            }
            else if (dd < 0) {
                this.ballPadGenerate("-", dd);
            }
        }

    }


    SelectUpcomeDraw = (x) => {
        if (this.state.myUCDraw) {
            const fobj = this.state.myUCDraw.filter(a => a == x.GameId);
            if (fobj.length > 0) {
                if (this.state.myUCDraw.length > 1)
                    this.state.myUCDraw = this.state.myUCDraw.filter(a => a != x.GameId);
            }
            else {
                let tlst = this.state.myUCDraw
                tlst.push(x.GameId);
                this.setState({ myUCDraw: tlst })
            }
        }
        else {
            this.state.myUCDraw = [x.GameId]
        }
    };

    render() {
        console.log(this.state.cartItems)
        const { t } = this.props;
        //let gameclor = ((this.state.currentDraw && this.state.currentDraw.gamegroupID == 10) ? "bg-game-10 text-nowrap text-white" : (this.state.currentDraw && this.state.currentDraw.gamegroupID == 11) ? "bg-game11 text-nowrap text-white" : "bg-game9 text-nowrap text-white")
        // let btncolor = ((this.state.currentDraw && this.state.currentDraw.gamegroupID == 10) ? "game2BackgroundColor" : "Bg")
        let iSdraw = 0;
        // if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0) {
        //     let s = this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.lottoType);
        //     if (s.length > 0) { iSdraw = 9; }
        // }
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 0) {
            iSdraw = this.state.currentDraw.gamegroupID;
        }
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <>
                    {this.state.lottoType == 9 ? <Helmet>
                        <title>Gulf Ticket's Fortune 4: Your Gateway to AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} Million for AED {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount}</title>
                        <meta name="description" content="Embark on a journey of excitement with Gulf Ticket's Fortune 4 lottery, offering a life-changing AED 200,000 prize for just AED 10." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Fortune 4, AED 200,000, AED 10, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content={`Gulf Ticket's Fortune 4: Your Gateway to AED ${appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} Million for AED ${appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount}`} />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 4 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/fortune-4-logo.png" />
                    </Helmet > : this.state.lottoType == 10 ? <Helmet>
                        <title>Gulf Ticket's Super 6: Your Chance to Win AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million for AED {appVariables.PRIZE_STRUCT_SUPER6.BetAmount}</title>
                        <meta name="description" content="Experience the thrill of becoming a Gulf Ticket Super 6 millionaire with a staggering AED 100 million prize for just AED 30." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Super 6, AED 100 million, AED 30, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content={`Gulf Ticket's Fortune 5: Your Gateway to AED ${appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million for AED ${appVariables.PRIZE_STRUCT_SUPER6.BetAmount}`} />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 5 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/super-6-logo.png?v0.2" />
                    </Helmet >  : <Helmet>
                        <title>Gulf Ticket's Magic 5: Your Chance to Win AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} for AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount}</title>
                        <meta name="description" content="Experience the thrill of becoming a Gulf Ticket magic 5 millionaire with a staggering AED 500,000 prize for just AED 5." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Magic 5, AED 500,000, AED 5, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content="Experience the thrill of becoming a Gulf Ticket magic 5 millionaire with a staggering AED 500,000 prize for just AED 5." />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 5 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/magic-5-logo.png?v0.2" />
                    </Helmet >}</>}
                <Header />
                <div className="wrap2">
                    <div className="buyTop">
                        <div className="col-md-4 col-sm-4 col-xs-12 buyTopCont">
                            <div className="butTopTitleCont">
                                <div className={`${this.state.lottoType != 13 ? 'w-full' : 'butTopTitle'}`}>
                                    {
                                        this.state.lottoType == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt='fortune-4 logo' />
                                            : this.state.lottoType == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt='super-6-logo' />
                                                : this.state.lottoType == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png?v0.1" alt="lucky1-logo" />
                                                    : <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt='magic-5-logo' />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 className="mainDrawWinnerText margin0">{t('lblNextDraw')}</h1>
                    <div className={`${this.state.lottoType == 13 ? 'lckbuyTimer' : 'buyTimer'} border-game${this.state.lottoType} border-width2`}>

                        {this.state.lottoType != 13 && <div className="buyTimerTxt flexCenter minHeight9">
                            {this.state.lottoType != 13 &&
                                <p>
                                    {this.state.currentDraw && this.state.currentDraw.RateInPs && <> <span className="buyTextFont">{this.props.appvariables.currency}</span> <br />
                                        <span className="buyHeadFont">{this.state.currentDraw && this.state.currentDraw.RateInPs && this.state.currentDraw.RateInPs.replace('#', t('lblMillion'))} *</span></>}
                                </p>
                            }
                        </div>}
                        <div className={`${this.state.lottoType == 13 ? 'lckbuyTimerCont' : ''}`}>
                            <div className={`dateCountDownSection minHeight9 ${this.state.lottoType == 13 ? '' : 'flexCenter flexCol'}`}>
                                {this.state.currentDraw && this.state.currentDraw.DrawShowTime && <div className={`dateTopSection text-game${this.state.lottoType}`}>{this.state.currentDraw.DisplayDateTime} {this.state.currentDraw.DrawShowTime} (UAE)</div>}
                                {this.state.currentDraw && this.state.currentDraw.SaleStatus != 1 && <>{this.state.currentDraw.SaleCloseTimeMSec > 0 && this.state.showSaleCloseInfo && <div style={{ textAlign: 'center', marginTop: "-22px" }} className={`adjustDate text-game${this.state.lottoType}`}>{t('lblsalebfr')} {this.state.currentDraw.SaleCloseTimeMSec / (60 * 1000)} {t('lblminutes')}</div>}</>}
                                <div className={`${this.state.lottoType != 13 ? '' : 'lckTimerAndNxtDrawBtn'}`}>
                                    <ul className={`dateCoundown bg-game${this.state.lottoType} ${this.state.lottoType == 13 ? 'dateCoundownLckInrGame' : ''}`}>
                                        {this.reCount(this.state.currentDraw, iSdraw)}
                                    </ul>

                                    <div className="lckGameRhtCont2-1 lckGameRhtContMar">
                                        {this.state.currentAvailableDraws.filter(draw => draw.gamegroupID == this.state.lottoType && draw.SaleStatus == 0).length > 1 && this.state.lottoType == 13 && this.state.currentAvailableDraws.filter(draw => draw.gamegroupID == this.state.lottoType && draw.SaleStatus == 0).map((x, inx) =>
                                            <button className={`${this.state.myUCDraw.filter(xx => xx == x.GameId).length > 0 ? 'bg-game13' : ''} zodiacValBtn zodiacValBtn3  text-game13`} onClick={() => { this.SelectUpcomeDraw(x) }}><span>{x.DisplayDateTime} </span><p>{x.DrawShowTime}(UAE)</p></button>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        {this.state.lottoType != 13 && <div className="minHeight9 flexCenter">
                            {this.state.lottoType != 13 &&
                                <p className="text-center">
                                    <span className="buyHeadFont"> {this.state.lottoType == 9 ? appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount : this.state.lottoType == 10 ? appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount : this.state.lottoType == 14 ? appVariables.PRIZE_STRUCT_DREAM5.RaffleWinCount : appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount}</span><br />
                                    <span className="buyTextFont">{t('lblguranteed')} {t('lblGuaranteedWinner')}</span> <br /><span className="buyTextFont">{t('lblWinner')}</span>
                                </p>
                            }
                        </div>}
                    </div>
                    <div className="buyGame displayFlex">
                        {this.state.lottoType != 13 ?
                            <div className={`buyGameTicketsAlign`}>
                                {this.state.cartItems && this.state.cartItems.length > 0 && this.state.currentDraw && this.state.currentDraw.SaleStatus != 1 && this.state.cartItems.map((x, inx) =>
                                    <div className="padd2 gamesWidth" key={`aw_${inx}`}>
                                        <div className={`gameCont gameCont2 marBottom gamesContainerHeight bg-img${x.gamegroupID} ${x.betComplete && x.gamegroupID == 10 ? "bg-game-10" : ""}`} onMouseEnter={() => this.setIsShown(x, false)} onMouseLeave={() => this.setIsShown(x, true)}>
                                            <div className="gameSelt">
                                                {(this.state.MyFavNos && this.state.MyFavNos.length > 0) ?
                                                    <select value={(this.state.myfavNoID == 0 ? "-1" : this.state.myfavNoID)} onChange={(e) => this.setMyFavNo(e, x)}>
                                                        <option value="-1">{t('lblChooseFav')}</option>
                                                        {this.state.MyFavNos && this.state.MyFavNos.map((mfav, inxs) =>
                                                            <option key={`ax_${inxs}`} value={inxs}>{mfav.numbers}</option>
                                                        )}
                                                    </select> :
                                                    <select>
                                                        <option value="-1">{t('lblNoFav')}</option>
                                                    </select>}
                                                <div className="likeAndShare itemsCenter likeAndShare2 float-right">
                                                    <a onClick={() => { this.saveMyFavNo(x) }} ><img src="./assets/img/icons/like.png" alt='like Icon' /></a>
                                                    <a onClick={() => { this.quickPick(x) }}><img src="./assets/img/icons/shuffle.png" alt='shuffle Icon' /></a>
                                                    <button type="button" className="text-center deleteBtn marginLeft1">
                                                        <img src="./assets/img/icons/delete.webp" alt="delete button" className="margin5Top" onClick={() => { this.removeGamePad(inx) }} />
                                                    </button>
                                                </div>
                                            </div>
                                            <ul className={`ballSec topBallSec game-${x.gamegroupID}-balls ${x.gamegroupID == 10 ? "topBallsAdjust2" : "topBallsAdjust1"} ${x.gamegroupID == 9 ? "topBallsAdjust4" : ''}`} >
                                                {x.BetSelection.map((x1, inx) => <li key={`ay_${inx}`}><a >{x1}</a></li>
                                                )}
                                            </ul>
                                            <div className="circlePosn">
                                                <div className="half-circle circle1"></div>
                                                <div className="half-circle circle2"></div>
                                            </div>
                                            <ul className={`ballSec game-${x.gamegroupID}-balls ${x.betComplete ? `addTick${x.gamegroupID}` : ''} ${x.gamegroupID == 10 ?
                                                'drawBallSection2' : 'drawBallSection rowGap'}`}>
                                                {this.createBall(x)}
                                            </ul>
                                            <button className="clrAll" onClick={() => { this.clearCart(x) }}>{t('lblClearAll')}</button>
                                            {this.state.cartItems.length - 1 == inx && <div className="buyDisable" onClick={() => { this.ballPadGenerate("+") }}>
                                                {x.gamegroupID == 9 ?
                                                    <img src="./assets/img/plus-circle.png?v0.1" alt="plus sign" className="addImgIcon" />
                                                    : x.gamegroupID == 10 ? <img src="./assets/img/game2_plus.png" alt="plus sign" className="addImgIcon" />
                                                        : x.gamegroupID == 13 ? <img src="./assets/img/plus-circle-13.png" alt="plus sign" className="addImgIcon" />
                                                            : <img src="./assets/img/plus-circle-3.png?v0.1" alt="plus sign" className="addImgIcon" />}
                                            </div>}
                                        </div>
                                    </div>
                                )}
                                {/* game loading performance changes. */}
                                {/* {(this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws == null || this.state.currentAvailableDraws < 2) && */}
                                {(!this.state.currentDraw || Object.keys(this.state.currentDraw).length == 0) &&
                                    <>
                                        {iSdraw == 0 && this.state.datafatchCompleted ?
                                            <div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblclosed')}</div> // {t('lblGameNotAvl')}   game not availbale show sale closed text.
                                            : <div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblLoading')}</div>
                                        }
                                    </>
                                }
                                {this.state.currentDraw && this.state.currentDraw.SaleStatus == 1 &&
                                    < div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblclosed')}</div>
                                    // <SaleClose Pagetype={2} lottotype={this.state.lottoType} />
                                }
                            </div>
                            :
                            <div className="buyGameTicketsAlign">
                                {this.state.cartItems && this.state.cartItems.length > 0 && this.state.currentDraw && this.state.currentDraw.SaleStatus != 1 && this.state.cartItems.map((x, inx) =>
                                    <div className="padd2 gamesWidth" key={`az_${inx}`}>
                                        <div className="zodiacGameCont zodiacGameContInr gameCont bg-img13 gameCont2 marBottom gamesContainerHeight">
                                            <div className="gameSelt paddCustom">
                                                {(this.state.MyFavNos && this.state.MyFavNos.length > 0) ?
                                                    <select value={(this.state.myfavNoID == 0 ? "-1" : this.state.myfavNoID)} onChange={(e) => this.setMyFavNo(e, x)}>
                                                        <option value="-1">{t('lblChooseFav')}</option>
                                                        {this.state.MyFavNos && this.state.MyFavNos.map((mfav, inxs) =>
                                                            <option key={`ba_${inxs}`} value={inxs}>{mfav.numbers}</option>
                                                        )}
                                                    </select> :
                                                    <select>
                                                        <option value="-1">{t('lblNoFav')}</option>
                                                    </select>}
                                                <div className="likeAndShare itemsCenter likeAndShare2 float-right">
                                                    <a onClick={() => { this.saveMyFavNo(x) }} ><img src="./assets/img/icons/like.png?v0.1" alt='like Icon' /></a>
                                                    <a onClick={() => { this.quickPick_lucy1(x) }}><img src="./assets/img/icons/shuffle.png?v0.1" alt='shuffle Icon' /></a>
                                                    <button type="button" className="text-center deleteBtn marginLeft1">
                                                        <img src="./assets/img/icons/delete.png?v0.1" alt="delete button" className="margin5Top" onClick={() => { this.removeGamePad(inx) }} />
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <ul className="ballSec topBallSec topBallsAdjust1 game-13-balls">
                                                {x.BetSelection.map((x1, inx) => <li><a >{x1}</a></li>
                                                )}
                                            </ul> */}

                                            <div className="circlePosn circlePosnLck2">
                                                <div className="half-circle circle1"></div>
                                                <div className="half-circle circle2"></div>
                                            </div>
                                            <div className="dottedLine"></div>
                                            <ul className="zodiacBallSec zodiacBallSecInr drawBallSection game-13-balls rowGap">
                                                {this.createBall_lucy1(x)}
                                            </ul>
                                            <div className="entryLbl text-game13">{t('lblmultientry')}</div>
                                            <div className="contw100" >
                                                {locky1Arr.map((nx, inxl) =>
                                                    <button key={`bc_${inxl}`} onClick={() => { this.selMulti_lucy1(x, nx, 1) }} className={`zodiacValBtn clrAll ${x.OddsVal == nx ? 'bg-game13 text-white' : ''}`}>{nx}<sup>x</sup></button>
                                                )}
                                                <div className="zodiacValCont">
                                                    <span onClick={() => { this.selMulti_lucy1_sub(x) }}>x</span>
                                                    <input onBlur={(obj) => this.multiKyblur(obj, x)} onKeyUp={(obj) => this.multiKup(obj, x)} id='txtmtpl' maxLength={4} className="zodiacValInpt" />
                                                </div>
                                                <div className="lckWindtls">
                                                    <p>{t('lblPay')} {x.minStake * x.OddsVal}</p>
                                                    <span>{t('lblwin')} {(x.minStake * x.OddsVal) * x.StakeMulti}</span>
                                                    {this.state.myUCDraw && this.state.myUCDraw.length > 1 &&
                                                        <div className="entryLbl">Total Pay Amount is {(x.minStake * x.OddsVal) * this.state.myUCDraw.length}</div>
                                                    }
                                                </div>
                                            </div>

                                            {this.state.cartItems.length - 1 == inx && <div className="buyDisable" onClick={() => { this.ballPadGenerate("+") }}>
                                                {x.gamegroupID == 9 ?
                                                    <img src="./assets/img/plus-circle.png?v0.1" alt="plus sign" className="addImgIcon" />
                                                    : x.gamegroupID == 13 ? <img src="./assets/img/plus-circle-13.png" alt="plus sign" className="addImgIcon" />
                                                        : <img src="./assets/img/plus-circle-3.png?v0.1" alt="plus sign" className="addImgIcon" />}
                                            </div>}

                                            {/* <button onClick={() => { this.clearCart(x) }} className="clrAll" style={{ float: 'right', margin: '8px 15px 0 0' }}>{t('lblClearAll')}</button> */}
                                        </div>
                                    </div>
                                )}
                                {(!this.state.currentDraw || Object.keys(this.state.currentDraw).length == 0) &&
                                    <>
                                        {iSdraw == 0 && this.state.datafatchCompleted ?
                                            <div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblclosed')}</div> // {t('lblGameNotAvl')}   game not availbale show sale closed text.
                                            : <div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblLoading')}</div>
                                        }
                                    </>
                                }
                                {this.state.currentDraw && this.state.currentDraw.SaleStatus == 1 &&
                                    < div style={{ float: "left", textAlign: "center", fontSize: "45px", fontFamily: "GentiumBookBasic-Bold", marginTop: "50px", marginRight: "10px" }}>{t('lblclosed')}</div>
                                    // <SaleClose Pagetype={2} lottotype={this.state.lottoType} />
                                }
                            </div>
                        }
                        {/* col-md-2  */}
                        <div className="col-sm-4 col-xs-12">
                            <div className="buyGameLft buyGameButton"  >
                                <div className="buyGameLftImg">
                                    {this.state.lottoType == 9 ?
                                        <img src="./assets/img/BottleWithML-1.png?v0.1" alt='Fortune4 Bottle Icon' />
                                        : this.state.lottoType == 10 ? <img src="./assets/img/BottleWithML-2.png" alt='Super6 Bottle Icon' />
                                            : <img src="./assets/img/BottleWithML-3.png" alt='Magic5 Bottle Icon' />
                                    }
                                </div>
                                <div className="plusMinus mar0Auto">
                                    <a className={`bg-game${this.state.lottoType}`} onClick={() => { this.ballPadGenerate("-") }}>-</a>
                                    {/* <span>{this.state.cartItems.length > 0 ? this.state.cartItems.length - 1 : 0}</span> */}
                                    {this.state.txtShow != true ?
                                        <span onClick={() => { this.setState({ txtShow: true }); }}> {this.state.cartItems.length > 0 ? this.state.cartItems.length - 1 : 0}</span>
                                        :
                                        <input type='text' id='txtpadcnt' autoFocus onBlur={(obj) => this.addCustomizePad_b(obj)} onKeyUp={(obj) => this.addCustomizePad_k(obj)} maxLength={2} />
                                    }
                                    <a className={`bg-game${this.state.lottoType}`} onClick={() => { this.ballPadGenerate("+") }}>+</a>
                                </div>
                                <div className="btnsCont">
                                    <button className={`text-nowrap text-white bg-game${this.state.lottoType}`} onClick={() => { this.addMyCart() }}>{t('lblAddTocart')}</button>
                                </div>
                                {/* <a className="subTotal" >Cart Count : {this.state.cartItems.length > 0 ? this.state.cartItems.length - 1 : 0}</a> */}
                                {this.state.lottoType == 13 && this.state.myUCDraw && this.state.myUCDraw.length > 1 ?
                                    <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {((this.state.cartItems.reduce((a, b) => a = a + (b.stakeValue * b.OddsVal), 0)) * this.state.myUCDraw.length)}</a>
                                    :
                                    <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</a>
                                }

                                <div className="text-[12px] text-slate-500 text-center">
                                    {t('lblbottletext')}
                                    {/* Your purchased bottle is donated to those in need of life’s basic amenities. */}
                                </div>
                                {/*<div className="btnsCont">
                                    <button className={`customBtn${btncolor}`} onClick={() => { this.AboutGame() }}>{t('lblAboutGame')}</button>
                                </div>
                                <div className="btnsCont">
                                    <button className={`customBtn${btncolor}`} onClick={() => history.push('/HowtoPlay')}>{t('lblHowToPlay')}</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div >
                <div>
                    {this.state.lottoType == 9 ? <AboutFortureFive /> : this.state.lottoType == 11 ? <AboutMgicFive /> : this.state.lottoType == 10 ? <AboutSuperSix /> : <AboutLuckyOne />}
                </div>
                < div className="cart" >
                    <img src="./assets/img/icons/cart.svg" alt="Cart" onClick={() => this.gotoMyCart()} />
                    {/* commended by srimathi issue no 231 */}
                    {/* <span>{this.state.cartItems.filter(a => a.stakeValue > 0).length + this.state.otherGameCount}</span> */}
                    <span>{this.state.otherGameCount}</span>
                </div >
            </>
        );
    }
}
function mapStateToProps(state) {
    const { currentDraw, MyFavNos, previousCartItem, currentAvailableDraws } = state.gameReducer;//currentAvailableDraws // game loading performance changes.
    const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    const { alertReducer } = state;
    return { user, serverTime, currentDraw, MyFavNos, appvariables, previousCartItem, alertReducer, currentAvailableDraws };
}
const mapActionToProps = {
    GameAvailableGames: gameActions.GameAvailableGames,
    closeBetSlip: gameActions.closeBetSlip,
    AddToCart: gameActions.AddToCart,
    ClearCart: gameActions.ClearCart,
    alertError: alertActions.error,
    SaveMyFavNumbers: gameActions.SaveMyFavNumbers,
    GetMyFavNumbers: gameActions.GetMyFavNumbers,
    UpdateCartAll: gameActions.UpdateCartAll,
    clearAlerts: alertActions.clear,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Lotto))