import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class GameRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowRule: 1
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <h2 className="headding3 floatNone">{t('lblGamesRules')}</h2>
                <div className="faqsContainer">
                    <div className="faqsFirstCont">
                        <div style={{ fontWeight: "bold" }}>{t('lblGamesRules')}</div>
                        <a onClick={() => { this.setState({ ShowRule: 1 }) }} className={this.state.ShowRule == 1 ? "activeLink" : ""}>{t('lblgel')}</a>
                        <a onClick={() => { this.setState({ ShowRule: 5 }) }} className={this.state.ShowRule == 5 ? "activeLink" : ""}>{t('lbllucky1')}</a>
                        <a onClick={() => { this.setState({ ShowRule: 6 }) }} className={this.state.ShowRule == 6 ? "activeLink" : ""}>{t('lbldreamfive')}</a>
                        <a onClick={() => { this.setState({ ShowRule: 4 }) }} className={this.state.ShowRule == 4 ? "activeLink" : ""}>{t('lblmagic5')}</a>
                        <a onClick={() => { this.setState({ ShowRule: 2 }) }} className={this.state.ShowRule == 2 ? "activeLink" : ""}>{t('lblFortune5')}</a>
                        <a onClick={() => { this.setState({ ShowRule: 3 }) }} className={this.state.ShowRule == 3 ? "activeLink" : ""}>{t('lblSuperSix')}</a>
                    </div>
                    {this.state.ShowRule == 1 &&
                        <div className="faqsLastContainer">
                            <div>
                                <p>With Every Water Bottle, Embrace the Magic: Unwrap Your Dreams with a Gulf Ticket!</p>
                                <p>
                                    Every water bottle you buy with Gulf Ticket isn't just hydration; it's an opportunity to transform your life. Choose
                                    your game, pick your numbers, and ignite the spark of possibility. At Gulf Ticket, we believe every dream
                                    deserves a chance to shine, and every purchase could be the first step toward an extraordinary journey.
                                </p>

                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">
                                    Gulf Ticket Rules: Play and Win Responsibly
                                </h4>
                                <p>
                                    By participating in Gulf Ticket's online draw games, you willingly accept and agree to comply with the
                                    established rules outlined herein. Before diving in, we highly recommend that you carefully review our
                                    comprehensive Terms & Conditions and Privacy Policy. These rules, along with those documents, constitute the
                                    full agreement governing your involvement in our Raffle Draw Games.
                                </p>
                                <p className="mt-3">
                                    In case, of any discrepancies between the English version and translated versions of these Rules, the English
                                    version will take precedence. Similarly, if there's any inconsistency between platform instructions,
                                </p>
                                <p className="mt-3">
                                    Entering any game signifies your informed acceptance of the Gulf Ticket Terms & Conditions, Rules, and Privacy
                                    Policy, making you legally bound by them.
                                </p>

                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-7 !mb-0">How to Play</h4>
                                <p>
                                    Your Magical Journey Starts Here! Every purchase of a bottled dream unlocks your chance to turn ordinary days
                                    into extraordinary ones with three electrifying draw games – Lucky 1,Dream 5 ,Magic 5, Fortune 4, and Super 6. So, grab your
                                    bottle, pick your numbers, and witness the magic of possibility unfold!
                                </p>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-7 !mb-0">1. Five Choices, Five times of the Excitement: We offer three water bottle sizes, Five games, and
                                    Five paths to your dream life.</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li><span className="font-semibold">Lucky 1:</span>Grab a {appVariables.PRIZE_STRUCT_LUCKY1.BottleSize} bottle for AED {appVariables.PRIZE_STRUCT_LUCKY1.BetAmount}  and dive into the mystical adventure of Lucky1.</li>
                                    <li><span className="font-semibold">Dream 5:</span> For AED {appVariables.PRIZE_STRUCT_DREAM5.BetAmount}, choose a {appVariables.PRIZE_STRUCT_DREAM5.BottleSize} bottle and embark on the mystical quest of Dream 5.</li>
                                    <li><span className="font-semibold">Magic 5:</span> For AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount}, choose a {appVariables.PRIZE_STRUCT_MAGIC5.BottleSize} bottle and embark on the mystical quest of Magic 5.</li>
                                    <li><span className="font-semibold">Fortune 4:</span> Opt for a {appVariables.PRIZE_STRUCT_FORTUNE5.BottleSize} bottle for AED {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount} and chase the fulfilling dream of Fortune 4.</li>
                                    <li><span className="font-semibold">Super 6:</span> Ascend to the grand stage with a {appVariables.PRIZE_STRUCT_SUPER6.BottleSize} bottle for AED {appVariables.PRIZE_STRUCT_SUPER6.BetAmount} and unlock the potential of Super 6.</li>
                                </ul>
                                <p>Each bottle is your gateway to thrilling draws and, potentially, a life-altering jackpot.</p>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-7 !mb-0">2. Your Ticket to Transformation: Select your lucky numbers! </h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li><span className="font-semibold">Lucky 1:</span> Select 1 Zodiac sign between 1 and 12 and enter the Lucky1 draw for a chance at a phenomenal prize!</li>
                                    <li><span className="font-semibold">Dream 5:</span> Choose {appVariables.PRIZE_STRUCT_DREAM5.BallSelectCount} numbers from 1 to 39 to awaken the Dream within</li>
                                    <li><span className="font-semibold">Magic 5:</span> Choose {appVariables.PRIZE_STRUCT_MAGIC5.BallSelectCount} numbers from 1 to 36 to awaken the magic within</li>
                                    <li><span className="font-semibold">Fortune 4:</span> Pick {appVariables.PRIZE_STRUCT_FORTUNE5.BallSelectCount} numbers from 1 to 36 for a shot at the life-changing Fortune 4 grand prize.</li>
                                    <li><span className="font-semibold">Super 6:</span> Channel your intuition and select {appVariables.PRIZE_STRUCT_SUPER6.BallSelectCount} numbers from 1 to 49 for the ultimate Super 6 draw.</li>
                                </ul>
                                <p>Every number holds the power to be your lucky strike, so choose wisely and let your dreams be your guide.</p>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-7 !mb-0">3. Witness the Magic Unfold: Draw Dates are displayed on your ticket and our website.</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li><span className="font-semibold">Lucky 1:</span> Everyday, Tune in to the live Lucky1 draw on our social media channels to see if your Zodiac/number are the
                                        key to winning big.</li>
                                    <li><span className="font-semibold">Dream 5:</span> Every Wednesday, tune in to the live Dream 5 draw on our social media channels and see if your
                                        chosen numbers hold the key to your dreams.
                                    </li>
                                    <li><span className="font-semibold">Magic 5:</span> Every Thursday, tune in to the live Magic 5 draw on our social media channels and see if your
                                        chosen numbers hold the key to your dreams.
                                    </li>
                                    <li><span className="font-semibold">Fortune 4:</span> Every Friday, witness the Fortune 4 live draw on social media channels and see if you've
                                        chosen the right numbers on your ticket.
                                    </li>
                                    <li><span className="font-semibold">Super 6:</span> On Saturdays, it's time for the Super 6 grand finale, where your selected six numbers could
                                        unlock the ultimate destiny. Hold your breath, and watch your dreams turn into reality</li>

                                </ul>


                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">
                                    Prizes That Make Your Dreams Sparkle:
                                </h4>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Lucky 1:</h5>
                                <div className="italic font-semibold mt-5 text-base">Main Draw: </div>
                                {/* <p>A guaranteed raffle can quench your thirst for extra wins.{appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} lucky participants win AED {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} each!</p> */}
                                {/* <h5 className="text-base font-semibold mt-3 lg:mt-5">Other Prizes:</h5> */}
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        If Zodiac/number is matched, to win the multiple of 10 times your purchase amount.
                                    </li>
                                </ul>

                                <h5 className="text-base font-semibold mt-3">Dream 5:</h5>
                                <div className="italic font-semibold mt-5 text-base">Guaranteed Raffle:</div>
                                <p>A guaranteed raffle can quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_DREAM5.RaffleWinCount} lucky participants win AED {appVariables.PRIZE_STRUCT_DREAM5.RaffleWinAmount} each!</p>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Other Prizes:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Match all {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FstPrize.replace('#','Million')}! (Shared, in case of
                                        multiple winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.SndPrize} each.
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.TrdPrize} each.
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FthPrize} each.
                                    </li>
                                    <li>
                                        5th Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FivthPrize} matching number gets you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FivthPrize} each.
                                    </li>
                                </ul>

                                <h5 className="text-base font-semibold mt-3">Magic 5:</h5>
                                <div className="italic font-semibold mt-5 text-base">Guaranteed Raffle:</div>
                                <p>A guaranteed raffle can quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} lucky participants win AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} each!</p>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Other Prizes:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Match all {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}! (Shared, in case of
                                        multiple winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.SndPrize} each.
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.TrdPrize} each.
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FthPrize} each.
                                    </li>
                                    <li>
                                        5th Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FivthPrize} matching number gets you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FivthPrize} each.
                                    </li>
                                </ul>
                               <h5 className="text-base font-semibold mt-3">Fortune 4:</h5>
                                <div className="italic font-semibold mt-5 text-base">Guaranteed Raffle:</div>
                                <p>A guaranteed raffle can quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} lucky participants win AED {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount} each!</p>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Other Prizes:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Match all {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize}! (Shared, in case of
                                        multiple winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.SndPrize} each.
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.TrdPrize} each.
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FthPrize} each.
                                    </li>

                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Super 6:</h5>
                                <div className="italic font-semibold mt-5 text-base">Guaranteed Raffle:</div>
                                <p>A guaranteed raffle can quench your thirst for extra wins.{appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} lucky participants win AED {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} each!</p>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Other Prizes:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Hit all {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FstPrize} numbers and unleash a tidal wave of AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million! (Shared, in case of multiple
                                        winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.SndPrize} matching numbers bag you AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.SndPrize}! (Shared, in case of multiple winners.)
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.TrdPrize} matching numbers reward you with AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.TrdPrize} for each.
                                        {/* (Shared, in case of multiple winners.) */}
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FthPrize} matching numbers pocket you AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FthPrize} each, a refreshing reward for your participation.
                                    </li>
                                    <li>
                                        5th Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FivthPrize} matching numbers mean AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FivthPrize} for each winner, a little something to keep the
                                        excitement bubbling
                                    </li>
                                </ul>




                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">  Wallet Rules</h4>
                                <p>
                                    Your Gulf Ticket wallet is your convenient one-stop shop for playing and winning! This section explains how to
                                    manage your wallet and enjoy a seamless experience. Your Gulf Ticket wallet is a secure virtual account where
                                    you can store funds for purchasing tickets and receive your winnings. This eliminates the need for repeated
                                    transactions via card or other payment modes and makes ticket purchases quick and easy.
                                </p>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Adding Money to Your Wallet</h5>
                                <ul className=" list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        <span className="font-semibold">Credit Card:</span> Use your Visa or Mastercard credit card to instantly add funds to your wallet. Minimum
                                        top-up amount is AED 5.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Debit Card:</span> Use your Visa or Mastercard debit card to add funds to your wallet. Minimum top-up
                                        amount is AED 5.
                                    </li>
                                    <li>
                                        <span className="font-semibold"> Apple Pay and Google Pay: </span>Use Apple Pay or Google Pay to add funds to your wallet easily and securely. Minimum top-up amount is AED 5.
                                    </li>
                                    {/* <li>
                                        <span className="font-semibold">Bank Transfer:</span> Transfer funds directly from your bank account to your Gulf Ticket wallet. Minimum
                                        transfer amount is AED 5.
                                    </li> */}
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">
                                    Using Your Wallet to Purchase Tickets
                                </h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>Select the game you want to play (Lucky1,Dream 5 ,Magic 5, Fortune 4 or Super 6).</li>
                                    <li>Choose your lucky numbers.</li>
                                    <li>Select "Wallet" as your payment method during checkout.</li>
                                    <li>The ticket cost will be deducted from your wallet balance.</li>
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Important Terms</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        <span className="font-semibold">Credit Card:</span> A card issued by a bank that allows you to borrow money to make purchases. You need to
                                        pay back the borrowed amount plus interest.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Debit Card:</span> A card linked to your bank account that allows you to spend the money you already have.
                                        No borrowing involved.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Balance:</span> The total amount of money available in your Gulf Ticket wallet.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Winnings:</span> Any prize money you win playing Gulf Ticket games
                                    </li>
                                    <li>
                                        <span className="font-semibold"> Apple Pay: </span> A mobile payment and digital wallet service by Apple that enables you to make secure payments using your iPhone, iPad, or Apple Watch wherever it is accepted.
                                    </li>
                                    <li>
                                        <span className="font-semibold"> Google Pay (GPay):  </span> A digital wallet platform and online payment system developed by Google that enables in-app and tap-to-pay purchases on mobile devices wherever it is accepted.
                                    </li>
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Receiving Winnings</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        All your winnings, from matching numbers to raffle prizes, will be automatically credited to your Gulf
                                        Ticket wallet. There's no need to claim them or wait for manual processing.
                                    </li>
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Withdrawing Winnings</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        You can withdraw any amount from your wallet (except the minimum AED 200 balance) to your bank
                                        account at any time.
                                    </li>
                                    <li>Go to the "My Wallet" section and initiate a withdrawal request.</li>
                                    <li>Enter the desired amount and your bank account details.</li>
                                    <li>
                                        The Gulf Ticket Support Team will process your request within 24 hours. You will receive a notification
                                        once the transfer is complete.
                                    </li>
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">Customer Support</h5>
                                <p>
                                    If you do not receive an acknowledgment of your entry, the entry may not have been recorded on the Gulf
                                    Ticket Online Gaming System for the relevant draw, and therefore it may be invalid. In the event that You do not
                                    receive an acknowledgment, please check your account to see if your entry has been registered. If you cannot
                                    see it, please contact Gulf Ticket's Customer Support Centre at +971 542473561 for all other locations, or by
                                    sending an email to support@gulfticket.com.
                                </p>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">
                                    Important Information
                                </h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>You must be 18 years or older to play.</li>
                                    <li>Online Raffle Draws can be addictive. Please play responsibly.</li>
                                    <li>
                                        For detailed terms and conditions, privacy policy, and frequently asked questions, please visit our
                                        dedicated information page on the website.
                                    </li>
                                    <li>
                                        Remember, responsible gambling is key to enjoying the thrill of the draw while protecting your
                                        well-being. We encourage you to set spending limits and play within your means.
                                    </li>
                                </ul>
                                <h5 className="text-base font-semibold mt-3 lg:mt-5">
                                    More Than Just a Draw, It's a Lifestyle
                                </h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        <span className="font-semibold">Turn Every Purchase into a Possibility:</span>  Every water bottle you buy is a chance to rewrite your story.
                                        Your purchased bottle is donated to those in need of life’s basic amenities. Yet, it’s not just about the
                                        social cause. Rather, it's about igniting your dreams and turning your hopes into reality.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Double the Excitement, Double the Wins:</span> With three games and three draws each week, the opportunities
                                        for winning are never-ending. Magic 5 on Thursday,Dream 5 on Wednesday , Fortune 4 on Friday, and Super 6 on Saturday, three
                                        chances to turn your weekend into a celebration.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Guaranteed Rewards, Every Single Draw:</span>  Even if your numbers don't hit the jackpot, our guaranteed
                                        raffle draws ensure you walk away with something to smile about. We believe in sharing the joy, so
                                        everyone gets a chance to win.
                                    </li>
                                    <li>
                                        <span className="font-semibold">Transparency and Fairness, Crystal Clear:</span>
                                        Witness the magic unfold in real-time with our live YouTube
                                        draws. We believe in fair play and open communication, so you can be sure every draw is conducted
                                        with integrity.
                                    </li>
                                </ul>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">
                                    Join the Gulf Ticket Community
                                </h4>
                                <p>
                                    Follow us on social media for exciting updates, promotional offers, and behind-the-scenes glimpses into the
                                    world of Gulf Ticket. Share your winning stories, connect with fellow players, and experience the excitement of
                                    the draw together.
                                </p>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-3">
                                    <li>
                                        <a
                                            className="text-sm text-blue-600"
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.facebook.com/profile.php?id=61555927626606">
                                            Facebook</a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/gulfticket_india/"
                                            className="text-sm text-blue-600"
                                            target="_blank"
                                            rel="noopener">
                                            Instagram</a>
                                    </li>
                                    <li>
                                        <a
                                            className="text-sm text-blue-600"
                                            target="_blank"
                                            rel="noopener"
                                            href="https://twitter.com/gulfticketindia"
                                        >Twitter</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            className="text-sm text-blue-600"
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.youtube.com/@gulfticket">
                                            Youtube</a>
                                    </li>
                                </ul>
                                <h4 className="text-base !lg:text-lg font-semibold mt-3 lg:mt-5 !mb-0">
                                    Download the Gulf Ticket App Today - COMING SOON
                                </h4>
                                <p>
                                    Available on the App Store and Google Play, the Gulf Ticket app brings the thrill of the draw right to your
                                    fingertips. Manage your tickets, track results, and join the community – all at your convenience. Download the
                                    app and start your adventure towards fortune!
                                </p>
                                <p className="mt-3">We wish you the best of luck in your pursuit of winning!</p>
                            </div>
                        </div>}

                    {this.state.ShowRule == 4 &&
                        <div className="faqsLastContainer">
                            <h5 className="text-base font-semibold">Magic 5:</h5>
                            <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                <li>
                                    Grand Prize: Match all {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize}! (Shared, in case of
                                    multiple winners.)
                                </li>
                                <li>
                                    2nd Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.SndPrize} each.
                                </li>
                                <li>
                                    3rd Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.TrdPrize} each.
                                </li>
                                <li>
                                    4th Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FthPrize} each.
                                </li>
                                <li>
                                    5th Prize: {appVariables.PRIZE_STRUCT_MAGIC5.MatchBall.FivthPrize} matching number gets you AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FivthPrize} each.
                                </li>
                            </ul>

                            <div className="italic font-semibold mt-5 text-base">Weekly Raffle:</div>
                            <p>
                                A guaranteed bonus to quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} lucky participants
                                win AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} each!
                            </p>
                        </div>}


                        {this.state.ShowRule == 6 &&
                        <div className="faqsLastContainer">
                            <h5 className="text-base font-semibold">Dream 5:</h5>
                            <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                <li>
                                    Grand Prize: Match all {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FstPrize.replace('#',' Million')}! (Shared, in case of
                                    multiple winners.)
                                </li>
                                <li>
                                    2nd Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.SndPrize} each.
                                </li>
                                <li>
                                    3rd Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.TrdPrize} each.
                                </li>
                                <li>
                                    4th Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FthPrize} each.
                                </li>
                                <li>
                                    5th Prize: {appVariables.PRIZE_STRUCT_DREAM5.MatchBall.FivthPrize} matching number gets you AED {appVariables.PRIZE_STRUCT_DREAM5.MainDrawWin.FivthPrize} each.
                                </li>
                            </ul>

                            <div className="italic font-semibold mt-5 text-base">Weekly Raffle:</div>
                            <p>
                                A guaranteed bonus to quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_DREAM5.RaffleWinCount} lucky participants
                                win AED {appVariables.PRIZE_STRUCT_DREAM5.RaffleWinAmount} each!
                            </p>
                        </div>}

                    {this.state.ShowRule == 2 &&
                        <div className="faqsLastContainer">
                            <div>
                                <h5 className="text-base font-semibold">Fortune 4:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Match all {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.FstPrize} numbers and be swept away by a wave of AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize}! (Shared, in case of
                                        multiple winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.SndPrize} matching numbers bring you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.SndPrize} each.
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.TrdPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.TrdPrize} each.
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_FORTUNE5.MatchBall.FthPrize} matching numbers get you AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FthPrize} each.
                                    </li>
                                </ul>
                                <div className="italic font-semibold mt-5 text-base">Weekly Raffle:</div>
                                <p>
                                    A guaranteed bonus to quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} lucky participants win
                                    AED {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount} each!
                                </p>
                            </div>
                        </div>
                    }
                    {this.state.ShowRule == 3 &&
                        <div className="faqsLastContainer">
                            <div>
                                <h5 className="text-base font-semibold">Super 6:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: Hit all {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FstPrize} numbers and unleash a tidal wave of AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million! (Shared, in case of multiple
                                        winners.)
                                    </li>
                                    <li>
                                        2nd Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.SndPrize} matching numbers bag you AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.SndPrize}! (Shared, in case of multiple winners.)
                                    </li>
                                    <li>
                                        3rd Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.TrdPrize} matching numbers reward you with AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.TrdPrize} for each. 
                                        {/* (Shared, in case of multiple winners.) */}
                                    </li>
                                    <li>
                                        4th Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FthPrize} matching numbers pocket you AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FthPrize} each, a refreshing reward for your participation.
                                    </li>
                                    <li>
                                        5th Prize: {appVariables.PRIZE_STRUCT_SUPER6.MatchBall.FivthPrize} matching numbers mean AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FivthPrize} for each winner, a little something to keep the
                                        excitement bubbling
                                    </li>
                                </ul>
                                <div className="italic font-semibold mt-5 text-base">Weekly Raffle:</div>
                                <p>
                                    A guaranteed bonus to quench your thirst for extra wins. {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} lucky participants win
                                    AED {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} each!
                                </p>
                            </div>
                        </div>
                    }


                    {this.state.ShowRule == 5 &&
                        <div className="faqsLastContainer">
                            <div>
                                <h5 className="text-base font-semibold">Lucky 1:</h5>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                    <li>
                                        Grand Prize: If Zodiac/number is matched, to win the multiple of 10 times your purchase amount
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div >
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(GameRule))