import React, { Component } from 'react';
import Header from '../../Header/Header';
import PrivacyPolicy from './PrivacyPolicy';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class PrivacyPolicyPage extends Component {
    constructor(props) {
        super(props);       
    }
  
    render() {
        return (<>
            <Header />
            <PrivacyPolicy />
        </>);
    }
}

function mapStateToProps(state) {
    return {  };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PrivacyPolicyPage))