import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { loginActions } from './action/loginAction';
import { withTranslation } from 'react-i18next';
import { appVariables } from './helpers/appVariables';
import { alertActions } from './action/AlertAction';
import Header from './Header/Header';
// import countrycode from './Pages/Home/countrycode.json';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { commonClass } from './helpers/commonClass';
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = { regMobileNo: '', TandC: true, firstName: '', lastName: '', password: '', Countrydetails: [], Selcountry: {}, showpwd: false, age: true, Countryfilter: '', Default: {} }
        this.props.logout(this.props.user.mobileNo, 1);
    }

    componentDidMount() {
        this.GetCountryList();
        setTimeout(() => {
            this.GetUserCountry();
        }, 300);
        // this.setState({ Selcountry: this.state.Countrydetails.default });
        // this.state.Selcountry = this.state.Countrydetails.default;
    }
    componentDidUpdate(prevState, prevProps) {
        //  var lstrdata = localStorage.getItem('alertmsg');
        //  if (lstrdata != null) {
        //          this.props.alertsucc(lstrdata);
        //  }
    }
    toggleCountry = () => { window.setCountryToggle(); }
    countryChange = (data) => {
        this.setState({ Selcountry: data, regMobileNo: '' });
        this.toggleCountry();
        this.state.Countryfilter = ''
    }
    handleTextChange(e) {
        try {
            let value = e.target.value
            if ([e.target.name] == "regMobileNo") {
                const regex = /^[0-9\b]+$/;
                if (!regex.test(value) && !value == '') { return; }
            }
            else if ([e.target.name] == "firstName" || [e.target.name] == "lastName") {
                const regex = /^[a-zA-Z ]*$/;
                if (!regex.test(value) && !value == '') { return; }
                // value = value.replace(/[^A-Za-z]/ig, '')
            }
            this.setState({ [e.target.name]: value });
        } catch (ex) { }
    }
    btnGetOTP() {
        if (this.state.firstName && this.state.firstName.trim() != "" && this.state.firstName.length > 2) {
            if (this.state.lastName && this.state.lastName.trim() != "") {
                if (this.state.regMobileNo && this.state.regMobileNo.trim() != "" && this.state.regMobileNo.length <= this.state.Selcountry.mobNolength && this.state.regMobileNo.length >= this.state.Selcountry.mobNoMinlength) {
                    if (this.state.password && this.state.password.trim() != "") {
                        let regEx = /^(?=.*?[0-9])(?=.*?[^0-9A-Za-z]).{8,15}$/;
                        if (regEx.test(this.state.password)) {
                            // if (this.state.password && this.state.password.length > 2 ) {
                            if (this.state.TandC !== false) {
                                if (this.state.age !== false) {
                                    this.state.password = this.state.password.replace(/'/g, "''");
                                    this.state.firstName = this.state.firstName[0].toUpperCase() + this.state.firstName.slice(1);
                                    this.state.lastName = this.state.lastName[0].toUpperCase() + this.state.lastName.slice(1);
                                    this.props.Otpgen(this.state.regMobileNo, appVariables.OTP.Register, this.props.i18n.language, this.props.user.loginKey, this.state.firstName, this.state.lastName, this.state.password, this.state.Selcountry.flag);
                                }
                                else { this.showMsg('lblPlsIndicateAge'); }
                            }
                            else { this.showMsg('lblAcceptTermCond'); }
                        }
                        else { this.showMsg('lblReqPasswordPolicy'); }
                        // else { this.showMsg('lblMinlen'); }
                    }
                    else { this.showMsg('lblEnterPwd'); }
                }
                else { this.showMsg('lblValidMobileNo'); }
            }
            else { this.showMsg('lblEnterLastname'); }
        }
        else { this.showMsg('lblFnamelength'); }
    }
    showMsg = (msg) => { this.props.alertError(this.props.t(msg)); }
    HandleCheckBox = (e) => {
        let Ischecked = e.target.checked;
        let name = e.target.name;
        this.setState({ [name]: Ischecked })
    }

    handleChange = (e) => {
        e.preventDefault()
        let name = e.target.name;
        this.setState({ [name]: e.target.value })
        this.state.Countryfilter = e.target.value;
    }

    GetCountryList = () => {
        let new_Version = 0;
        let Version_old = localStorage.getItem('countryver');
        if (this.props.appvariables.bVersion) {
            let totalversion = this.props.appvariables.bVersion.split("~")
            if (totalversion.length > 0) {
                new_Version = totalversion[3];
            }
        }
        if (Version_old != new_Version) {
            commonClass.GetCountry(new_Version)
                .then(response =>
                    response.json()
                )
                .then(data => {
                    if (data) {
                        localStorage.setItem('countryver', new_Version);
                        localStorage.setItem('countrylst', JSON.stringify(data));
                        this.setState({ Countrydetails: data })
                        // this.setState({ Selcountry: data.default })
                        this.setState({ Default: data.default })
                    }

                });
        }
        else {
            let CountryList = JSON.parse(localStorage.getItem('countrylst')); // get from localstorage for countrylist
            this.setState({ Countrydetails: CountryList })
            // this.setState({ Selcountry: CountryList.default })
            this.setState({ Default: CountryList.default })
        }
    }

    GetUserCountry = () => {
        let mythis = this;
        try {
            commonClass.GetUserCountry()
                .then(response =>
                    response.json()
                )
                .then(data => {
                    if (data) {
                        // Countryname
                        let LoadCountry = this.state.Countrydetails.other.filter(Fobj => Fobj.isocode2 == data.country)
                        if (LoadCountry && LoadCountry.length > 0) {
                            this.setState({ Selcountry: LoadCountry[0] })
                        }
                        else {
                            this.setState({ Selcountry: this.state.Default })
                        }
                    }
                    else {
                        this.setState({ Selcountry: this.state.Default })
                    }
                }).catch(function (err) {
                    mythis.setState({ Selcountry: mythis.state.Default })
                });
        } catch (error) {
            mythis.setState({ Selcountry: mythis.state.Default })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title>Gulf Ticket - Register</title>
                    <meta name="description" content="Securely Access Your Gulf Ticket Account: Register Now" />
                    <meta name="keywords" content="Gulf Ticket, online raffle draw, Biggest Online Draw" />

                    <meta property="og:title" content="Gulf Ticket - Register" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Securely Access Your Gulf Ticket Account: Register Now" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                </Helmet>}
                <Header />
                <div className="wrap2">
                    <div className="logReg">
                        <div className="logRegLi">
                            <div className="logRegTitle"><img src="./assets/img/icons/register.webp" alt='Register Icon' /><h2>{t('lblSignUp')}</h2></div>
                            <div className='form'>
                                <div className='displayFlex'>
                                    <input type="text" placeholder={`${t('lblFirstName')}*`} name='firstName' value={this.state.firstName} onChange={this.handleTextChange.bind(this)} maxLength={30} autocomplete="off" />
                                    <input type="text" placeholder={`${t('lblLastName')}*`} name='lastName' value={this.state.lastName} onChange={this.handleTextChange.bind(this)} maxLength={30} autocomplete="off" />
                                </div>
                                <div className="userLogCont">
                                    <div className="countryCode">
                                        {this.state.Selcountry && this.state.Selcountry.flag && <a className="countryImg !w-10" style={{ width: '10%' }} onClick={() => this.toggleCountry()}>
                                            <img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${this.state.Selcountry.flag}.png?v0`} alt='Show Flag Icon' />
                                            <small>{this.state.Selcountry.Ccname}</small> {this.state.Selcountry.code}
                                            <i className="fa fa-angle-down"></i>
                                        </a>}
                                        <ul className="selContry scrollbar" style={{ display: "none" }}>
                                            <li><input type="text" value={this.state.Countryfilter} name="Countryfilter" placeholder="Search" onChange={(e) => { this.handleChange(e) }} /></li>
                                            {this.state.Countrydetails && this.state.Countrydetails.other && this.state.Countrydetails.other.filter(country =>
                                                (country.Countryname.includes(this.state.Countryfilter.toUpperCase()) || country.flag.includes(this.state.Countryfilter))).map((data, inx) =>
                                                    <li key={`p_${inx}`}><a onClick={() => this.countryChange(data)}><img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${data.flag}.png?v0`} alt='Show Flag Icon' />{data.code}</a></li>
                                                )}
                                        </ul>
                                        {this.state.Selcountry && <input type="tel" className='form-control lgnusr' name='regMobileNo' placeholder={`${t('lblMobileNo')}*`} value={this.state.regMobileNo} onChange={this.handleTextChange.bind(this)} maxLength={this.state.Selcountry.mobNolength} autocomplete="off" required style={{ padding: "12px 93px" }} />}
                                    </div>
                                </div>
                                <div className='passwordDiv'>
                                    {this.state.showpwd ? <img src='./assets/img/icons/eyeopen.webp?ver=1.0' alt="show password icon" className="passwordEye" onClick={() => this.setState({ showpwd: false })} /> : <img src='./assets/img/icons/eye-slash.webp?ver=1.0' alt="show password icon" className="passwordEye" onClick={() => this.setState({ showpwd: true })} />}
                                    <input type={this.state.showpwd ? "text" : "password"} style={{ paddingLeft: '10%' }} placeholder={`${t('lblPwd')}*`} name='password' value={this.state.password} onChange={this.handleTextChange.bind(this)} maxLength={15} autocomplete="off" required />
                                </div>
                                <small style={{ color: "#b1b2ba", fontSize: "small" }}><i className="fa fa-info-circle" style={{ fontFamily: "fontawesome-webfont" }}></i> {t('lblPolicyInPwd')}</small>
                                <div className="checkBox">
                                    <label className="checkLble">{`${t('lbl18YearsAgeorOld')}*`}
                                        <input className="checkboxInput" type="checkbox" value={this.state.age} name='age' checked={this.state.age} onChange={(e) => { this.HandleCheckBox(e) }}></input>
                                        <span className="checkmark border-color"></span>
                                    </label>
                                </div>
                                <div className="checkBox">
                                    <label className="checkLble">{t('lblAgree')}<a className="terms"><Link to="/termscondition" target="_blank" rel="noopener" >{t('lblTermsandCondition')}</Link>&nbsp;</a>{t('lblWebsite')}
                                        <input className="checkboxInput" type="checkbox" value={this.state.TandC} name='TandC' checked={this.state.TandC} onChange={(e) => { this.HandleCheckBox(e) }}></input>
                                        <span className="checkmark border-color"></span>
                                    </label>
                                </div>
                                <button onClick={() => { this.btnGetOTP() }}>{t('lblNext')}</button>
                            </div>
                            <span className="frtgotPsw"><a href={`${process.env.REACT_APP_BASENAME}/login`} >{t('lblAlreadyAcc')}  <a style={{ textDecoration: "underline" }}>{t('lblLogin')}</a></a></span>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    return { user, appvariables };
}
const mapActionToProps = {
    login: loginActions.login,
    Otpgen: loginActions.Register,
    alertError: alertActions.error,
    logout: loginActions.logout,
    //alertsucc: alertActions.success
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Register))