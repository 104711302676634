import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { alertActions } from '../../action/AlertAction';
import { gameActions } from '../../action/GameAction';
class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Feedback: '',
            starValue: 0,
            MaximummsgCount: 200
        }
    }

    handleTextChange(e) {
        try {
            let value = e.target.value
            this.setState({ [e.target.name]: value });
        } catch (ex) { }
    }


    SumbitButton = () => {
        if (this.props.user.mobileNo != '0') {
            if (this.state.Feedback && this.state.Feedback.trim().length != 0) {
                if (this.state.starValue > 0) {
                    this.state.Feedback = this.state.Feedback.replace(/'/g, "''");
                    let data = { "reviewpoints": parseInt(this.state.starValue), "message": this.state.Feedback };
                    this.props.SendUserratings(data, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
                    this.setState({
                        Feedback: '',
                        starValue: 0
                    })
                }
                else {
                    this.showMsg('lblwithoutrating');
                }
            }
            else {
                this.showMsg('lblsometxt');
            }
        }
        else {
            this.showMsg('lblplsloginTry');
        }

    }

    showMsg = (msg) => { this.props.alertError(this.props.t(msg)); }



    GetStarts = (e) => {
        this.setState({ starValue: e.target.value })
    }


    render() {
        const { t } = this.props;
        let messageText = this.state.Feedback.trim().length;
        return (
            <>
                <Header />
                <div className="wrap2">
                    <h2 className="headding3 floatNone">{t('lblFeedback')}</h2>
                    <div className="feedBack">
                        <div className="feedBackCont">
                            <form className="contactForm" name="contactForm" id="contactForm" method="post" novalidate="novalidate">
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblvalComments')} ({messageText <= 0 ? t('lblmax') : t('lbltexteft')} {messageText <= 0 ? this.state.MaximummsgCount : this.state.MaximummsgCount - messageText})</label>
                                        <textarea value={this.state.Feedback} name="Feedback" onChange={(e) => { this.handleTextChange(e) }} maxLength={200} id="Feedback" cols="30" rows="10" className="form-control required" aria-required="true"></textarea>
                                    </div>
                                </div>

                                    <div className="starRating col-md-8" >
                                        <h4>{t('lblstarttxt')}</h4>
                                        <div className="star-rating">
                                            <input onClick={(e) => { this.GetStarts(e) }} type="radio" id="5-stars" name="rating" value="5" checked={this.state.starValue == "5"} />
                                            <label for="5-stars" className="star">&#9733;</label>
                                            <input onClick={(e) => { this.GetStarts(e) }} type="radio" id="4-stars" name="rating" value="4" checked={this.state.starValue == "4"} />
                                            <label for="4-stars" className="star">&#9733;</label>
                                            <input onClick={(e) => { this.GetStarts(e) }} type="radio" id="3-stars" name="rating" value="3" checked={this.state.starValue == "3"} />
                                            <label for="3-stars" className="star">&#9733;</label>
                                            <input onClick={(e) => { this.GetStarts(e) }} type="radio" id="2-stars" name="rating" value="2" checked={this.state.starValue == "2"} />
                                            <label for="2-stars" className="star">&#9733;</label>
                                            <input onClick={(e) => { this.GetStarts(e) }} type="radio" id="1-star" name="rating" value="1" checked={this.state.starValue == "1"} />
                                            <label for="1-star" className="star">&#9733;</label>
                                        </div>
                                    </div>
                                <div className="form-group row" >
                                    <div className="col-md-4 field">
                                        <div id="Alert1"></div>
                                        <div id="Loader1" style={{ display: "none" }}>
                                            <img src="img/loder.gif" width="50" height="50" alt='loder icon'/>
                                        </div>
                                        <button onClick={() => { this.SumbitButton() }} className="bg-game9 sendMsgBtn" name="send" id="send" type="button">{t('lblSubmit')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout,
    alertError: alertActions.error,
    SendUserratings: gameActions.SendUserratings
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Feedback))