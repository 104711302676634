import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { gameActions } from "../../action/GameAction";
import Header from "../../Header/Header";
import { Helmet } from "react-helmet";
import { history } from "../../helpers/history";

class WinnerPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    static getDerivedStateFromProps(props, state) {
        return {
            previousResult: props.previousResult,
            RaffleResult: props.RaffleResult && props.RaffleResult.RaffleResultLst,
            breakdownlist: props.RaffleResult && props.RaffleResult.breakdownlist,
            //breakdownlist: props.breakdownlist
        };
    };
    render() {
        window.allDrawResultPopUp();
        const { t } = this.props;
        let unique = [];
        if (this.state.RaffleResult) {
            unique = [...new Set(this.state.RaffleResult.map(item => item.draws_type))]
        }
        return (
            <div id="popup-container1" className="popup-container1 bigpopup">
                <div id="popup-content" className="popup-content1 min-width-65 no-scrollbar">
                    <div id="rightAlign" className="rightAlign">
                        <button id="close-popup1" className="close-popup1 bigpopupclose">
                            <img src="./assets/img/icons/Close-Btn.svg" alt="Close icon" />
                        </button>
                    </div>
                    <div className={`popup-main bg-game${this.props.drawType}`} >
                        {this.props.drawType != 13 ?
                            <div className="viewLatestHead2">
                                <div className="dateDraw">
                                    {/* <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && <> {this.props.PastDrawStateObj.draw_date.split(',')[0].split(' ')[1]} {this.props.PastDrawStateObj.draw_date.split(',')[0].split(' ')[2]}</>}</div>
                                    <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && this.props.PastDrawStateObj.draw_date.split(',')[1].split(' ')[0]}</div> */}
                                    <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && <> {this.props.PastDrawStateObj.draw_date.split(',')[0]} {this.props.PastDrawStateObj.draw_date.split(',')[1].split(' ')[0]}</>}</div>
                                    <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && this.props.PastDrawStateObj.draw_date.split(',')[1].split('  ')[1]}</div>
                                </div>
                                <div className="drawHeader">
                                    <div>{t('lblPastdraw')}</div>
                                    <div>{t('lblResult')}</div>
                                </div>
                            </div>
                            :
                            <div className="viewLatestHead2">
                                 <div className="dateDraw">
                                    <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && <> {this.props.PastDrawStateObj.draw_date.split(',')[0]} {this.props.PastDrawStateObj.draw_date.split(',')[1].split(' ')[0]}</>}</div>
                                    <div>{this.props.PastDrawStateObj && this.props.PastDrawStateObj.draw_date && this.props.PastDrawStateObj.draw_date.split(',')[1].split('  ')[1]}</div>
                                </div>
                                <div className="drawHeader">
                                    <div>{t('lblPastdraw')}</div>
                                    <div>{t('lblResult')}</div>
                                </div>
                                {/* <div className="drawHeader">
                                    <div>{t('lblViewWinners')}{t('lblWinner')}</div>
                                </div> */}
                            </div>}
                        <div className="winnersList1">
                            <div className="columns-2">
                                <div className={`bg-game${this.props.drawType}fade ${this.props.drawType == 13 ? 'min-h304' : ''}`} >
                                    {<div className="prizeBreak">{t('lblPriceBreak')}</div>}
                                    {this.props.drawType == 13 ?
                                        <>
                                            <div className="matches">
                                                <div style={{ marginTop: "10px" }} className={`formatOutOf  !opacity-100 ${this.props.drawType == 13 ? "!text-right" : ""}`}>10x Entry Amount</div>
                                            </div>
                                        </>
                                        : <>
                                            <div>
                                                {this.state.breakdownlist && this.state.breakdownlist.length > 0 && this.state.breakdownlist.map((obj, inx) =>
                                                    <div className="matches matches2" key={`ap_${inx}`}>
                                                        <div className={`formatOutOf  !opacity-100 ${this.props.drawType == 10 && inx == 0 ? "!text-right" : ""}`} >{obj.matchingball} {t('lblOutOf')} {obj.max_ball}</div>
                                                        <div className="font700"><span>{this.props.appvariables.currency}&nbsp;</span>{obj.winamt.replace('#', t('lblMillion'))} {obj.addstar && obj.addstar == 1 ? "*" : " "}</div>
                                                        {/* <div className="font700"><span>{this.props.appvariables.currency}</span> {(this.props.PastDrawStateObj.draw_id == "6069" || this.props.PastDrawStateObj.draw_id == "4062" || this.props.PastDrawStateObj.draw_id == "145038") && obj.game_group_id == "10" && obj.pricetext == "3rd Prize" ? "500" : (inx <= 1 || obj.game_group_id == "10" && inx <= 2) ? obj.winamt + "*" : obj.winamt}</div> */}
                                                    </div>
                                                )}
                                            </div>
                                        </>}
                                </div>
                                <div>
                                    <div className="prizeBreak text-center">{t('lblMainDrawResult')}</div>
                                    <div className="matches">
                                        <div>{t('lblWinningCombination')} : &nbsp;&nbsp;&nbsp;&nbsp;</div>
                                        <div>
                                            {this.props.drawType != 13 && <div className={`winningCombo winningCombo2 bg-game${this.props.drawType}`}>
                                                {this.props.PastDrawStateObj.win_number && this.props.PastDrawStateObj.win_number.split(';').length > 0 && this.props.PastDrawStateObj.win_number.split(';').map((winNos, inx) =>
                                                    <span key={`aq_${inx}`}>{winNos}</span>
                                                )}
                                            </div>}
                                            {this.props.drawType == 13 && this.props.PastDrawStateObj.win_number && this.props.PastDrawStateObj.win_number.split(';').map((ballNo, index) =>
                                                <div className="winningCombo" key={`ar_${index}`}>
                                                    <ul className="zodiacBallSec">
                                                        <li id="ball_526_2">
                                                            <a className={`game-13-balls-${parseInt(ballNo)} game-13`}>
                                                                <span>{ballNo}</span>
                                                            </a></li>
                                                    </ul>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="matches margin-top10">
                                        <div >{t('lblTotalPrize')} :</div>
                                        <div className="totalPrize"><span>{this.props.appvariables.currency}</span> &nbsp;{this.props.PastDrawStateObj.total_prize}</div>
                                    </div>
                                    <div className="matches margin-top10">
                                        <div >{t('lblTotwinner')} :</div>
                                        <div className="totalPrize">{this.props.PastDrawStateObj.total_winner}</div>
                                    </div>

                                </div>
                            </div>
                            {/* Main Draw Winners */}
                            {(unique && unique.length > 0) ? unique.map((obj, inx) =>
                                <>
                                    {obj == 1 && <div className="textHeader margin-top2">{t('lblMainDrawWin')}</div>}
                                    {obj == 2 && <div className="textHeader margin-top2">{t('lblRaffleWinners')}</div>}
                                    <div className={obj == 2 ? `winnersList marginTopNone` : `winnersList bg-game${this.props.drawType}fade15 border-radius-none`} key={`as_${inx}`}>
                                        {/* {this.state.RaffleResult && this.state.RaffleResult.length == 0 && <p className='marginAuto' style={{ textAlign: "center" }}>{t('lblNoRecordFound')}</p>} */}
                                        {this.state.RaffleResult.filter(result => result.draws_type == obj).map((result, index) =>
                                            // {obj.map(result =>
                                            <>
                                                {this.props.drawType != 13 && <div className="winnersListAlign" key={`at_${index}`}>
                                                    <div className="firstColumnName">
                                                        <div>{result.Player_name}</div>
                                                        <div>{result.Player_age} {result.Player_age ? " " + t('lblOld') : ""}  {result.Player_city ? " " + t('lblFrom') + " " : ""}<span>{result.Player_city}</span></div>
                                                    </div>
                                                    <div className="secondColumnDetails">
                                                        <div className={`${obj == 2 ? 'flex items-center' : ''}`} ><span>{obj == 2 ? <span>{t('lblRafflaID')}:</span> : <span className="text-right">{t('lblPurchase')}  :</span>}</span>
                                                            {obj == 2 ? <span> #{result.raffleid}</span> :
                                                                <span className={`winningCombo bg-game${this.props.drawType}`}>
                                                                    {result.raffleid && result.raffleid.split(';').map((ballNo, ind) =>
                                                                        <span key={`au_${ind}`} style={{ fontWeight: "normal" }}>{ballNo}</span>
                                                                    )}
                                                                </span>
                                                            }

                                                        </div>
                                                        <div><span >{t('lblWon')}:</span> <span> {this.props.appvariables.currency} {result.winamount}</span></div>
                                                    </div>
                                                </div>}

                                                {this.props.drawType == 13 && <div className="winnersListAlign cont-align-items">
                                                    <div className="firstColumnName w33">
                                                        <div>{result.Player_name}</div>
                                                        <div>{result.Player_age} {result.Player_age ? " " + t('lblOld') : ""}  {result.Player_city ? " " + t('lblFrom') + " " : ""}<span>{result.Player_city}</span></div>
                                                    </div>
                                                    <div className="secondColumnDetails w33">
                                                        <div className="flex items-center"><span>{t('lblWon')}:</span> <span> {this.props.appvariables.currency} {result.winamount} </span></div>
                                                    </div>
                                                    <div className="secondColumnDetails w33">
                                                        <>
                                                            <span>{obj == 2 ? <span>{t('lblRafflaID')}:</span> : <span className="text-right custom-line-height">{t('lblPurchase')}:</span>}</span>
                                                            {obj == 2 ? <span> #{result.raffleid}</span> :
                                                                <>
                                                                    {result.raffleid && result.raffleid.split(';').map((ballNo, index) =>
                                                                        <ul className="zodiacBallSec Latestlukyresult" key={`au_${index}`}>
                                                                            <li id="ball_526_2">
                                                                                <a className={`game-13-balls-${parseInt(ballNo)} game-13`}>
                                                                                    <span>{ballNo}</span>
                                                                                </a></li>
                                                                        </ul>
                                                                    )}
                                                                </>
                                                            }

                                                        </>

                                                    </div>
                                                </div>}
                                            </>
                                        )}
                                    </div >
                                </>)

                                :
                                <div className={`winnersList ${this.props.drawType == 9 ? "bg1Fade15" : "bg2Fade15"} border-radius-none`}>
                                    <p className='marginAuto' style={{ textAlign: "center" }}>{t('lblresultprogress')}</p>
                                    {/* <p className='marginAuto' style={{ textAlign: "center" }}>{t('lblNoRecordFound')}</p> */}
                                </div>
                            }

                        </div>
                        <button className="capitalize py-1 px-5 !text-white font-semibold mx-auto !bg-brand rounded-md close-popup1 border-2 bigpopupclose">
                            {t('lblclose')}
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    // const {breakdownlist} = state.accountReducer;
    const { previousResult, RaffleResult } = state.gameReducer;
    return { user, previousResult, RaffleResult, appvariables };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetPreviousResult: gameActions.GetPreviousResult,
    GetRaffleResults: gameActions.GetRaffleResults,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(WinnerPopup))
