import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const LangSelector = () => {

    const reloadLangCssFile = (event) => {
        if (event == "ar" || event == "ur") {
            let myid = document.getElementById('ar_css');
            if (myid == null || myid == undefined) {
                findAndRomoveCss('ar_css', 'custom-arabic5.css?v1.2');
                // let css = document.createElement('link');
                // css.id = "ar_css";
                // css.href = './assets/css/custom-arabic4.css?v1.1';
                // css.rel = 'stylesheet';
                // css.type = 'text/css';
                // document.getElementById('myscpt').appendChild(css);
                // let ml = document.getElementById('ml_css');
                // let es = document.getElementById('es_css');
                // if (ml != null && ml != undefined) {
                //     document.getElementById('ml_css').remove();
                // }
                // else if (es != null && es != undefined) {
                //     document.getElementById('es_css').remove();
                // }
            }
        }
        else if (event == "ml") {
            let myid1 = document.getElementById('ml_css');
            if (myid1 == null || myid1 == undefined) {
                findAndRomoveCss('ml_css', 'custom-malayalam5.css?v0.8')
                // let css = document.createElement('link');
                // css.id = "ml_css";
                // css.href = './assets/css/custom-malayalam4.css?v0.7';
                // css.rel = 'stylesheet';
                // css.type = 'text/css';
                // document.getElementById('myscpt').appendChild(css);
                // let ar = document.getElementById('ar_css');
                // let es = document.getElementById('es_css');
                // if (ar != null && ar != undefined) {
                //     document.getElementById('ar_css').remove();
                // }
                // else if (es != null && es != undefined) {
                //     document.getElementById('es_css').remove();
                // }
            }
        }
        else if (event == "es") {
            let myid1 = document.getElementById('es_css');
            if (myid1 == null || myid1 == undefined) {
                findAndRomoveCss('es_css', 'custom-spanish.css?v0.3')
                // let css = document.createElement('link');
                // css.id = "es_css";
                // css.href = './assets/css/custom-spanish.css?v0.1';
                // css.rel = 'stylesheet';
                // css.type = 'text/css';
                // document.getElementById('myscpt').appendChild(css);

                // let ar = document.getElementById('ar_css');
                // let ml = document.getElementById('ml_css');
                // if (ar != null && ar != undefined) {
                //     document.getElementById('ar_css').remove();
                // }
                // else if (ml != null && ml != undefined) {
                //     document.getElementById('ml_css').remove();
                // }
            }
        }
        else if (event == "tl") {
            let myid1 = document.getElementById('tl_css');
            if (myid1 == null || myid1 == undefined) {
                findAndRomoveCss('tl_css', 'custom-tagalog.css?v0.3')
                // let css = document.createElement('link');
                // css.id = "es_css";
                // css.href = './assets/css/custom-spanish.css?v0.1';
                // css.rel = 'stylesheet';
                // css.type = 'text/css';
                // document.getElementById('myscpt').appendChild(css);

                // let ar = document.getElementById('ar_css');
                // let ml = document.getElementById('ml_css');
                // if (ar != null && ar != undefined) {
                //     document.getElementById('ar_css').remove();
                // }
                // else if (ml != null && ml != undefined) {
                //     document.getElementById('ml_css').remove();
                // }
            }
        }
        else if (event == "ta") {
            let myid1 = document.getElementById('ta_css');
            if (myid1 == null || myid1 == undefined) {
                findAndRomoveCss('ta_css', 'custom-tamil.css?v0.4')
            }
        }
        else if (event == "hi") {
            let myid1 = document.getElementById('hi.css');
            if (myid1 == null || myid1 == undefined) {
                findAndRomoveCss('hi.css', 'custom-hindi.css?v0.3')
            }
        }
        else {
            findAndRomoveCss()
            // let ml = document.getElementById('ml_css');
            // let ar = document.getElementById('ar_css');
            // let es = document.getElementById('es_css');
            // if (ml != null && ml != undefined)
            //     document.getElementById('ml_css').remove();
            // if (ar != null && ar != undefined)
            //     document.getElementById('ar_css').remove();
            // if (es != null && es != undefined)
            //     document.getElementById('es_css').remove();
        }
    }

    const findAndRomoveCss = (cssname, css_path) => {
        // set css for website
        if (cssname != undefined && css_path != undefined) {
            let css = document.createElement('link');
            css.id = cssname;
            css.href = './assets/css/' + css_path;
            css.rel = 'stylesheet';
            css.type = 'text/css';
            document.getElementById('myscpt').appendChild(css);
        }
        // Remove Pervious Css File
        let Wholecss = ['ml_css', 'ar_css', 'es_css', 'tl_css', 'ta_css', 'hi.css']
        Wholecss = Wholecss.filter(a => a != cssname);
        Wholecss.forEach(element => {
            let css_file = document.getElementById(element);
            if (css_file != null && css_file != undefined) {
                css_file.remove();
            }
        });
    }

    const getCookie = (name) => {
        try {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            let lang_val = process.env.REACT_APP_DEFAULTLANGUAGE;
            //if (parts.length === 2)
            if (parts.length >= 2) {
                //lang_val =parts.pop().split(';').shift();
                let lst = value.split(`;`);
                let fobj = lst.filter(a => a.indexOf('gtlang=') >= 0);
                if (fobj && fobj.length > 0) {
                    let fobj1 = fobj[fobj.length - 1].split(`=`);
                    lang_val = fobj1[1];
                }
            }
            console.log("selected lang : " + lang_val);
            reloadLangCssFile(lang_val);
            return lang_val;
        }
        catch (ex) {
            return process.env.REACT_APP_DEFAULT_LANGUAGE;
        }
    };

    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(getCookie("gtlang"));


    const changeLanguage = (event) => {
        reloadLangCssFile(event)
        i18n.changeLanguage(event);
        document.cookie = "gtlang=" + event;
        setSelectedLang(event);
        window.location.reload(); //for get some translation from db
    }
    useEffect(() => {
        window.setLangDoggle();
    }, [])


    return (
        <div className="selLanguage" onChange={changeLanguage} id="lstLang">
            <a className="selLangBtn">
                <img src="./assets/img/icons/language.webp" />
                <span style={{ textTransform: 'uppercase' }} className="selectedLanguageName">{selectedLang}</span>
            </a>
            <ul className="selLangCont">
                <li><a onClick={() => changeLanguage('en')}>English</a></li>
                <li><a onClick={() => changeLanguage('ar')}>عربي</a></li>
                <li><a onClick={() => changeLanguage('ml')}>മലയാളം</a></li>
                <li><a onClick={() => changeLanguage('ur')}>اردو</a></li>
                <li><a onClick={() => changeLanguage('th')}>แบบไทย</a></li>
                <li><a onClick={() => changeLanguage('tl')}>Tagalog</a></li>
                {/* <li><a onClick={() => changeLanguage('es')}>spanish</a></li>
                <li><a onClick={() => changeLanguage('ta')}>தமிழ்</a></li>
                <li><a onClick={() => changeLanguage('hi')}>हिंदी</a></li> */}
            </ul>
        </div>
    )
}

export default LangSelector;