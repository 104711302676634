import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { history } from '../../helpers/history';

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerList: [],
            Promotionrule: [
                // { "offertype": "Dive into the Gold Rush!", "offer_quote": "Win Daily with the Countdown to 1st Draw", "img_no": 1, "date": "offer" },
                { "offertype": "LAUNCH EXTRAVAGANZA", "offer_quote": "Offer Lasts Only 3 Weeks After Launch.", "img_no": 5, "date": "offer" },
                { "offertype": "", "offer_quote": "We are thrilled to announce a significant change to our Super6 Raffle and Draw!", "img_no": 5, "date": "offer" },
                // { "offertype": "The Biggest Draw - Super 6 Offer", "offer_quote": "This opportunity is open for individuals across the world", "img_no": 2, "date": "FRI 18 AUG" },
                // { "offertype": "Special Launch Offer - Weekly Guaranteed Millionaire", "offer_quote": "This opportunity is open for individuals across the world", "img_no": 3, "date": "FRI 18 AUG" },
            ]
        };
    }

    OfferDetails = (inx) => {
        if (inx == 0) {
            history.push('/PromotionRules')
        }
        else {
            history.push('/OfferSuper6')
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                {/* <div className="card1">
                        <div>
                            <div className="card1-header"></div>
                            <span className="circleIcon">
                                <img src="./assets/img/falcon.png" alt="falcon" />
                            </span>
                            <div className="promotionLink">
                                <a onClick={() => history.push('/PromotionRules')}>
                                    <h4>{t('lblGulfLaunchOffer')}</h4>
                                    <p>{t('lblFortune5')}</p>
                                </a>
                            </div>
                        </div>
                    </div> */}
                <div className="wrap1">
                    <h2 className="headding3 floatNone">{t('lblPromotionRule')}</h2>
                    <div className="row">
                        <div className="pastDrawResultsSection marginBottomResult">
                            {this.state.Promotionrule && this.state.Promotionrule.map((x, inx) =>
                                <div className="pastDrawResultSubSection bg-white" key={`bg_${inx}`}>
                                    {inx == 0 && <div class={`dateAbsoluteSection bg-game9`}>{x.date}</div>}
                                    <div className="WinningContainer">
                                        <div className="WinningCome solid-border paddingNone">
                                            {/* <img  src={`${process.env.REACT_APP_BANNER_CDN}/Web/Promotion/promotions-${x.img_no}.png`} alt="" srcset="" /> */}
                                            <img src={`./assets/img/promotion/promotions-${x.img_no}.png?v0.1`} alt="promotions icon" srcset="" />
                                        </div>
                                        <div className="solid-border solid-border-1 paddingNone margin0 text-left promotionRulesCont">
                                            <p>{x.offertype}</p>
                                            <span>{x.offer_quote}</span>
                                        </div>
                                        <div className="detailsSection">
                                            <a onClick={() => { this.OfferDetails(inx) }}><img src="./assets/img/icons/Notes-3.svg" alt="details" /></a>
                                        </div>
                                        {/* <div class="dateAbsoluteSection logoBg !right-[-2.5rem] xl:!right-[-4.5rem] !text-[0.8rem]">
                                        {x.date} */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Promotion)