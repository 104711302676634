import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from '../Header/Header';
import { alertActions } from '../action/AlertAction';
import { gameActions } from '../action/GameAction';
import CryptoJS from 'crypto-js';
import { history } from '../helpers/history';
import { commonClass } from "../helpers/commonClass";
import { loginActions } from '../action/loginAction';
const PayTypeobj = { Online: 1, Wallet: 2 };
const OnlinePayType = { NoSelect: 0, Card: 1, Gpay: 2, Apay: 3 };
class Payment extends Component {
    constructor(props) {
        super(props);
        this.iframwrefs = React.createRef();
        this.state = {
            laccholcardno: '',
            laccholcvv: '',
            lblaccholexpdate: '',
            payType: PayTypeobj.Wallet, // 2  wallet , 1 Online
            redirecturl: null,
            setEvnt: false,
            showloader: true,
            TransactionID: 0,
            ShowOnOffPopup: false,
            ApprovedWalletAmt: false,
            SelCardOrWal: false,
            OnlinePayMode: OnlinePayType.NoSelect,
            ToggleHandlerFlag: false,
            PayAmount: props.cartItems.reduce((a, b) => a = a + b.stakeValue, 0),
            ProceedbtnFlag: false,
            OpenCardFlag: true,
            CartEmytyMsgFlag: false
        }
        //only allow logged in users
        if (!props.user || props.user.mobileNo == "0") {
            history.push('/login');
        }
        this.props.getBalance(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        }
    }
    // gotoPaymentVerify = () => {
    //     if ((this.state.laccholcardno != '' && this.state.laccholcvv != '' && this.state.lblaccholexpdate != '') || this.state.payType == 2) {
    //         this.props.setPaymentDetails(this.state.payType);
    //         history.push('/paymentverify');
    //     }
    //     else {
    //         this.props.alertError(this.props.t('lblPleaseFillDet'));
    //     }
    // }
    gotoPaymentVerify = (cardVerify) => {
        // if ((this.state.payType == 1 && cardVerify == 0) || this.state.payType == 2) {
        //     if (this.props.user.balance == 0) {
        //         this.props.alertError(this.props.t('lblAmntInsuff'));
        //         return;
        //     }
        //     else {
        //         this.props.setPaymentDetails(this.state.payType);
        //         this.BuyLotteryBet();
        //     }
        // }
        // else {
        //     this.props.alertError(this.props.t('lblPleaseFillDet'));
        // }
        let CartItemAmt = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
        let UserBalandWinAmt = this.props.user.balance - this.props.user.winbalance;
        if (this.state.payType == PayTypeobj.Wallet && this.props.user.balance < CartItemAmt) {
            this.props.alertError(this.props.t('lblAmntInsuff'));
        }
        else if (this.state.payType == PayTypeobj.Wallet && CartItemAmt > UserBalandWinAmt) {
            this.state.ShowOnOffPopup = true;
            return;
        }
        else if (this.state.payType == PayTypeobj.Online && cardVerify != 0) {
            this.props.alertError(this.props.t('lblPaymentFailed'));
        }
        else {
            //check sale closeTime implemented
            if (!cardVerify) {
                const _sts = this.checkSaleClosedStatus();
                if (_sts) { return }
            }
            this.props.setPaymentDetails(this.state.payType);
            this.BuyLotteryBet();
        }
    }

    checkSaleClosedStatus = () => {
        let _sStatus = false;
        let CloseCount = 0;
        //multi draw
        //let Gamegid = [...new Set(this.state.cartItems.map(item => item.gamegroupID))];
        let Gamegid = [...new Set(this.state.cartItems.map(item => item.GameId))];
        // Gamegid.forEach(ggID => {
        for (var i = 0; i <= Gamegid.length - 1; i++) {
            let ggID = Gamegid[i]
            let fcartItems = this.state.cartItems.filter(a => a.GameId == ggID);
            //let GamegWiseid = [...new Set(fcartItems.map(item => item.GameId))];
            const _date = fcartItems && fcartItems.length > 0 && fcartItems.filter(Fobj => Fobj.GameId == ggID);
            const _dateObj = _date[0];
            if (_dateObj) {
                const _date = _dateObj.DrawDateTime;
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                let SaleCloseTime = sTime - _dateObj.SaleCloseTimeMSec;
                let SaleStatusRemaining = SaleCloseTime - this.props.serverTime;
                const SaleStatus = (SaleStatusRemaining >= 0 ? 0 : 1);
                // if (_sStatus == false && SaleStatus == 1) {
                if (SaleStatus == 1) {
                    this.props.RemoveClosedDrawbetFromCart(_dateObj.GameId)
                    // this.props.ClearCart()
                    // this.props.alertError(this.props.t('lblclosed'));
                    _sStatus = true;
                    CloseCount++;
                }
            }
        }
        // })
        if (CloseCount == Gamegid.length) {
            setTimeout(() => {
                history.push('/home');
            }, 4000);
        }
        return _sStatus;
    }

    handletxtclick(e) {
        const regex = /^[0-9\b]+$/;
        if (regex.test(e.target.value) || e.target.value == "") {
            this.setState({ [e.target.name]: e.target.value });
        }
    }
    handleInputChange(e) {
        if (e.target.value != '') { this.setState({ [e.target.name]: e.target.value }); }
    }
    setPayType = (_payType) => {
        // if (this.state.payType == _payType) { return; } // && flag == undefined  use my wallet old option using code 
        if (_payType == PayTypeobj.Online) {
            this.setState({ ProceedbtnFlag: true })
            //check sale closeTime implemented
            const _sts = this.checkSaleClosedStatus();
            // if (_sts) { return }
            if (_sts) { window.location.reload() } // Loaded Not Word
            // let amt = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
            let amt = this.state.PayAmount;
            let deviceId = "~1"; //1- for web , 2-for-mobileapp 3 - for mobileWeb
            let payType = "~2"; //2- for buy
            var sTime = new Date(this.props.serverTime);
            let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
            this.setState({ TransactionID: TransID })
            localStorage.setItem("c_tid", TransID)//To handle transaction while refresh the page
            const bstr = this.generateBuyInfo();
            if (bstr == "") { return }
            let lreqdata = CryptoJS.AES.encrypt(this.props.user.mobileNo + "~" + this.props.user.loginKey + "~" + this.props.user.Country_code.replaceAll("+", "") + "~" + this.props.user.balance + "~" + amt + "~" + this.props.i18n.language + deviceId + payType + "~" + TransID + "~" + process.env.REACT_APP_DESKTOP_URL + "~" + bstr, "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=");
            let wallet_base_url = process.env.REACT_APP_WALLET_URL;
            let OnlineFormUrl = this.state.OnlinePayMode == OnlinePayType.Card ? '?data=' : this.state.OnlinePayMode == OnlinePayType.Gpay ? 'gpay/addmoney?data=' : 'apay/addmoney?data='
            OnlineFormUrl = wallet_base_url + OnlineFormUrl;
            this.state.redirecturl = OnlineFormUrl + lreqdata.toString().replaceAll("+", "@");
            this.setState({ showloader: true });
            this.setState({ setEvnt: false });
            // window.location.href = this.state.redirecturl;
        }
        // else {
        //     this.setState({ ApprovedWalletAmt: false });  // use my wallet old option using code 
        // }
        this.setState({ payType: _payType });
    }
    getCardPayStatus = (msgData) => {
        if (msgData.type == "buypay" && msgData.paystatus != "") {
            let mybet = { status: msgData.paystatus, msg: msgData.paymsg };
            if (mybet.status == 0) {
                // commonClass.SendPageDataLayer(10); // this event implemented in pg source
                this.gotoPaymentVerify(mybet.status);
            }
            else {
                // commonClass.SendPageDataLayer(11); // this event implemented in pg source
                mybet.status = 3; // Set Status 3 Show PaymentStatus Page tryAgain button
                mybet.msg = mybet.msg ? mybet.msg : msgData.paystatus;
                this.props.setPayErrorStatus(mybet);
            }
        }
        else {
            this.setPayType(1);
        }
    }
    updateGtmCardPay = (msgData) => {
        commonClass.SendPageDataLayer(9);
    }
    componentDidMount() {
        if (this.props.location) {
            const query = new URLSearchParams(this.props.location.search);
            const token = query && query.get('pn')
            if (token) {
                const token1 = CryptoJS.AES.decrypt(token.replaceAll("@", "+").replaceAll(" ", "+"), "U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=").toString(CryptoJS.enc.Utf8);
                const ft1 = token1.split("^^");
                const ft = ft1[0].split("~");
                localStorage.setItem('sendEventFlog', 1); // Online buy After Failure Not Fired.
                if (ft1 && ft1.length > 1 && ft1[1] != "0") {
                    const f1 = { type: "buypay", paystatus: ft[0], paymsg: ft[1], binfo: ft1[1] };
                    this.state.payType = PayTypeobj.Online;
                    this.getCardPayStatus_OnlinePay(f1);
                }
                else if (ft && ft.length > 1) {
                    const f = { type: "buypay", paystatus: ft[0], paymsg: ft[1] };
                    this.state.payType = PayTypeobj.Online;
                    this.props.loading();
                    let tmr = setInterval(
                        () => {
                            if (this.state.cartItems && this.state.cartItems.length > 0) {
                                this.getCardPayStatus(f);
                                clearInterval(tmr);
                            }
                        }
                        , 500)
                }
            }
            else {
                this.setState({ CartEmytyMsgFlag: true })
            }
        }
        window.getCardPayStatus(this.getCardPayStatus);
        if (window.updateGtmCardPay) {
            window.updateGtmCardPay(this.updateGtmCardPay);
        }
        window.addEventListener('message', window.receiveMessage, false);
        this.HandleCheckBox(null, true)
    }
    componentDidUpdate() {
        this.onLoadMyVirtual();
    }
    BuyLotteryBet = () => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg(this.props.t('lblPlsLoginOrSignUp'));
            return;
        }
        if (this.state.cartItems.length == 0) {
            this.showMsg(this.props.t('lblplcurbet'));
            return;
        }
        let gamestring = "";
        let totStake = 0;
        let Gamegid = [...new Set(this.state.cartItems.map(item => item.gamegroupID))];
        let trasnIDs1 = [...new Set(this.state.cartItems.map(item => item.GameId))];
        trasnIDs1.forEach(ggID => {
            let fcartItems = this.state.cartItems.filter(a => a.GameId == ggID);
            let inx = 1;
            fcartItems.forEach(x => {
                if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
                    this.showMsg('lblplcurbet');
                    return;
                }
                let item = (x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')
                const Ovalue = x.OddsVal ? x.OddsVal : 0;
                gamestring += x.GameId + "~" + x.DrawTime + "~" + inx + "~" + item + "~" + Ovalue + "~" + x.minStake + "~" + x.minStake + "|";
                inx++;

            });
            gamestring += "^";
        });
        let x = this.state.cartItems[0];
        let TransID = this.state.TransactionID;
        // var sTime = new Date(this.props.serverTime);
        // let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
        if (this.state.payType == PayTypeobj.Online && TransID == 0) {
            TransID = localStorage.getItem("c_tid")
            if (!TransID) {
                return;
            }
        }
        else {
            var sTime = new Date(this.props.serverTime);
            TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
        }
        let ObjBuy = {
            "reflot": x.GameId,
            "group_id": parseInt(x.gamegroupID),
            "draw_time": x.DrawTime,
            "bet_info": gamestring,
            "client_transid": TransID,
            "amount": totStake,
            "type_id": this.state.payType //1 Payment  2 Wallet
        }
        // this.sendTrackingCode(Gamegid,PayTypeobj.Wallet)
        commonClass.SendEventTracking(17, {}, false, this.state.cartItems)
        this.props.Buybet(ObjBuy, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, Gamegid);
        //becoz already using in Buybet function
        //this.props.ClearCart();
        localStorage.removeItem("c_tid")
    };
    gotoAddMoney = () => {
        localStorage.setItem('atabId', 3);
        localStorage.setItem('isRedirectToCart', 1);
        history.push('/Account');
    }
    onLoadMyVirtual = () => {
        if (this.iframwrefs.current && this.state.setEvnt != true && this.state.payType == PayTypeobj.Online) {
            this.state.setEvnt = true;
            var iframe = this.iframwrefs.current;
            if (this.props.user.mobileNo != 0) { // only for logged in players
                let mythis = this;
                if (iframe.attachEvent) { // for IE
                    iframe.attachEvent("onload", function () {
                        console.log("iframe.attachEvent")
                        mythis.setState({ showloader: false });
                    });
                } else { // for Non IE
                    iframe.onload = function () {
                        console.log("iframe.onload")
                        mythis.setState({ showloader: false });
                    };
                }
            }
            let mythis1 = this;
            let tmr1 = setTimeout(
                () => {
                    if (mythis1.state.showloader) { mythis1.setState({ showloader: false }); }
                    clearTimeout(tmr1);
                }
                , 4000)
        }
    }
    generateBuyInfo = () => {
        try {
            if (this.props.user == undefined || this.props.user.mobileNo == "0") {
                this.showMsg(this.props.t('lblPlsLoginOrSignUp'));
                return;
            }
            if (this.state.cartItems.length == 0) {
                this.showMsg(this.props.t('lblplcurbet'));
                return;
            }
            let gamestring = "";
            let Gamegid = [...new Set(this.state.cartItems.map(item => item.gamegroupID))];
            let Gamegid1 = [...new Set(this.state.cartItems.map(item => item.GameId))];
            localStorage.setItem('Gamegid', JSON.stringify(Gamegid));
            Gamegid1.forEach(ggID => {
                let fcartItems = this.state.cartItems.filter(a => a.GameId == ggID);
                let inx = 1;
                fcartItems.forEach(x => {
                    if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
                        this.showMsg('lblplcurbet');
                        return;
                    }
                    let item = (x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')
                    const Ovalue = x.OddsVal ? x.OddsVal : 0;
                    gamestring += x.GameId + "*" + x.DrawTime + "*" + inx + "*" + item + "*" + Ovalue + "*" + x.minStake + "*" + x.minStake + "|";
                    inx++;

                });
                gamestring += "^";
            });
            let x = this.state.cartItems[0];
            let TransID = this.state.TransactionID;
            if (this.state.payType == PayTypeobj.Online && TransID == 0) {
                TransID = localStorage.getItem("c_tid")
                if (!TransID) {
                    return;
                }
            }
            else {
                var sTime = new Date(this.props.serverTime);
                TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
            }
            // this.sendTrackingCode(Gamegid,PayTypeobj.Online)
            commonClass.SendEventTracking(17, {}, false, this.state.cartItems)
            return gamestring;
        } catch (error) {
            return "";
        }
    };

    getCardPayStatus_OnlinePay = (msgData) => {
        if (msgData.type == "buypay" && msgData.paystatus != "") {
            let mybet = { status: msgData.paystatus, msg: msgData.paymsg, binfo: msgData.binfo };
            if (mybet.status == 0) {
                const Gamegid = JSON.parse(localStorage.getItem('Gamegid'));
                localStorage.removeItem('Gamegid')
                this.props.Buybet_OnlinePay(mybet.binfo, this.props.user.mobileNo, Gamegid);
            }
            else {
                // commonClass.SendPageDataLayer(11); // this event implemented in pg source
                mybet.status = 3; // Set Status 3 Show PaymentStatus Page tryAgain button
                mybet.msg = mybet.msg ? mybet.msg : msgData.paystatus;
                this.props.setPayErrorStatus(mybet);
            }
        }
        else {
            this.setPayType(1);
        }
        localStorage.removeItem("c_tid");
    }
    userConfirmation = (flag) => {
        this.setState({ ShowOnOffPopup: false })
        if (flag == 0) {
            const _sts = this.checkSaleClosedStatus();
            if (_sts) { return }
            this.props.setPaymentDetails(this.state.payType);
            this.BuyLotteryBet();
        }
    }

    HandleCheckBox = (e, initload) => {
        this.setState({ OnlinePayMode: OnlinePayType.NoSelect });
        let Ischecked = initload ? initload : e.target.checked;
        this.setState({ ApprovedWalletAmt: Ischecked })
        if (this.props.user.balance <= 0 && Ischecked) {
            this.setState({ ApprovedWalletAmt: false })
        }
        if (Ischecked == true) {
            let amt = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
            if (this.props.user.balance >= amt) {
                this.setState({ SelCardOrWal: false });
                this.setState({ PayAmount: 0 })
            }
            else {
                this.setState({ SelCardOrWal: true });
                this.setState({ PayAmount: (amt - this.props.user.balance).toFixed(2) });
            }
        }
        else {
            let amt = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
            this.setState({ SelCardOrWal: true })
            this.setState({ PayAmount: amt })
        }
    }

    // OnlinePayment Type Set Values 
    onSiteChanged = (e) => {
        this.setState({ OnlinePayMode: e.target.value })
    }

    //Use My Wallet Option Set Flag
    ToggleHandler = (e) => {
        this.setState({ ToggleHandlerFlag: e.target.checked })
    }

    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }

    //Payment Proceed Button For Online Payments
    ChooseOnlinePayment = () => {
        if (this.state.OnlinePayMode == OnlinePayType.NoSelect) { // Check WithOut Click Online Payment Option Proceed To Pay
            this.showMsg('lblskipOnPay');
        }
        else {
            this.setPayType(PayTypeobj.Online)
        }
    }

    sendTrackingCode = (Gamegid, Paymode) => {
        let parr = []
        Gamegid.forEach(ggID => {
            let url = '';
            let imgurl = '';
            switch (ggID) {
                case '9':
                    url = "/fortune4";
                    imgurl = 'fortune-4-logo.png'
                    break;
                case '10':
                    url = "/super6";
                    imgurl = 'super-6-logo.png?v0.2'
                    break;
                case '11':
                    url = "/magic5";
                    imgurl = 'magic-5-logo.png?v0.2'
                    break;
                default:
                    url = "Unknow";
                    imgurl = 'Unknow';
                    break;
            }
            let fcartItems = this.state.cartItems.filter(a => a.gamegroupID == ggID);
            parr.push({
                'product_name': fcartItems[0].LotName,
                'prod_url': process.env.REACT_APP_DESKTOP_URL + url,
                'prod_img_url': process.env.REACT_APP_DESKTOP_URL + '/assets/img/gamesBg/' + imgurl,
                'prqt': fcartItems.length,
                'product_price': fcartItems[0].stakeValue,
                // 'payment_method':Paymode
            })
        })
        let Obj = {
            'items': parr,
        }
        commonClass.SendEventTracking(17, Obj)
    }

    backtogame = (gameID) => {
        let prevCartitem = this.state.cartItems;
        this.props.BackFromCart(prevCartitem, gameID);
    }

    ChangeFlag = () => {
        this.setState({ CartEmytyMsgFlag: false })
        this.setState({ OpenCardFlag: false })
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
        //after my card page merged 22/08/2024
        commonClass.SendPageDataLayer(8);
        commonClass.SendEventTracking(14, {}, false, this.state.cartItems);
    }

    removeTicket = (obj) => {
        let gamePadID = obj.GamePadId;
        let gameID = obj.gamegroupID
        let lst1 = this.state.cartItems.filter(a => (a.GamePadId != gamePadID || a.gamegroupID != gameID))
        let amt = lst1.reduce((a, b) => a = a + b.stakeValue, 0);
        if (this.props.user.balance >= amt) {
            this.setState({ SelCardOrWal: false });
            this.setState({ PayAmount: 0 })
        }
        else {
            // this.setState({ SelCardOrWal: true })
            this.setState({ PayAmount: (amt - this.props.user.balance).toFixed(2) });
        }
        this.props.RemoveFromCart(gamePadID, gameID);
        let cobj = {};
        let tcartitem = [obj]
        commonClass.SendEventTracking(10, cobj, false, tcartitem);
    }

    render() {
        const { t } = this.props
        let trasnIDs = [...new Set(this.state.cartItems.map(item => item.gamegroupID))]
        // let CartitemAmt = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
        let gameIDs = [...new Set(this.state.cartItems.filter(a => a.gamegroupID == 13).map(item => item.GameId))];
        gameIDs = gameIDs.sort((a, b) => (a < b) ? 1 : -1); // Loop From Lucky1 Multiple Game If delete Tickets Collapse Loop.
        return (
            <>
                <Header />
                <div className="wrap2">
                    <h2 className="headding3">{t('lblPayment')}</h2>
                </div>
                <div className="wrap1">
                    {this.state.cartItems && this.state.cartItems.length > 0 ?
                        <section>
                            <div className="col-md-7 col-sm-12 col-xs-12 flex-col">
                                <div className="cartItems scrollbar2">
                                    {/* old payment flow */}
                                    {/* {this.state.payType == PayTypeobj.Online &&                                
                                <div className="myCartLft creditCardDiv">
                                    <div className="cardSec">
                                        <span>{t('lblcardNo')}</span>
                                        <div className="cardDtls">
                                            <div className="cardNo">
                                                <input type="text" name='laccholcardno' placeholder="0000 0000 0000 0000" value={this.state.laccholcardno} onChange={this.handletxtclick.bind(this)} maxLength={16} autoComplete='off' />
                                                <div><img src="./assets/img/visa.webp" alt="Card" /></div>
                                            </div>
                                            {this.state.laccholcardno.length > 5 && <small>{t('lblVISACardEnd')} {this.state.laccholcardno.substring(this.state.laccholcardno - 5, this.state.laccholcardno - 1)}.{t('lblConfSecurityCvv')}.</small>}
                                            <div className="dateCvv">
                                                <input type="password" name='laccholcvv' placeholder={t('lblCvv')} value={this.state.laccholcvv} onChange={this.handletxtclick.bind(this)} maxLength={3} autoComplete='off' />
                                                <input type="text" placeholder="MM/YY" name="lblaccholexpdate" value={this.state.lblaccholexpdate} onChange={this.handleInputChange.bind(this)} maxLength={5} autoComplete='off' />
                                                <img src="./assets/img/icons/credit-card.webp" alt="Card" />
                                            </div>
                                            <p>{t('lblAmtPayByCard')}: <b>{this.props.appvariables.currency} {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</b></p>
                                            <small>{t('lblYourPaymetReflect')}</small>
                                        </div>
                                    </div>
                                </div> 
                                } */}

                                    {/* New Payment Flow Code  */}
                                    {/* {this.state.payType == PayTypeobj.Online &&
                                <>
                                    {this.state.showloader && <div id="divLoading" style={{ position: "absolute", height: "85%" }}>
                                        <div id="center">
                                            <div className="lds-dual-ring"><div></div><div></div></div>
                                        </div>
                                    </div>
                                    }
                                    <iframe ref={this.iframwrefs} src={this.state.redirecturl} width="100%" height="500" style={{ "border": "none" }}></iframe>
                                </>
                            } */}

                                    {/* New Payment Flow Code  */}
                                    {/* {this.state.payType == PayTypeobj.Wallet &&
                                <div className="myCartLft walletPaddingBottom walletBackground">
                                    <div className="walletTopSec">
                                        <div>{t('lblBal')}:</div>
                                        <button className="addCreditBtn" onClick={() => { this.gotoAddMoney() }}>{t('lblAddCredit')}</button>
                                        <div className="switchBtnSec" >
                                            <div className="quickBetInr">
                                                <label for="switch" className="switchbtn">
                                                    <input type="checkbox" id="switch" name='ApprovedWalletAmt' checked={this.state.ApprovedWalletAmt} value={this.state.ApprovedWalletAmt} onChange={(e) => { this.HandleCheckBox(e) }} />
                                                    <span className="sliderbtn"></span>
                                                </label>
                                                {this.state.ApprovedWalletAmt ? <span className="signin">{t('lblNotUseWallet')}</span> : <span className="signin">{t('lblUseWallet')}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <h2>{this.props.appvariables.currency} {this.props.user.balance}</h2>
                                    <small>{t('lblAmntInWallet')}</small>
                                    <div className="overlaypayment" style={{ display: (this.state.ApprovedWalletAmt ? "none" : "") }}></div>
                                </div>
                            } */}

                                    {/* Old Payment Flow Code  */}
                                    {/* <div className="walletSec">
                                <div className="walletSecInner">
                                    <a onClick={() => { this.setPayType(2) }}>
                                        <div className={`pBtn pBtn-secondary pBtnLink ${this.state.payType == PayTypeobj.Wallet ? 'bgpay' : ''}`}>
                                            <span className={`pIcon ${this.state.payType == PayTypeobj.Wallet ? 'pIconWallet' : 'pIconCCard'}`}></span>
                                            <label className="pBtnTxt ">GT {t('lblWallet')}</label>
                                        </div>
                                    </a>
                                </div>
                                <div className="walletSecInner">
                                    <a onClick={() => { this.setPayType(1) }}>
                                        <div className={`pBtn pBtn-secondary pBtnLink ${this.state.payType == PayTypeobj.Online ? "bgpay" : ""}`}>
                                            <span className={`pIcon ${this.state.payType == PayTypeobj.Online ? 'pIconWallet' : 'pIconCCard'}`}></span>
                                            <label className="pBtnTxt">{t('lblOnlinePay')}</label>
                                        </div>
                                    </a>
                                </div>
                                <div className="walletSecInner" style={{ "marginLeft": "20%", "marginTop": "-20px" }}>
                                    {this.state.payType == PayTypeobj.Wallet && CartitemAmt > this.props.user.balance && this.props.user.balance > 0 && <div className="checkBox" style={{ "paddingLeft": "200px" }}> <label className="checkLble" style={{ "marginBottom": "-8px", "color": "#494949" }}>{t('lblApprovedWalletAmt')}
                                        <input className="checkboxInput" type="checkbox" value={this.state.ApprovedWalletAmt} name='ApprovedWalletAmt' checked={this.state.ApprovedWalletAmt} onChange={(e) => { this.HandleCheckBox(e) }}></input>
                                        <span className="checkmark border-color"></span>
                                        <span style={{ "color": "#494949" }}>({process.env.REACT_APP_CURRENCY} {this.props.user.balance})</span>
                                    </label>
                                    </div>
                                    }
                                </div>
                            </div> */}

                                    {/* New Payment Flow Code  */}
                                    {/* {this.state.SelCardOrWal && !this.state.ProceedbtnFlag && <> <div className="paymentMethod">
                                <ul>
                                    <li><input id="r1" type="radio" value={OnlinePayType.Card} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio1" /><label className="border-color dcCard" for="r1"><span>{t('lblcards')}</span></label></li>
                                    <li><input id="r2" type="radio" value={OnlinePayType.Gpay} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio2" /><label className="border-color gpay" for="r2"><span>{t('lblgpay')}</span></label></li>
                                    <li><input id="r3" type="radio" value={OnlinePayType.Apay} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio3" /><label className="border-color applepay" for="r3"><span>{t('lblapay')}</span></label></li>
                                </ul>
                            </div>
                                <div className="pBtnWrap">
                                    <button className="pBtn pBtn-secondary" onClick={() => { this.ChooseOnlinePayment() }}>{t('lblproceed')} {this.state.PayAmount} {this.props.appvariables.currency}</button>
                                </div>
                            </>} */}

                                    {trasnIDs.map((objId, inx) =>
                                        <div className={`myCartLft ${objId == 13 ? 'myCartLft60' : ''}`} id='myCartDiv' key={`x_${inx}`}>
                                            <div className={`myCartLftTxt ${objId == 13 ? 'myCartLftTxt40' : ''}`} >
                                                {objId == 9 ?
                                                    <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt="Fortune 4 logo" className="h-10 w-24" /> :
                                                    objId == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt="super 6 logo" className="h-10 w-24" />
                                                        : objId == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" />
                                                            : objId == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" />
                                                                :objId == 14 ? <img src="./assets/img/gamesBg/dream-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" /> : 
                                                                 <img src="./assets/img/gamesBg/dream-5-logo.png?v0.2" alt="Dream 5 logo" className="h-10 w-24" />}
                                                {objId != 13 &&
                                                    <>
                                                        <p>{t('lblDrawDate')}:</p>
                                                        <p>{this.state.cartItems.filter(a => a.gamegroupID == objId)[0].DrawShowTime}</p>
                                                    </>
                                                }
                                            </div>
                                            <ul>
                                                {objId != 13 ? <>
                                                    {this.state.cartItems.filter(a => a.gamegroupID == objId).map((obj, inx) =>
                                                        // <li className={objId == 9 ? 'row ticketLineFlex' : 'ticketLineFlex'}>
                                                        <li className='ticketLineFlex' key={`y_${inx}`}>
                                                            <div className={`myCartLine bg-game${objId} col-xs-12 col-sm-3 col-md-3`} >{t('lblLine')} {inx + 1}</div>
                                                            <div className={`myCartBall border-game${objId}`}>
                                                                {obj.BetSelection && obj.BetSelection.map((_bal, inx) =>
                                                                    <span key={`z_${inx}`} className={`bg-game${objId}`}>{_bal}</span>
                                                                )}
                                                            </div>
                                                            <button type="button" className="text-center deleteBtn marginLeft1">
                                                                {this.state.OpenCardFlag && <img src="./assets/img/icons/delete.webp" alt="delete button" className="margin5Top" onClick={() => this.removeTicket(obj)} />}
                                                            </button>
                                                            {/* <div style={{ width: "30%", marginRight: "-18%", float: "right", fontFamily: "SourceSans3-Bold", fontSize: "16px", textAlign: "center", marginTop: "-20px" }}><a style={{  marginLeft:  "-62px"  }} onClick={() => this.removeTicket(obj.GamePadId, obj.gamegroupID)}>X</a></div> */}
                                                        </li>
                                                    )
                                                    }
                                                </>
                                                    :
                                                    <>
                                                        {gameIDs.map((gobjId, inx1) =>
                                                            <>
                                                                {this.state.cartItems.filter(a => a.gamegroupID == objId && a.GameId == gobjId).map((obj, inx) =>
                                                                    <>
                                                                        {inx == 0 &&
                                                                            <li  className='ticketLineFlex lckticketLineFlex'>
                                                                                <div className="myCartLftTxt Luckypayspace" style={{width:"100%"}}>
                                                                                {/* <p>{t('lblDrawDate')}:</p> */}
                                                                                <p style={{textAlign:"right"}}>{t('lblDrawDate')} : {this.state.cartItems.filter(a => a.gamegroupID == objId && a.GameId == gobjId)[0].DrawShowTime}</p>
                                                                            </div > </li>}
                                                                        <li className='ticketLineFlex lckticketLineFlex'>
                                                                            <div className={`lckmyCartLine myCartLine border-game13 bg-game13 col-xs-12 col-sm-3 col-md-3`} >{t('lblLine')} {inx + 1}</div>
                                                                            {obj.BetSelection && obj.BetSelection.map((_bal, inx) =>
                                                                                <div className={`lckmyCartBall border-game13 game-13-balls-${_bal}`} key={`aa_${inx}`}>
                                                                                    <span>{_bal}</span>
                                                                                </div>
                                                                            )}
                                                                            <div className="lckmyCartLine myCartLine border-game13 col-xs-12 col-sm-3 col-md-3 colorTxtG">{obj.OddsVal}x</div>
                                                                            <button type="button" className="text-center deleteBtn marginLeft1">
                                                                                {this.state.OpenCardFlag && <img src="./assets/img/icons/delete.webp" alt="delete button" className="margin5Top" onClick={() => this.removeTicket(obj)} />}
                                                                            </button>
                                                                        </li>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className='pBtnWrap'>
                                    <button className="pBtn pBtn-secondary" onClick={() => this.backtogame(this.state.cartItems[0].gamegroupID)}>{t('lblBack')}</button>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 col-xs-12">
                                <div className="myCartRht">
                                    <div className="BetslipTop">
                                        <span className="span1">{t('lblItem')}</span>
                                        <span className="span2">{t('lblQuantity')}</span>
                                        <span className="span3">{t('lblAmt')}</span>
                                    </div>
                                    {trasnIDs.map((objId, inx) =>
                                        <div className="BetslipLi" key={`ab_${inx}`}>
                                            <span className="span1">{this.state.cartItems.filter(a => a.gamegroupID == objId)[0].LotName}</span>
                                            <span className="span2">{this.state.cartItems.filter(a => a.gamegroupID == objId).length}</span>
                                            <span className="span3">{this.props.appvariables.currency} {this.state.cartItems.filter(a => a.gamegroupID == objId).reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                                        </div>
                                    )}
                                    <div className="BetslipLi">
                                        <span className="span4">{t('lblTotalamtPay')}</span>
                                        <span className="span3">{this.props.appvariables.currency} {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                                    </div>
                                    {/* New Payment Flow Code  */}
                                    {/* {!this.state.SelCardOrWal && <div className="pBtnWrap">
                                    <button id="btnCardPay" className='pBtn pBtn-secondary' onClick={() => { this.gotoPaymentVerify() }}>{t('lblPaynow')}</button>
                                </div>} */}
                                    {this.state.OpenCardFlag && <div className="pBtnWrap">
                                        <button id="btnCardPay" style={{ marginBottom: "10px" }} className='pBtn pBtn-secondary' onClick={() => { this.ChangeFlag() }}>{t('lblPaynow')}</button>
                                    </div>}
                                </div>

                                <>
                                    {this.state.payType == PayTypeobj.Online &&
                                        <>
                                            {this.state.showloader && <div id="divLoading" style={{ position: "absolute", height: "72%", top: "230px" }}>
                                                <div id="center">
                                                    <div className="lds-dual-ring"><div></div><div></div></div>
                                                </div>
                                            </div>
                                            }
                                            <iframe ref={this.iframwrefs} src={this.state.redirecturl} width="100%" height="500" style={{ position: "relative", "border": "none", top: "35px" }}></iframe>
                                        </>}

                                    {this.state.payType == PayTypeobj.Wallet && !this.state.OpenCardFlag &&
                                        <div className="myCartLft walletPaddingBottom walletBackground" style={{ marginTop: "40px" }}>
                                            <div className="walletTopSec">
                                                <div>{t('lblBal')}:</div>
                                                <button className="addCreditBtn" onClick={() => { this.gotoAddMoney() }}>{t('lblAddCredit')}</button>
                                                <div className="switchBtnSec" >
                                                    <div className="quickBetInr">
                                                        <label for="switch" className="switchbtn">
                                                            <input type="checkbox" id="switch" name='ApprovedWalletAmt' checked={this.state.ApprovedWalletAmt} value={this.state.ApprovedWalletAmt} onChange={(e) => { this.HandleCheckBox(e) }} />
                                                            <span className="sliderbtn"></span>
                                                        </label>
                                                        {this.state.ApprovedWalletAmt ? <span className="signin">{t('lblNotUseWallet')}</span> : <span className="signin">{t('lblUseWallet')}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <h2>{this.props.appvariables.currency} {this.props.user.balance}</h2>
                                            <small>{t('lblAmntInWallet')}</small>
                                            <div className="overlaypayment" style={{ display: (this.state.ApprovedWalletAmt ? "none" : "") }}></div>
                                        </div>
                                    }
                                    {this.state.SelCardOrWal && !this.state.ProceedbtnFlag && !this.state.OpenCardFlag && <> <div className="paymentMethod">
                                        <ul>
                                            <li><input id="r1" type="radio" checked={this.state.OnlinePayMode == OnlinePayType.Card} value={OnlinePayType.Card} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio1" /><label className="border-color dcCard" for="r1"><span>{t('lblcards')}</span></label></li>
                                            <li><input id="r2" type="radio" checked={this.state.OnlinePayMode == OnlinePayType.Gpay} value={OnlinePayType.Gpay} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio2" /><label className="border-color gpay" for="r2"><span>{t('lblgpay')}</span></label></li>
                                            <li><input id="r3" type="radio" checked={this.state.OnlinePayMode == OnlinePayType.Apay} value={OnlinePayType.Apay} onChange={(e) => { this.onSiteChanged(e) }} name="group1" className="radio3" /><label className="border-color applepay" for="r3"><span>{t('lblapay')}</span></label></li>
                                        </ul>
                                    </div>
                                        <div className="pBtnWrap">
                                            <button className="pBtn pBtn-secondary" onClick={() => { this.ChooseOnlinePayment() }}>{t('lblproceed')} {this.state.PayAmount} {this.props.appvariables.currency}</button>
                                        </div>
                                    </>}
                                </>
                                {!this.state.SelCardOrWal && !this.state.OpenCardFlag && <div className="pBtnWrap">
                                    <button id="btnCardPay" className='pBtn pBtn-secondary' onClick={() => { this.gotoPaymentVerify() }}>{t('lblPaynow')}</button>
                                </div>}
                            </div>

                        </section >
                        :
                        <>
                            {this.state.CartEmytyMsgFlag &&
                                <section>
                                    <div className="col-lg-7 col-sm-12 col-xs-12">
                                        {t('lblCartempty')}

                                    </div>
                                    <div className='payNowBtn myCartPayNow'>
                                        <button className="payNowBtn" onClick={() => history.push('/home')}>{t('lblBactToHome')}</button>
                                    </div>
                                </section>}
                        </>
                    }
                    {this.state.ShowOnOffPopup && <div>
                        <div style={{ transform: "translate(-50%, -50%)", display: "block" }} className="lightbox AlertpopUp">
                            <div className="lightBox-wrap">
                                <div className="userContent">
                                    <h2 style={{ padding: "10px" }}>{t('lblwinbal')}</h2>
                                    <div className="altBtns">
                                        <button className="bg6" onClick={() => { this.userConfirmation(0) }}>{t('lblYes')}</button>
                                        <button className="bg3" onClick={() => { this.userConfirmation(1) }}>{t('lblNo')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overlaybetsilp" style={{ display: "block" }}></div>
                    </div>}
                </div >
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { cartItems } = state.gameReducer;
    const { serverTime } = state.commonReducer;
    return { user, cartItems, appvariables, serverTime };
}
const mapActionToProps = {
    alertError: alertActions.error,
    setPaymentDetails: gameActions.setPaymentDetails,
    setPayErrorStatus: gameActions.setPayErrorStatus,
    Buybet: gameActions.Buybet,
    getBalance: loginActions.getBalance,
    loading: alertActions.loading,
    Buybet_OnlinePay: gameActions.Buybet_OnlinePay,
    RemoveClosedDrawbetFromCart: gameActions.RemoveClosedDrawbetFromCart,
    RemoveFromCart: gameActions.RemoveFromCart,
    BackFromCart: gameActions.BackFromCart
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Payment))