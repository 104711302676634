import React, { Component } from "react";
import Header from "../../Header/Header";
import Previousresult from "./Previousresult";
import PreviousDrawShows from "./previousdrawshow";
import { withTranslation } from 'react-i18next';
import Ourwinners from "./Ourwinners";
import Weeklylive from "./Weeklylive";
import PastDrawVideo from "./WinnersStory";

class Mydraws extends Component {
    constructor(props) {
        super(props);
        const tabid = localStorage.getItem('drawtabId') ? localStorage.getItem('drawtabId') : 1;
        if (tabid == null || tabid == "")
            this.state = {
                setTabId: 1, drawType: (props.drawtype == '12' ? '9' : props.drawtype),
                _dbdrawType: props.drawtype
            }
        else
            this.state = {
                setTabId: tabid, drawType: (props.drawtype == '12' ? '9' : props.drawtype),
                _dbdrawType: props.drawtype
            }
    }
    changeMenu = (tabId) => {
        this.setState({ setTabId: tabId });
    }



    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <div className="wrap2">
                    <h2 className="headding3 pastDrawHead font-[portilla]">{t('lblPastDrawRes')}</h2>
                    <div className="profile">
                        <ul className={(this.state.drawType == 9) ? "gameTabs fortuneTabs" : this.state.drawType == 10 ? "gameTabs superTabs" :this.state.drawType == 13 ? "gameTabs luckyTabs" : this.state.drawType == 14 ? "gameTabs dreamTabs" : "gameTabs magicTabs"}>
                            <li className={(this.state.setTabId == 1) ? "drwTabItem1 tabActive" : "drwTabItem1"}><a className="proLiPos7" onClick={() => this.changeMenu(1)}>{t('lblPastDrawRes')}</a></li>
                            <li className={(this.state.setTabId == 2) ? "drwTabItem2 tabActive" : "drwTabItem2"}><a className="proLiPos8" onClick={() => this.changeMenu(2)}>{t('lblPastDrawShow')}</a></li>
                            <li className={(this.state.setTabId == 3) ? "drwTabItem3 tabActive" : "drwTabItem3"}><a className="proLiPos9" onClick={() => this.changeMenu(3)}>{t('lblOurWinner')}</a></li>
                            {this.state._dbdrawType != 12 && <li className={(this.state.setTabId == 4) ? "drwTabItem4 tabActive" : "drwTabItem4"}><a className="proLiPos10" onClick={() => this.changeMenu(4)}>{t('lblWeeklyLive')}</a></li>}
                            {/* {this.state._dbdrawType != 12 && <li className={(this.state.setTabId == 5) ? "drwTabItem4 tabActive" : "drwTabItem4"}><a className="proLiPos10" onClick={() => this.changeMenu(5)}>{t('lblPastDrawVideo')}</a></li>} */}
                        </ul>
                        <select name="draw-results-menu" className="pUserMenuMobile">
                            <option value="past_results" onClick={() => this.changeMenu(1)}>{t('lblPastDrawRes')}</option>
                            <option value="past_shows" onClick={() => this.changeMenu(2)}>{t('lblPastDrawShow')}</option>
                            <option value="winners" onClick={() => this.changeMenu(3)}>{t('lblOurWinner')}</option>
                            <option value="live_stream" onClick={() => this.changeMenu(4)}>{t('lblWeeklyLive')}</option>
                        </select>
                        {this.state.setTabId == 1 && <Previousresult drawtype={this.state.drawType} send_db_id={this.state._dbdrawType} />}
                        {this.state.setTabId == 2 && <PreviousDrawShows drawtype={this.state.drawType} send_db_id={this.state._dbdrawType} />}
                        {this.state.setTabId == 3 && <Ourwinners drawtype={this.state.drawType} send_db_id={this.state._dbdrawType} />}
                        {this.state.setTabId == 4 && <Weeklylive drawtype={this.state.drawType} send_db_id={this.state._dbdrawType} />}
                        {/* {this.state.setTabId == 5 && <PastDrawVideo drawtype={this.state.drawType} send_db_id={this.state._dbdrawType} />} */}
                    </div>
                </div>
            </>
        );
    }
}

export default (withTranslation()(Mydraws));