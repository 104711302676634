import { appConstants } from '../helpers/actionType';
import { gameService } from '../services/gameService';
import { history } from '../helpers/history';
import { commonClass } from "../helpers/commonClass";
import { alertActions } from './AlertAction';
import { loginActions } from './loginAction';

export const gameActions = {
    GameFamily,
    SetGameFamily,
    GameAvailableGames,
    ClearAvailableGames,
    GetServerTime,
    SetServerTime,
    GetLatestResult,
    closeBetSlip,

    AddToCart,
    SetCurrentDraw,
    RemoveFromCart,
    ClearCart,
    RemoveClosedDrawbetFromCart,
    Buybet,
    UpdateStakeAll,
    GetCurrentResult,
    UpdateCartAll,
    GetSlotGames,
    RePrintBetDel,
    GetGames,
    GetOddsConfig,
    setPaymentDetails,
    SaveMyFavNumbers,
    GetMyFavNumbers,
    GetPreviousResult,
    GetReBetNumbers,
    BackFromCart,
    GetfavNo,
    setPayErrorStatus,
    DeletefavNo,
    GetRaffleResults,
    ClearGetRaffleResults,
    Buybet_OnlinePay,
    UserContact,
    SendUserratings,
    GetUserratings
}

function GameFamily(Gameid, mobno, lang, loginKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            localStorage.removeItem('cFamily');
            let data = { "type_id": Gameid };
            gameService.GetGameFamily(data, mobno, lang, loginKey)
                .then(
                    games => {
                        let gamefamily = [];
                        if (games && games.result && games.result.Status_id != 0 && games.result.Status_id != 5) {
                            localStorage.setItem('availgames', JSON.stringify(games.result));
                            // game loading performance changes.
                            // games.result.forEach(obj => { 
                            //     let _ballInfo = obj.no_of_balls.split('~');//startball~endball~selectball
                            //     let _stakeInfo = obj.min_max_multi.split('~');//minstake~maxskate~multistake~minTotStake~maxTotStake
                            //     gamefamily.push(
                            //         {
                            //             id: obj.group_id,
                            //             name: obj.group_name,
                            //             sball: parseInt(_ballInfo[0]),
                            //             ball: parseInt(_ballInfo[1]),
                            //             minSelect: parseInt(_ballInfo[2]),
                            //             maxSelect: parseInt(_ballInfo[2]),
                            //             minStakePerTrans: _stakeInfo[3],
                            //             maxStakePerTrans: _stakeInfo[4],
                            //         }
                            //     );
                            // })
                        }
                        else {
                            localStorage.setItem('availgames', JSON.stringify([]));
                        }
                        if (games && games.result && games.result.Status_id == 5) {
                            loginActions.logout();
                            // dispatch(alertActions.error(games.result.Message));
                            localStorage.setItem('alertmsg', games.result.Message);
                        }
                        localStorage.setItem('allFamily', JSON.stringify(gamefamily));
                        dispatch({ type: appConstants.GET_GAMEFAMILY_SUCCESS, gamefamily });
                        dispatch({ type: appConstants.LOADED });
                    },
                    error => {
                        // Api Not Available it show game not available
                        // localStorage.setItem('availgames', JSON.stringify([]));
                        // localStorage.setItem('allFamily', JSON.stringify([]));
                        error = error.message;
                        dispatch({ type: appConstants.GET_GAMEFAMILY_ERROR, error });
                        dispatch({ type: appConstants.LOADED });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

//if new games ,add here and gameReducer file
function SetGameFamily(gFamilyID, langID) {
    try {
        let allFamily = JSON.parse(localStorage.getItem('allFamily'));
        allFamily = allFamily ? allFamily : [{ id: gFamilyID }];
        let gFamily = allFamily.filter(a => a.id == gFamilyID)[0];
        return dispatch => {
            localStorage.setItem('cFamily', JSON.stringify(gFamily));
            dispatch({ type: appConstants.SET_GAMEFAMILY, gFamily });
            if (gFamilyID == 41)//gFamily.id
                history.push('/quickwin');
            if (gFamilyID == 42)
                history.push('/quick9');
            if (gFamilyID == 48)
                history.push('/sicbo');
            if (gFamilyID == 59)
                history.push('/panta');
            if (gFamilyID == 40)
                history.push('/roulette');
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};


function ClearAvailableGames() {
    return dispatch => {
        let error = [];
        dispatch({ type: appConstants.GET_AVAILDRAWS_ERROR, error });
    };
}


function GameAvailableGames(mobileNo, familyId, langID, serverTime, sessionKey) {
    //let serverTime=15455;
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //by thamil for fix the session share diff browser tabs with diff games on 24/02/2023
            //localStorage.removeItem('cDraw');
            let games = JSON.parse(localStorage.getItem('availgames'));
            //games[0].draw_time = "11:28:30"
            //games[0].end_time = "11:28:30"
            // games[0].draw_date = "12/10/2024"
            //games[3].draw_time="21:52:00"
            //games[3].end_time="21:52:00"
            //games[3].draw_date='21/09/2024'
            //  games[4].draw_time="05:45:00"
            //  games[4].end_time="05:45:00"    
            // games[4].draw_date='19/09/2024'  
            // games.pop()

            if (games == undefined || games == null || serverTime == -1) {
                dispatch({ type: appConstants.LOADED });
                return
            }
            if (familyId > 0) {
                let fgames = games.filter(a => a.group_id == familyId);
                dispatch({ type: appConstants.LOADED });
                if (fgames && fgames.length <= 0) {
                    let cDraw = {}
                    dispatch({ type: appConstants.SET_DRAW, cDraw });
                    // game loading performance changes.
                    /* let currentAvailableDraws = [];
                    dispatch({ type: appConstants.GET_AVAILDRAWS_SUCCESS, currentAvailableDraws }); */
                    return;
                }
                let currentAvailableDraws = [];
                //for multi draw
                //fgames = fgames[0];       
                fgames.forEach(fgames => {
                    let _gameid = parseInt(fgames.reflot_id);

                    let [s = 0, m = 0, h = 0] = fgames.end_time.split(':').reverse();
                    let _endTime = (+h) * 60 + (+m) * 1;//+ (+s); 

                    let [s1 = 0, m1 = 0, h1 = 0] = fgames.draw_time.split(':').reverse();
                    let _startTime = (+h1) * 60 + (+m1) * 1;//+ (+s1); 

                    //let _ballInfo = fgames.NoOfballs.split('~');
                    let _stakeInfo = fgames.min_max_multi.split('~');
                    fgames.interval = parseInt(fgames.interval);
                    for (let _drawTime = _startTime; _drawTime <= _endTime;) {
                        var num = _drawTime;
                        var hours = (num / 60);
                        var rhours = Math.floor(hours);
                        var minutes = (hours - rhours) * 60;
                        var rminutes = Math.round(minutes);
                        let newdrawTime = ("0" + rhours).slice(-2) + ":" + ("0" + rminutes).slice(-2) + ":00";

                        let showdrawTime = "";
                        if (hours <= 12) {
                            showdrawTime = ("0" + rhours).slice(-2) + ":" + ("0" + rminutes).slice(-2) + (hours < 12 ? " AM" : " PM");
                        }
                        else {
                            showdrawTime = ("0" + (rhours - 12)).slice(-2) + ":" + ("0" + rminutes).slice(-2) + " PM";
                        }

                        var gametime = fgames.draw_date + " " + newdrawTime;
                        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                        let ss = new Date(gametime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                        let gametimestamp = (ss.getTime());
                        let dd = gametimestamp - serverTime;
                        var ldrawhours = Math.floor(dd / (60 * 60 * 1000));
                        var countdownFlag = (ldrawhours > 24) ? 0 : 1;

                        // sale closeTime implemented
                        let _close_time = fgames.close_time ? (parseInt(fgames.close_time) * 1000) : 0;
                        let SaleCloseTime = gametimestamp - _close_time;
                        let SaleStatusRemaining = SaleCloseTime - serverTime;

                        if (dd > 0) {
                            currentAvailableDraws.push(
                                {
                                    DrawDateTime: fgames.draw_date + " " + newdrawTime,
                                    DrawNo: _gameid,
                                    DrawDate: fgames.draw_date,
                                    DrawTime: newdrawTime,
                                    DrawShowTime: showdrawTime,
                                    RateInPs: fgames.odds,
                                    GameId: _gameid,
                                    minStake: parseFloat(_stakeInfo[0]),
                                    maxStake: parseFloat(_stakeInfo[1]),
                                    StakeMulti: parseFloat(_stakeInfo[2]),
                                    LotName: fgames.game_name,
                                    DrawID: _gameid,
                                    no_of_balls: fgames.no_of_balls,
                                    gamegroupID: fgames.group_id,
                                    DisplayDateTime: fgames.display_draw_date,
                                    CountdownFlag: countdownFlag,
                                    SaleCloseTimeMSec: _close_time,
                                    SaleStatus: (SaleStatusRemaining >= 0 ? 0 : 1),
                                    OddsVal: (fgames.group_id == 13 ? 1 : 0)
                                }
                            );
                        }
                        _gameid++;
                        _drawTime += (fgames.interval);
                        // MultiDraw Checking
                        // if (fgames.interval == 0) { break; }
                        break;
                    }
                });
                dispatch({ type: appConstants.LOADED });
                // game loading performance changes.
                // dispatch({ type: appConstants.GET_AVAILDRAWS_SUCCESS, currentAvailableDraws });
                if (currentAvailableDraws.length > 0) {
                    //for multi draw
                    //let cDraw = currentAvailableDraws[0];
                    //let cDraw = currentAvailableDraws.filter(a => ((a.SaleStatus == 0 && familyId == 13) || familyId != 13))[0];
                    let cDraw = currentAvailableDraws.sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1)[0];
                    dispatch({ type: appConstants.SET_DRAW, cDraw });
                }
                else {
                    let cDraw = {};
                    dispatch({ type: appConstants.SET_DRAW, cDraw });
                }
            }
            else {
                let fgamesAll = games.filter(a => a.group_id == familyId || familyId == 0);
                dispatch({ type: appConstants.LOADED });
                if (fgamesAll && fgamesAll.length <= 0) {
                    let cDraw = {};
                    dispatch({ type: appConstants.SET_DRAW, cDraw });
                    let currentAvailableDraws = [];
                    dispatch({ type: appConstants.GET_AVAILDRAWS_SUCCESS, currentAvailableDraws });
                    return;
                }
                let currentAvailableDraws = [];
                let j = 0;
                fgamesAll.forEach(fgames => {
                    //fgames = fgames[0];              
                    let _gameid = parseInt(fgames.reflot_id);

                    let [s = 0, m = 0, h = 0] = fgames.end_time.split(':').reverse();
                    let _endTime = (+h) * 60 + (+m) * 1;//+ (+s); 

                    let [s1 = 0, m1 = 0, h1 = 0] = fgames.draw_time.split(':').reverse();
                    let _startTime = (+h1) * 60 + (+m1) * 1;//+ (+s1); 

                    let _stakeInfo = fgames.min_max_multi.split('~');
                    fgames.interval = parseInt(fgames.interval);
                    for (let _drawTime = _startTime; _drawTime <= _endTime;) {
                        var num = _drawTime;
                        var hours = (num / 60);
                        var rhours = Math.floor(hours);
                        var minutes = (hours - rhours) * 60;
                        var rminutes = Math.round(minutes);
                        let newdrawTime = ("0" + rhours).slice(-2) + ":" + ("0" + rminutes).slice(-2) + ":00";
                        let showdrawTime = "";
                        if (hours <= 12) {
                            showdrawTime = ("0" + rhours).slice(-2) + ":" + ("0" + rminutes).slice(-2) + (hours < 12 ? " AM" : " PM");
                        }
                        else {
                            showdrawTime = ("0" + (rhours - 12)).slice(-2) + ":" + ("0" + rminutes).slice(-2) + " PM";
                        }
                        var gametime = fgames.draw_date + " " + newdrawTime;
                        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                        let ss = new Date(gametime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                        let gametimestamp = (ss.getTime());
                        let dd = gametimestamp - serverTime;
                        var ldrawhours = Math.floor(dd / (60 * 60 * 1000));
                        var countdownFlag = (ldrawhours > 24) ? 0 : 1;
                        let tarr = [];
                        for (let i = 1; i <= fgames.no_of_balls.split('~')[2]; i++) {
                            tarr.push('?');
                        }
                        j++;
                        // sale closeTime implemented
                        let _close_time = fgames.close_time ? (parseInt(fgames.close_time) * 1000) : 0;
                        let SaleCloseTime = gametimestamp - _close_time;
                        let SaleStatusRemaining = SaleCloseTime - serverTime;
                        if (dd > 0) {
                            currentAvailableDraws.push(
                                {
                                    DrawDateTime: fgames.draw_date + " " + newdrawTime,
                                    DrawNo: _gameid,
                                    DrawDate: fgames.draw_date,
                                    DrawTime: newdrawTime,
                                    DrawShowTime: showdrawTime,
                                    RateInPs: fgames.odds,
                                    GameId: _gameid,
                                    minStake: parseFloat(_stakeInfo[0]),
                                    maxStake: parseFloat(_stakeInfo[1]),
                                    StakeMulti: parseFloat(_stakeInfo[2]),
                                    LotName: fgames.game_name,
                                    DrawID: _gameid,
                                    no_of_balls: fgames.no_of_balls,
                                    gamegroupID: fgames.group_id,
                                    BetSelection: tarr,
                                    stakeValue: parseFloat(_stakeInfo[0]),
                                    autoApply: 1,
                                    betComplete: false,
                                    DisplayDateTime: fgames.display_draw_date,
                                    CountdownFlag: countdownFlag,
                                    SaleCloseTimeMSec: _close_time,
                                    SaleStatus: (SaleStatusRemaining > 0 ? 0 : 1),
                                    OddsVal: (fgames.group_id == 13 ? 1 : 0)
                                }
                            );
                        }
                        _gameid++;
                        _drawTime += (fgames.interval);
                        break;
                    }
                });
                dispatch({ type: appConstants.LOADED });
                dispatch({ type: appConstants.GET_AVAILDRAWS_SUCCESS, currentAvailableDraws });
            }

        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetServerTime(mobileNo) {
    try {
        return dispatch => {
            gameService.GetServerTime(mobileNo)
                .then(
                    _date => {
                        if (_date && _date.Status_id == 0) {
                            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                            let ss = new Date(_date.Date_time.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                            let serverTime = (ss.getTime());
                            console.log("ServerTime form db : " + serverTime);
                            dispatch({ type: appConstants.GET_SERVERTIME_SUCCESS, serverTime });
                        }
                        else {
                            let error = _date && _date.message && _date.message;
                            dispatch({ type: appConstants.GET_SERVERTIME_ERROR, error });
                        }
                    },
                    error => {
                        error = error && error.Message && error.Message;
                        dispatch({ type: appConstants.GET_SERVERTIME_ERROR, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function SetServerTime(serverTime) {
    localStorage.setItem('sTime', serverTime);
    return dispatch => {
        dispatch({ type: appConstants.GET_SERVERTIME_SUCCESS, serverTime });
    }
};

function GetLatestResult(gameID, mobno, langID, loginID) {
    try {
        if (gameID == undefined) { return; }
        return dispatch => {
            //no need ,bcoz it will distrub while buy next draw.
            //dispatch({ type: appConstants.LOADING });
            let data = { "game_group_id": parseInt(gameID) };
            gameService.GetLatestResult(data, mobno, langID, loginID)
                .then(
                    lResult => {
                        if (lResult && lResult.result && lResult.result.Status_id == 1) {
                            lResult = [];
                            dispatch({ type: appConstants.GET_LATEST_RESULT_SUCCESS, lResult });
                        } else {
                            // dispatch({ type: appConstants.LOADED});
                            lResult = lResult.result;
                            dispatch({ type: appConstants.GET_LATEST_RESULT_SUCCESS, lResult });
                        }
                    },
                    error => {
                        // dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch({ type: appConstants.GET_LATEST_RESULT_ERROR, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function closeBetSlip() {
    let lstrval = null;
    return dispatch => { dispatch({ type: appConstants.GET_REPRINT_DATA, lstrval }); }
};

function GetSlotGames(game_type_id, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "game_type_id": game_type_id };
            gameService.GetSlotGames(data, mobileNo, langID, sessionKey)
                .then(
                    SlotGame => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_SLOT_GAMES, SlotGame });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = [];
                        dispatch({ type: appConstants.GET_SLOT_GAMES, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function AddToCart(cItem) {
    //as per sachin .the "add_ro_cart" fire only one times
    let mycart = JSON.parse(localStorage.getItem('mycart'));
    if (mycart == null || mycart == undefined || mycart.length == 0) {
        commonClass.SendPageDataLayer(7);
    }
    return dispatch => {
        dispatch({ type: appConstants.ADD_CARTITEM, cItem });
    }
};

function UpdateCartAll(Items) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_CARTITEM, Items });
    }
};

function RemoveFromCart(cid, gameID) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CARTITEM, cid, gameID });
    }
};

function RemoveClosedDrawbetFromCart(did) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CLOSEDDRAW_CARTITEM, did });
    }
};

function ClearCart() {
    return dispatch => {
        dispatch({ type: appConstants.CLEAR_CARTITEM });
    }
};

function SetCurrentDraw(cDraw) {
    return dispatch => {
        dispatch({ type: appConstants.SET_DRAW, cDraw });
    }
};

function UpdateStakeAll(stake) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_STAKE_ALL, stake });
    }
};

function GetCurrentResult(gameDate, gameID, langID, loginID) {
    let mobno = 0;
    return dispatch => {
        let data = { "game_group_id": parseInt(gameID), "draw_time": gameDate };
        gameService.GetCurrentResult(data, mobno, langID, loginID)
            .then(
                cResult => {
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                },
                error => {
                    let cResult = undefined;
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                }
            );
    }
};

function Buybet(buyStr, mobileNo, langID, loginID, ggourArr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = buyStr;
            //commonClass.SendOtherEvent(3, 0, data.group_id);
            gameService.LotteryOnlineSale(data, mobileNo, langID, loginID)
                .then(
                    buyInfo => {
                        let myBet = generateBetSlip(buyInfo);
                        localStorage.setItem('sendEventFlog', 1);
                        if (myBet.status == 0) {
                            //this calll in payment status page
                            // commonClass.SendPageDataLayer(12);
                            //commonClass.SendOtherEvent(3, 1,{...myBet,mobileNo:mobileNo});
                            myBet = { ...myBet, mobileNo: mobileNo, ggourArr: ggourArr };
                            let uObj = {}
                            // commonClass.SendUserTracking(1, uObj);
                            // used in Paymentstatus Page
                            // commonClass.SendEventTracking(15, uObj, false, myBet.betLst)
                        }
                        else {
                            //this calll in payment status page
                            // commonClass.SendPageDataLayer(13);
                            ////commonClass.SendOtherEvent(3, 2);
                            let uObj = {
                                'REASON': myBet.msg
                            }
                            // commonClass.SendUserTracking(2, uObj);
                            // used in Paymentstatus Page
                            // commonClass.SendEventTracking(19, uObj);
                        }
                        // dispatch({ type: appConstants.LOADED });
                        if (myBet.status == 5) {//5-for loginID Mismatch                                                         
                            //localStorage.setItem('alertmsg', myBet.msg);
                            setTimeout(() => {
                                loginActions.logout(myBet.status);
                            }, 1000);
                            dispatch(alertActions.error(myBet.msg));
                        }
                        else if (myBet.status != 3) { //3-for balance error.
                            dispatch({ type: appConstants.CLEAR_CARTITEM });
                        }
                        // if (myBet.status == 1) {
                        //     dispatch(alertActions.error(myBet.msg));
                        // }
                        // else if (myBet.status == 3) {
                        //     dispatch(alertActions.error(myBet.msg));
                        //     setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                        // }
                        // else if (myBet.status == 4) {
                        //     dispatch(alertActions.error(myBet.msg));
                        //     setTimeout(() => { history.push('/login'); }, 2000);
                        // }
                        // else {
                        //     dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        // }

                        if (myBet.balance) {
                            let balance = myBet.balance;
                            let winbalance = myBet.winbalance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        }
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        setTimeout(() => { history.push('/paymentstatus'); }, 500);
                    },
                    error => {
                        //this calll in payment status page
                        // commonClass.SendPageDataLayer(13);
                        ////commonClass.SendOtherEvent(3, 2);
                        let myBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        history.push('/paymentstatus');
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function setPayErrorStatus(myBet) {
    return dispatch => {
        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
        history.push('/paymentstatus');
    }
}

function generateBetSlip(mybet) {
    try {
        mybet = mybet.result;
        if (mybet.Status_id != 0) {
            return { status: mybet.Status_id, msg: mybet.Message };
        }
        let lbuyStr = mybet.print_info.split('|');
        let tempStr2 = [];
        let tempStr = [];
        for (let i = 0; i <= lbuyStr.length - 1; i++) {
            if (lbuyStr[i] != "") {

                //let tempStr = mybet.print_info.split('~');
                tempStr = lbuyStr[i].split('~');
                let tempStr1 = tempStr[5].split('^');
                //let tempStr2 = [];
                tempStr1.forEach(ele => {
                    if (ele != "") {
                        let _betValue = ele.split('#');
                        if (_betValue.length > 0) {
                            let _gamegroupID = tempStr[2].toLowerCase().indexOf('fortune') >= 0 ? 9 :
                                tempStr[2].toLowerCase().indexOf('super') >= 0 ? 10 :
                                    tempStr[2].toLowerCase().indexOf('magic') >= 0 ? 11 :
                                        tempStr[2].toLowerCase().indexOf('lucky') >= 0 ? 13 : 14;
                            tempStr2.push({
                                Id: tempStr[0],
                                BallNo: _betValue[0],
                                OddsValue: _betValue[1],
                                StakeValue: parseFloat(_betValue[2]),
                                SubTotal: parseFloat(_betValue[3]),
                                GameTime: tempStr[3] + " " + tempStr[4],
                                GameID: parseInt(tempStr[1]),
                                TransDateTime: tempStr[11], //mybet.date_time,
                                GameName: tempStr[2],
                                RaffleID: _betValue.length > 4 ? _betValue[4] : "",
                                PrizeAmount: _betValue[5],
                                gamegroupID: _gamegroupID
                            });
                        }
                    }
                });
            }
        }
        let betObj = {
            betLst: tempStr2,
            status: mybet.Status_id,
            msg: mybet.Message,
            balance: mybet.Balance,
            winbalance: mybet.Win_Balance,
            familyID: 5,
            buttomMsg: tempStr[10],
            ticketStatus: mybet.ticket_status,
            winamt: mybet.win_amount,
            payment_mode: tempStr.length > 11 ? tempStr[12] : ""
        }
        return betObj;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function RePrintBetDel(transactionID, mobileNo, langID, loginKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING })
            let data = { "transaction_id": transactionID, "type_id": 1 };
            gameService.GetRePrintDel(data, mobileNo, langID, loginKey)
                .then(
                    RePrintData => {
                        let myReBet = generateBetSlip(RePrintData);
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_REPRINT_DATA, myReBet });
                    },
                    error => {
                        let myReBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_REPRINT_DATA, myReBet });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}
function GetGames(game_type_id, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            let data = { "game_group_id": parseInt(game_type_id) };
            gameService.GetPopularGames(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let PopularGames = data.result;
                        dispatch({ type: appConstants.GET_POPUPLAR_GAMES, PopularGames });
                    },
                    error => {
                        let PopularGames = error.message;
                        dispatch({ type: appConstants.GET_POPUPLAR_GAMES, PopularGames })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }

}

function GetOddsConfig(gameID, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            let data = { "game_group_id": parseInt(gameID) };
            gameService.GetConfigOdds(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let OddsConfiglst = data.result;
                        dispatch({ type: appConstants.GET_ODDS_CONFIGLIST, OddsConfiglst });
                    },
                    error => {
                        let OddsConfiglst = error.message;
                        dispatch({ type: appConstants.GET_ODDS_CONFIGLIST, OddsConfiglst })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function setPaymentDetails(payid) {
    try {
        return dispatch => {
            localStorage.setItem("pay_id", payid)
            dispatch({ type: appConstants.ORDER_PAYTYPE_ID, payid });
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function SaveMyFavNumbers(favID, game_group_id, favnos, mobileNo, langID, sessionKey) {
    try {

        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "favorite_id": parseInt(favID), "game_group_id": parseInt(game_group_id), "numbers": favnos };
            //let data = { "favorite_id":  favID,  "numbers":  favnos };
            gameService.SaveMyFavNumbers(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        if (data.result.Status_id == 0) {
                            dispatch(alertActions.success(data.result.Message));
                            GetMyFavNumbers(game_group_id, mobileNo, langID, sessionKey);
                        }
                        else {
                            dispatch(alertActions.error(data.result.Message));
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.error(error.message));
                        localStorage.setItem('alertmsg', error.message);
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }

}

function GetMyFavNumbers(game_group_id, mobileNo, langID, sessionKey) {
    try {
        if (!mobileNo || mobileNo == 0) {
            return dispatch => { };
        }
        return dispatch => {
            let data = { "game_group_id": parseInt(game_group_id) };
            gameService.GetMyFavNumbers(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        if (data.result.Status_id) {
                            if (data.result.Status_id != '5') {
                                let favno = [];
                                dispatch({ type: appConstants.My_FAV_NUMBERS, favno });
                            }
                            else {
                                loginActions.logout();
                                localStorage.setItem('alertmsg', data.result.Message);
                            }
                        }
                        else {
                            let favno = data.result;
                            dispatch({ type: appConstants.My_FAV_NUMBERS, favno });
                        }
                        // if (data.result.Status_id != '1') {
                        //     let favno = data.result;
                        //     dispatch({ type: appConstants.My_FAV_NUMBERS, favno });
                        // }
                    },
                    error => {
                        let favno = [];
                        dispatch({ type: appConstants.My_FAV_NUMBERS, favno })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }

}

function GetPreviousResult(gameID, from_date, to_date, mobno, langID, loginID, SeparateCom) {
    try {
        if (gameID == undefined) { return; }
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "game_group_id": parseInt(gameID), "from_date": from_date, "to_date": to_date, "flag": 1 };
            gameService.GetPreviousResult(data, mobno, langID, loginID)
                .then(
                    PrevResult => {
                        dispatch({ type: appConstants.LOADED });
                        if (SeparateCom == 0) {
                            if (PrevResult.result.Status_id == 1) {
                                PrevResult = [];
                                dispatch({ type: appConstants.GET_PREVIOUS_RESULT_SUCCESS, PrevResult });
                            } else {
                                PrevResult = PrevResult.result;
                                dispatch({ type: appConstants.GET_PREVIOUS_RESULT_SUCCESS, PrevResult });
                            }
                        }
                        else {
                            if (PrevResult.result.Status_id == 1) {
                                let AlterPrevResult = [];
                                dispatch({ type: appConstants.ANOTHER_GET_PREVIOUS_RESULT_SUCCESS, AlterPrevResult });
                            } else {
                                let AlterPrevResult = PrevResult.result;
                                dispatch({ type: appConstants.ANOTHER_GET_PREVIOUS_RESULT_SUCCESS, AlterPrevResult });
                            }
                        }
                    },
                    error => {
                        if (SeparateCom == 0) {
                            dispatch({ type: appConstants.GET_PREVIOUS_RESULT_ERROR, error });
                        } else {
                            dispatch({ type: appConstants.ANOTHER_GET_PREVIOUS_RESULT_ERROR, error });
                        }
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetReBetNumbers(game_group_id, tarnsID, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "transaction_id": tarnsID };
            gameService.GetReBetNumbers(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        if (data.result.Status_id != '1') {
                            let favno = data.result.map(a => a.info).join('|');
                            favno = game_group_id + '~' + favno;
                            localStorage.setItem('rebetno', favno);
                            if (game_group_id == '9') {
                                history.push('/fortune4')
                            }
                            else if (game_group_id == '11') {
                                history.push('/magic5')
                            }
                            else if (game_group_id == '10') {
                                history.push('/super6')
                            }
                            else if (game_group_id == '14') {
                                history.push('/dream5')
                            }
                            else {
                                history.push('/lucky1')
                            }
                        }
                        else {
                            dispatch(alertActions.error(data.result.Message));
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.error(error.message));
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }

}
function BackFromCart(prev_cartItem, game_group_id) {
    return dispatch => {
        localStorage.setItem('prevCartItem', JSON.stringify(prev_cartItem));
        if (game_group_id == '9') {
            history.push('/fortune4')
        }
        else if (game_group_id == '10') {
            history.push('/super6')
        }
        else if (game_group_id == '11') {
            history.push('/magic5')
        }
        else if (game_group_id == '14') {
            history.push('/dream5')
        }
        else {
            history.push('/lucky1')
        }
    }
}

function GetfavNo(game_group_id, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            let data = { "game_group_id": parseInt(game_group_id) };
            gameService.GetFavNo(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        // if (data.result.Status_id != '1' || data.result.Status_Id != '1') {
                        if (data.result.Status_id) {
                            if (data.result.Status_id != '5') {
                                let FavNo = [];
                                dispatch({ type: appConstants.GET_FAV_NUMBERS, FavNo });
                            }
                            else {
                                loginActions.logout();
                                localStorage.setItem('alertmsg', data.result.Message);
                            }
                        }
                        else {
                            let FavNo = data.result;
                            dispatch({ type: appConstants.GET_FAV_NUMBERS, FavNo });
                        }
                    },
                    error => {
                        let FavNo = [];
                        dispatch({ type: appConstants.GET_FAV_NUMBERS, FavNo })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function DeletefavNo(fav_id, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            let data = { "fav_id": parseInt(fav_id) };
            gameService.DeleteFavNo(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let dFavNo = data.result;
                        dispatch({ type: appConstants.DELETE_FAV_NUMBERS, dFavNo });
                        if (data.result.Status_id != '1') {
                            dispatch(alertActions.success(dFavNo.Message));
                        }
                        else {

                            dispatch(alertActions.error(dFavNo.Message));
                        }
                    },
                    error => {
                        let dFavNo = undefined;
                        dispatch({ type: appConstants.DELETE_FAV_NUMBERS, dFavNo });
                        dispatch(alertActions.error(error.message));
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}


function GetRaffleResults(game_group_id, drawid, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "game_group_id": parseInt(game_group_id), "draw_id": parseInt(drawid) };
            gameService.GetRaffleResults(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        let RResult = [];
                        let pricebreakdown = null;
                        if (data.result.Status_id != 1) {
                            let SplitTiledObj = data.result.raffle_result.split('|');
                            let SplitTiledbrdown = data.result.scheme.split('|');
                            let AllObjData = data.result;
                            for (var i = 0; i <= SplitTiledObj.length - 1; i++) {
                                let SplitProperty = SplitTiledObj[i].split('~');
                                if (SplitProperty.length < 2) {
                                    continue;
                                }
                                else {
                                    let formObj = { "raffleid": SplitProperty[0], "winamount": SplitProperty[1], "draw_date": AllObjData.draw_date, "total_win_amount": AllObjData.total_win_amount, "total_win_count": AllObjData.total_win_count, "win_no": AllObjData.win_no, "Player_name": SplitProperty[2], "Player_name": SplitProperty[2], "Player_age": SplitProperty[3], "Player_city": SplitProperty[4], "draws_type": SplitProperty[5] }
                                    RResult.push(formObj)
                                }
                            }
                            if (drawid != 0) {
                                pricebreakdown = [];
                                for (var i = 0; i <= SplitTiledbrdown.length - 1; i++) {
                                    let SplitProperty = SplitTiledbrdown[i].split('~');
                                    if (SplitProperty.length < 4) {
                                        continue;
                                    }
                                    else {
                                        let formObj = { "max_ball": SplitProperty[0], "matchingball": SplitProperty[1], "winamt": SplitProperty[2], "addstar": SplitProperty[3] }
                                        pricebreakdown.push(formObj)
                                    }
                                }
                            }
                        }
                        // if (drawid != 0) { //for show the latest raffile draw winner only
                        //     pricebreakdown = JSON.parse(localStorage.getItem('prresult'));
                        // }
                        let RaffleresultObj = { breakdownlist: pricebreakdown, RaffleResultLst: RResult };
                        dispatch({ type: appConstants.GET_RAFFLE_RESULTS, RaffleresultObj });
                    },
                    error => {
                        let pricebreakdown = null;
                        let RResult = [];
                        let RaffleresultObj = { breakdownlist: pricebreakdown, RaffleResultLst: RResult };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_RAFFLE_RESULTS, RaffleresultObj })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function ClearGetRaffleResults() {
    return dispatch => {
        let RResult = undefined;
        dispatch({ type: appConstants.GET_RAFFLE_RESULTS, RResult });
    }
};

function Buybet_OnlinePay(buyInfo, mobileNo, ggourArr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            const buyInfoArr = buyInfo.split('*')
            const buySubArr1 = buyInfoArr[0].split("~");
            const buySubArr2 = buyInfoArr[1].split("~");

            let _buy = { result: { Status_id: buySubArr1[0], Message: buySubArr1[1] } };
            if (buySubArr1[0] == "0") {
                _buy.result.Balance = buySubArr2[0];
                _buy.result.Win_Balance = buySubArr2[1];
                _buy.result.print_info = buyInfoArr[2];
            }
            let myBet = generateBetSlip(_buy);
            localStorage.setItem('sendEventFlog', 1);
            if (myBet.status == 0) {
                myBet = { ...myBet, mobileNo: mobileNo, ggourArr: ggourArr };
                let uObj = {}
                // commonClass.SendUserTracking(1, uObj);
                // used in Paymentstatus Page
                // commonClass.SendEventTracking(15, uObj, false, myBet.betLst)
            }
            else {
                let uObj = {
                    'REASON': myBet.msg
                }
                // used in Paymentstatus Page
                // commonClass.SendEventTracking(19, uObj);
            }

            if (myBet.status == 5) {//5-for loginID Mismatch       
                setTimeout(() => {
                    loginActions.logout(myBet.status);
                }, 1000);
                dispatch(alertActions.error(myBet.msg));
            }
            else if (myBet.status != 3) { //3-for balance error.
                dispatch({ type: appConstants.CLEAR_CARTITEM });
            }

            if (myBet.balance) {
                let balance = myBet.balance;
                let winbalance = myBet.winbalance;
                dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
            }
            dispatch({ type: appConstants.BUY_SUCCESS, myBet });
            setTimeout(() => {
                dispatch({ type: appConstants.LOADED });
                history.push('/paymentstatus');
            }, 500);
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};



function UserContact(data, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            gameService.UserContact(data)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.success(data.result.Message))
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.error(error.message));
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex, "UserContact");
    }

}

function SendUserratings(data, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            gameService.SendUserratings(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.success(data.result.Message))
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch(alertActions.error(error.message));
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex, "SendUserratings");
    }

}


function GetUserratings(mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            gameService.GetUserratings(mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        let GetUser = [];
                        GetUser = data.result;
                        dispatch({ type: appConstants.GET_USER_RATINGS, GetUser });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let GetUser = [];
                        dispatch({ type: appConstants.GET_USER_RATINGS, GetUser });
                        // dispatch(alertActions.error(error.message));
                    }
                )
        }
    }
    catch (ex) {
        commonClass.writeLog(ex, "GetUserratings");
    }
}
