import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../helpers/history';
import Header from '../Header/Header';
import Barcode from 'react-barcode';
import { gameActions } from '../action/GameAction';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint from "react-to-print";
import { commonClass } from "../helpers/commonClass";
class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        //only allow logged in users
        if (!props.user || props.user.mobileNo == "0") {
            history.push('/login');
        }
        // props.ClearCart();
    }
    componentDidMount() {
        if (localStorage.getItem('sendEventFlog') == 1 && this.props.myBet) {
            localStorage.removeItem('sendEventFlog');
            localStorage.removeItem('ftimesel');
            let myBet = this.props.myBet;
            if (myBet.status == 0) {
                let uObj = {}
                commonClass.SendEventTracking(15, uObj, false, myBet.betLst);
                commonClass.SendOtherEvent(3, 1, myBet);
                commonClass.SendPageDataLayer(12);
                myBet.ggourArr && myBet.ggourArr.forEach(ggID => {
                    if (ggID == 9) {
                        commonClass.SendPageDataLayer(14);
                    }
                    if (ggID == 11) {
                        commonClass.SendPageDataLayer(16);
                    }
                    else if (ggID == 10) {
                        commonClass.SendPageDataLayer(15);
                    }
                    else if (ggID == 13) {
                        commonClass.SendPageDataLayer(18);
                    }
                })
            }
            else {
                let uObj = {
                    'REASON': myBet?.msg
                }
                commonClass.SendEventTracking(19, uObj);
                commonClass.SendPageDataLayer(13);
            }
        }
    }

    gotoHome = () => {
        history.push('/home');
    }
    // downloadPdfDocument = (downloadFileName) => {
    //     let downID = "qwinprint1";
    //     let downDivID = "qwinprint";
    //     document.getElementById(downID).style.maxHeight = "none"
    //     let input = document.getElementById(downDivID);
    //     html2canvas(input)
    //         .then((canvas) => {
    //             let imgData = canvas.toDataURL('image/png');
    //             let pdf = new jsPDF();
    //             pdf.addImage(imgData, 'JPEG', 20, 0);
    //             pdf.save(`${downloadFileName}.pdf`);
    //         })
    //     document.getElementById(downID).style.removeProperty("max-height")
    // }

    // getPrintCont = () => {
    //     let downID = "qwinprint1";
    //     let downDivID = "qwinprint";
    //     document.getElementById(downID).style.maxHeight = "none"
    //     let input = document.getElementById(downDivID).cloneNode(true);
    //     document.getElementById(downID).style.removeProperty("max-height");
    //     return input;
    // }
    downloadPdfDocument = (downloadFileName) => {
        let downID = "qwinprint1";
        let downDivID = "qwinprint";
        document.querySelectorAll("#" + downID).forEach(element => {
            element.style.maxHeight = "none";
        });
        let input = document.getElementById(downDivID);
        html2canvas(input)
            .then((canvas) => {
                let imgData = canvas.toDataURL('image/png');
                // let pdf = new jsPDF('p', 'pt', [1800, 700]);
                //pdf.addImage(imgData, 'JPEG', 30, 60);
                let pdf = new jsPDF("p", "mm", "a4");
                var width = pdf.internal.pageSize.getWidth();
                var height = (pdf.internal.pageSize.getHeight());
                pdf.addImage(imgData, 'JPEG', 3, 3, width, input.offsetHeight * 0.4); //width*0.90, height*0.50
                pdf.save(`${downloadFileName}.pdf`);
            })
        document.querySelectorAll("#" + downID).forEach(element => {
            element.style.removeProperty("max-height");
        });
    }

    getPrintCont = () => {
        let downID = "qwinprint1";
        let downDivID = "qwinprint";
        //document.getElementById(downID).style.maxHeight = "none"
        document.querySelectorAll("#" + downID).forEach(element => {
            element.style.maxHeight = "none";
        });
        let input = document.getElementById(downDivID).cloneNode(true);

        //document.getElementById(downID).style.removeProperty("max-height");
        document.querySelectorAll("#" + downID).forEach(element => {
            element.style.removeProperty("max-height");
        });
        input.style.marginTop = '30px'
        return input;
    }

    gotoTryAgain = (id) => {
        if (id == 3) {
            history.push('/payment');
        }
        else {
            this.gotoHome();
        }
    }

    render() {
        const { t } = this.props;
        let gamegroupLst = (this.props.myBet && this.props.myBet.betLst) ? [...new Set(this.props.myBet.betLst.map(item => item.GameID))] : [];
        let flst = [];
        let _transID = "";
        let _gameID = "";
        let _gameTime = "";
        function dd(Id, GameTime, GameID) {
            _transID = Id;
            _gameTime = GameTime;
            _gameID = GameID;
        }
        function tfilte(betLst) {
            flst = [...new Set(betLst.map(item => item.GameID))]
        }
        return (
            <>
                <Header />
                <div className="wrap2 payFailedHeadAlign">
                    {this.props.myBet && this.props.myBet.status != 0 && <h2 className="headding3 payFailureHead">{t('lblPaymentFailed')}</h2>}
                    {this.props.myBet && this.props.myBet.status == 0 && <h2 className="headding3 payFailureHead">{t('lblPaymentSucc')}</h2>}
                </div>
                <div className={this.props.myBet.status == 0 ? "wrap1" : "wrap2 payFailedHeadAlign"}>
                    <section className={this.props.myBet.status == 0 ? "flex" : ""}>
                        <div className={this.props.myBet.status == 0 ? "w-1/3" : "col-md-12 col-sm-12 col-xs-12"}>
                            {this.props.myBet && this.props.myBet.status == 0 ?
                                <div className="payMent">
                                    <div className="rtl:flex"><p>{t('lblCongrats')}</p></div>
                                    <div className="rtl:flex">
                                        <p><img src="./assets/img/icons/tick-g.webp" alt="Tick" />{this.props.myBet.msg} </p></div>
                                </div> :
                                <div className="payMent">
                                    <div className="rtl:flex"><p>{t('lblThankChooseGulfTicket')}</p></div>
                                    <div className="rtl:flex">
                                        <p><img src="./assets/img/icons/failed.webp" alt="Tick" />{this.props.myBet.msg}</p></div>
                                </div>
                            }
                            {this.props.myBet && this.props.myBet.status == 3 && <button className="verifyBtn bg3" onClick={() => { this.gotoTryAgain(this.props.myBet.status) }} id="tryAgain">{t('lblTryAgain')}</button>}
                            <button className={this.props.myBet.status == 0 ? "verifyBtn bg3 rtl:float-right" : "verifyBtn bg3 !ml-4"} onClick={() => { this.gotoHome() }} id="tryAgain">{t('lblBactToHome')}</button>
                        </div>
                        <div className="w-2/3" >
                            <div className="ticketFlex" id='qwinprint'>
                                <div className="ticketSeq flex-wrap">  {/* style={{ gap: 0 }} */}
                                    {this.props.myBet && this.props.myBet.status == 0 && <>
                                        {gamegroupLst && gamegroupLst.map((x1, incx) => <>
                                            {/* { incx == 1 && <div style={{padding:'2px'}}></div>} */}
                                            <div className="newTicket" key={`ac_${incx}`}>
                                                {dd(this.props.myBet.betLst.filter(a => a.GameID == x1)[0].Id, this.props.myBet.betLst.filter(a => a.GameID == x1)[0].GameTime, this.props.myBet.betLst.filter(a => a.GameID == x1)[0].GameID)}
                                                <div className="newTicketInner ticketSlipInner no-scrollbar" id='qwinprint1'>
                                                    <div className="ticketLogo"><img src="./assets/img/logo.png" alt='Gulf logo icon'/></div>
                                                    <div className="dateAndTime1 gameTicketDetails">
                                                        <h1>{t('lblTicketDet')}</h1>
                                                        <span className="time">{t('lblTransId')}: {_transID}</span>
                                                        <span className="time !pt-0">{t('lblDrawDate')}: {this.props.myBet.betLst.filter(a => a.GameID == x1)[0].GameTime}</span>
                                                    </div>
                                                    {tfilte(this.props.myBet.betLst.filter(a => a.GameID == x1))}
                                                    <>
                                                        {this.props.myBet.betLst.filter(a => a.GameID == x1).filter(a => a.GameID == x1).map((x, idx) =>
                                                            // <div className={x.GameName.toLowerCase().indexOf('super') >= 0 ? 'ticketsContainer super6Ticket' : "ticketsContainer !px-4"}>
                                                            <div className="ticketsContainer !px-4" key={`ad_${idx}`}>
                                                                <section className={`purchasedTicketContainer ticket-cut-game-${x.gamegroupID} topTicketMargin bg-game${x.gamegroupID}`}>
                                                                    <div className="ticketsAlign">
                                                                        <span className="purchaseTicketHead">{x.GameName}</span>
                                                                        <div className="ticketNumber">{t('lblTicket')} #{idx + 1}</div>                                                                      
                                                                        {x.gamegroupID == 13 && <div className="raffleId">{t('lblentryamt')}: {x.StakeValue}</div>
                                                                        // : <div class="raffleId">{t('lblRafflaID')}: {x.RaffleID}</div>
                                                                        }
                                                                    </div>

                                                                    {x.gamegroupID == 13 ?
                                                                        <div>
                                                                            <div className={`lckmyCartBallTkt game-13-balls-${parseInt(x.BallNo)}`}></div>
                                                                            <span className="lckTktNo">{parseInt(x.BallNo)}</span>
                                                                        </div>
                                                                        : <div>
                                                                            <div className="selectedNumbers">
                                                                                {x.BallNo.split(';').map((x2, idx2) => <span key={`ae_${idx2}`} className="individualNumber" > {x2}</span>)}
                                                                            </div>
                                                                            <div className="raffleId text-center">{t('lblRafflaID')}: {x.RaffleID}</div>
                                                                        </div>
                                                                    }
                                                                </section>
                                                            </div>
                                                        )}
                                                        {/* style={{ padding: '15px 0' }} */}
                                                        <section className="px-4 pt-2" >{/*    */}
                                                            {/* <p className="ticketSlipcontent">
                                                                <span>{t('lblDrawDate')}</span>
                                                                <span>{this.props.myBet.betLst.filter(a => a.GameID == x1)[0].GameTime}</span>
                                                            </p> */}
                                                            {/* <p className="ticketSlipcontent">
                                                                <span>{t('lblGrandPrize')}</span>
                                                                <span>{this.props.appvariables.currency} {this.props.myBet.betLst.filter(a => a.GameID == x1)[0].PrizeAmount}</span>
                                                            </p> */}
                                                            <p className="ticketSlipcontent">
                                                                <span>{t('lblPlays')}</span>
                                                                <span>{this.props.myBet.betLst.filter(a => a.GameID == x1).length}</span>
                                                            </p>
                                                            <p className="ticketSlipcontent">
                                                                <span>{t('lblSubmitted')}</span>
                                                                <span>{this.props.myBet.betLst.filter(a => a.GameID == x1)[0].TransDateTime}</span>
                                                            </p>
                                                            <p className="ticketSlipcontent">
                                                                <span>{t('lblTicketId')}</span>
                                                                <span>{_transID}</span>
                                                            </p>
                                                            <p className="ticketSlipcontent">
                                                                <span>{t('lblTotalSpend')}</span>
                                                                <span>{this.props.appvariables.currency} {this.props.myBet.betLst.filter(a => a.GameID == x1).reduce((a, b) => a = a + b.StakeValue, 0)}</span>
                                                            </p>
                                                            {this.props.myBet.payment_mode != "" &&
                                                                <p className="ticketSlipcontent">
                                                                    <span>{t('lblModeOfPay')}</span>
                                                                    <span>{this.props.myBet.payment_mode}</span>
                                                                </p>
                                                            }
                                                        </section>
                                                        <div className="dateAndTime1" style={{ "width": "0%" }}>
                                                            <div className="barCode">
                                                                <Barcode displayValue="true" height="50px" format="CODE128" value={_transID} />
                                                            </div>
                                                        </div>
                                                        {this.props.myBet.buttomMsg && this.props.myBet.buttomMsg != "" &&
                                                            <span className="copyrgtTicket">{this.props.myBet.buttomMsg}</span>
                                                        }

                                                        {/* </>)} */}
                                                    </>
                                                </div >
                                            </div>
                                        </>
                                        )}

                                    </>
                                    }
                                    {this.props.myBet && this.props.myBet.status != 0 && <>
                                        <div className="lightbox21" style={{ display: "block" }}>
                                            <div className="newCo">
                                                {/* <div className="newCoInner">
                                            <div className="newCoInnerLft">
                                                <img src="./assets/img/icons/Betslip.png" />
                                                <span>{t('lblgameslip')}</span>
                                            </div>                                            
                                        </div>*/}
                                                {/* <div className="newCoWrap">
                                                    <div className="betSec1">
                                                        <div className="betValCont">
                                                            <div className="playAmount">
                                                                <span className="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "16px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myBet.msg}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            {this.props.myBet && this.props.myBet.status == 0 ?
                                <div className="DownLoadBtn">
                                    <a onClick={() => { this.downloadPdfDocument(_transID) }}><img src="./assets/img/icons/inbox_download.webp" alt='inbox_download icon'/></a>
                                    {/* <a onClick={() => { this.printFn() }}><img src="./assets/img/icons/printer.webp" /></a> */}
                                    <ReactToPrint
                                        trigger={() => <a><img src="./assets/img/icons/printer.webp" alt='printer icon'/></a>}
                                        content={() => this.getPrintCont()}
                                    />
                                </div> : ""}
                        </div >
                    </section >
                </div >
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { myBet } = state.gameReducer;
    return { user, myBet, appvariables };
}
const mapActionToProps = {
    ClearCart: gameActions.ClearCart,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PaymentStatus))