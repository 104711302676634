import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class Weeklylive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawtype: props.drawtype,
            _dbdrawType: props.send_db_id
        }
    }

    componentDidMount() {
        window.allDrawResultPopUp();
        localStorage.setItem('drawtabId', 4);
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="profileCont  pb-14 mydrawtabs">
                    {/* <div className="flex flex-col gap-y-7 items-start w-full">
                        <div className="flex gap-8 mt-10 items-center w-full">
                            <div className="w-1/2 rounded-md">
                                <img
                                    src={this.state.drawtype == 9 ? "./assets/img/past-draws/Fortune5.png" : "./assets/img/past-draws/super6.png"}
                                    alt="draw date"
                                    className="h-48 w-80 rounded-xl"
                                />
                            </div>
                            <div>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 mt-3">
                                    <li>
                                        <a className="text-sm text-blue-600" target="_blank" href="https://www.youtube.com/@Gulfticket/streams">YouTube</a>
                                    </li>
                                    <li>
                                        <a className="text-sm text-blue-600" target="_blank" href="https://www.facebook.com/gulfticketindia">Facebook</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex gap-10 items-center w-full">
                        <div className="w-1/2 rounded-md my-12 flex justify-center">
                            {this.state.drawtype == 9 ? <img src="./assets/img/past-draws/Fortune4.png?v0.2" alt="Fortune4" className="h-48 w-80 rounded-xl" />
                                : this.state.drawtype == 10 ? <img src="./assets/img/past-draws/super6.jpg?v0.1" alt="super6" className="h-48 w-80 rounded-xl" />
                                    : this.state.drawtype == 13 ? <img src="./assets/img/past-draws/lucky-1.jpg" alt="lucky-1" className="h-48 w-80 rounded-xl" />
                                    : this.state.drawtype == 14 ? <img src="./assets/img/past-draws/dream-5.jpg" alt="dream-5" className="h-48 w-80 rounded-xl" />
                                        : <img src="./assets/img/past-draws/magic-5.jpg?v0.1" alt="magic" className="h-48 w-80 rounded-xl" />}
                        </div>
                        <div className="flex flex-col gap-4 items-start">
                            <div className="w-3/4">
                                {this.state.drawtype == 9 ? <>{t('lblWatchlivefortune5')}</>
                                    : this.state.drawtype == 10 ? <>{t('lblWatchlivesuper6')}</>
                                        : this.state.drawtype == 13 ? <>{t('lblWatchlivelucky1')}</>
                                        : this.state.drawtype == 14 ? <>{t('lblWatchlivedream5')}</>
                                            : <>{t('lblWatchlivemagic5')}</>}
                            </div>
                            <div className="flex gap-4">
                                <button
                                    className={`gap-x-3 px-1 flex items-center rounded-full py-1 bg-game${this.state.drawtype} text-white font-semibold min-w-32`}>
                                    <a href="https://www.youtube.com/@Gulfticket/streams" target="_blank" rel="noopener"><img
                                        src="./assets/img/icons/social/youtube.png" alt="youtube icon"/> <span>Youtube</span></a>
                                </button>
                                <button
                                    className={`gap-x-3 px-1 flex items-center rounded-full py-1 bg-game${this.state.drawtype} text-white font-semibold min-w-32`}>
                                    <a href="https://www.facebook.com/gulfticketindia" target="_blank" rel="noopener"><img
                                        src="./assets/img/icons/social/fb.png" alt="fb icon"/> <span>Facebook</span></a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}




export default (withTranslation()(Weeklylive))
