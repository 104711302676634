import React, { Component } from "react";
import Header from "../../Header/Header";
import Profile from "./Profile";
import Withdraws from "./Withdraws";
import Order from "./Order";
import Wallet from "./Wallet";
import Changepassword from "./Changepassword";
import Transaction from './Transaction';
import { withTranslation } from 'react-i18next';
import { history } from "../../helpers/history";
import { connect } from 'react-redux';
import MyFavourite from "./MyFavourite";
import Purchase from "./Purchase";


class Account extends Component {
    constructor(props) {
        super(props);       
        const tabid = localStorage.getItem('atabId') ? localStorage.getItem('atabId') : 1;
        if (tabid == null || tabid == "")
            this.state = { setTabID: 1 }
        else
            this.state = { setTabID: tabid }
        //only allow logged in users
        if (!props.user || props.user.mobileNo == "0") {
            history.push('/login');
        }
    }

    changeTab = (tabId) => {
        localStorage.setItem('atabId', tabId);
        localStorage.removeItem('isRedirectToCart');
        this.setState({ setTabID: tabId });
    }

    changeEvt = (evt) => {
        this.changeTab(evt.target.value);
    }

    render() {
        const { t } = this.props
        return (
            <>
                <Header />
                <div className="wrap2">
                    <div className="profile profileTopMargin">
                        <ul className="profileTabs">
                            <li className={(this.state.setTabID == 1) ? "proTabItem1 tabActive" : "proTabItem1"}><a className="proLiPos1" onClick={() => this.changeTab(1)}>{t('lblProfile')}</a></li>
                            <li className={(this.state.setTabID == 2) ? "proTabItem2 tabActive" : "proTabItem2"}><a className="proLiPos2" onClick={() => this.changeTab(2)}>{t('lblOrders')}</a></li>
                            <li className={(this.state.setTabID == 3) ? "proTabItem3 tabActive" : "proTabItem3"}><a className="proLiPos3" onClick={() => this.changeTab(3)}>{t('lblWallet')}</a></li>
                            {/* <li className={(this.state.setTabID == 4) ? "proTabItem4 tabActive" : "proTabItem4"}><a className="proLiPos4" onClick={() => this.changeTab(4)}>{t('lblPaymentMethod')}</a></li> */}
                            <li className={(this.state.setTabID == 4) ? "proTabItem4 tabActive" : "proTabItem4"}><a className="proLiPos4" onClick={() => this.changeTab(4)}>{t('lblwithdrawls')}</a></li>
                            {/* <li className={(this.state.setTabID == 5) ? "proTabItem5 tabActive" : "proTabItem5"}><a className="proLiPos5" onClick={() => this.changeTab(5)}>{t('lblAccSetting')}</a></li> */}
                            <li className={(this.state.setTabID == 5) ? "proTabItem5 tabActive" : "proTabItem5"}><a className="proLiPos5" onClick={() => this.changeTab(5)}>{t('lblChangepPwd')}</a></li>
                            <li className={(this.state.setTabID == 7) ? "proTabItem6 tabActive" : "proTabItem6"}><a className="proLiPos6" onClick={() => this.changeTab(7)}>{t('lblFav')}</a></li>
                            <li className={(this.state.setTabID == 6) ? "proTabItem7 tabActive" : "proTabItem7"}><a className="proLiPos7" onClick={() => this.changeTab(6)}>{t('lblTransaction')}</a></li>                           
                            {/* <li className={(this.state.setTabID == 8) ? "proTabItem8 tabActive" : "proTabItem8"}><a className="proLiPos8" onClick={() => this.changeTab(8)}>{t('lblPurchase')}</a></li>                            */}
                        </ul>
                        <select name="user-menu" className="pUserMenuMobile" onChange={(e) => this.changeEvt(e)} value={this.state.setTabID}>
                            <option value='1' >{t('lblProfile')}</option>
                            <option value='2' >{t('lblOrders')}</option>
                            <option value='3' >{t('lblWallet')}</option>
                            {/* <option value='4' >{t('lblPaymentMethod')}</option> */}
                            <option value='4' >{t('lblwithdrawls')}</option>
                            {/* <option value='5'>{t('lblAccSetting')}</option> */}
                            <option value='5'>{t('lblChangepPwd')}</option>
                            <option value='6' >{t('lblTransaction')}</option>
                            <option value='8' >{t('lblPurchase')}</option>
                        </select>
                        {this.state.setTabID == 1 && <Profile />}
                        {this.state.setTabID == 2 && <Order />}
                        {this.state.setTabID == 3 && <Wallet locationData={this.props.location} changeTab={this.changeTab}/>}
                        {/* {this.state.setTabID == 4 && <Savedcards />} */}
                        {this.state.setTabID == 4 && <Withdraws />}
                        {this.state.setTabID == 5 && <Changepassword />}
                        {this.state.setTabID == 6 && <Transaction changeTab={this.changeTab} />}
                        {this.state.setTabID == 7 && <MyFavourite  />}
                        {/* {this.state.setTabID == 8 && <Purchase/>} */}
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
//export default withTranslation()(Account);
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Account))