import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../helpers/history';
import { accountActions } from '../../action/AccountAction';
import { gameActions } from "../../action/GameAction";


class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rdoStatus: 0,
            rdoStatus1: 0,
            rdoStatus2: 0,
            rdoStatus3: 0,
            TransactionData: [],
            serverDate: new Date(),
            rdoStatus4: 0,
            allStatus: 0,
            rdoStatus5: 0,
        }
        // this.props.GetTransactiondel(1, this.props.user.mobileNo, '01/01/2023', '09/10/2023', this.props.i18n.language, this.props.user.loginKey);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            OrderData: props.OrderData && props.OrderData.result && props.OrderData.result.map(function (ele) {
                return { ...ele, game_group_id_temp: ele.game_group_id == 12 ? 9 : ele.game_group_id };
            })
        };
    };
    componentDidMount() {
        window.documentReadyjQueryFlex();
        window.Loaddaterange(1);
        var lstrdate = document.getElementById('mydaterange').value;
        this.onchangeDate(lstrdate);
        window.getdateFromJS(this.onchangeDate);
        // localStorage.setItem('atabId', 2); this code is using in Account Component
    }
    viewTicket = (transactionID) => {
        this.props.ViewTicket(transactionID, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
    }
    gotoReBet = (game_group_id, transactionID) => {
        this.props.GetReBetNumbers(game_group_id, transactionID, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
    }
    onchangeDate = (ldate) => {
        var fdate = ldate.split('-')[0].trim();
        var tdate = ldate.split('-')[1].trim();
        let checkdate = this.validatedate(fdate, tdate);
        if (checkdate) {
            // this.props.GetTransactiondel(this.state.rdoStatus, fdate, tdate, 1, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
            this.props.GetOrderDel(0, fdate, tdate, 1, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        }
    }
    validatedate = (fromDate, toDate) => {
        let datepattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var fr = (new Date(fromDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var to = (new Date(toDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var currDate = ("0" + this.state.serverDate.getDate()).slice(-2) + "/" + ("0" + (this.state.serverDate.getMonth() + 1)).slice(-2) + "/" + this.state.serverDate.getFullYear();
        var lserverDate = (new Date(currDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        if (fr > lserverDate || to > lserverDate) {
            this.showMsg('lblNoFuturedate');
            return false;
        }
        return true;
        if (fr > to) {
            this.showMsg('lblFromdateNotGreater');
            return false;
        }
        fr.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        if (fr > to || fr < to) {
            var date = this.state.serverDate;
            var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
            var cdate = new Date(new Date(ss.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var lfr = ("0" + fr.getDate()).slice(-2) + "/" + ("0" + (fr.getMonth() + 1)).slice(-2) + "/" + fr.getFullYear();
            var lto = ("0" + to.getDate()).slice(-2) + "/" + ("0" + (to.getMonth() + 1)).slice(-2) + "/" + to.getFullYear();
            var fdate = new Date(new Date(lfr.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var tdate = new Date(new Date(lto.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            cdate.setHours(0, 0, 0, 0);
            fdate.setHours(0, 0, 0, 0);
            tdate.setHours(0, 0, 0, 0);
            if (fdate > cdate && tdate > cdate) {
                this.showMsg('lblCurrentDate');
                return false;
            }
        }
        return true;
    }
    showMsg = (lstrError) => {
        this.props.alertError(this.props.t(lstrError));
    }
    reSetFilter = () => {
        this.setState({ rdoStatus: 0 });
        // window.Loaddaterange();
        // var lstrdate = document.getElementById('mydaterange').value;
        // this.onchangeDate(lstrdate);
    }
    chkbtnClick = (id) => {
        if (id == 9) {
            this.setState({ rdoStatus: (this.state.rdoStatus == 0 ? id : 0) });
            this.state.rdoStatus = (this.state.rdoStatus == 0 ? id : 0);
        }
        else if (id == 10) {
            this.setState({ rdoStatus1: (this.state.rdoStatus1 == 0 ? id : 0) });
            this.state.rdoStatus1 = (this.state.rdoStatus1 == 0 ? id : 0);
        }
        else if (id == 11) {
            this.setState({ rdoStatus2: (this.state.rdoStatus2 == 0 ? id : 0) });
            this.state.rdoStatus2 = (this.state.rdoStatus2 == 0 ? id : 0);
        }
        else if (id == 12) {
            this.setState({ rdoStatus3: (this.state.rdoStatus3 == 0 ? id : 0) });
            this.state.rdoStatus3 = (this.state.rdoStatus3 == 0 ? id : 0);
        }
        else if (id == 13) {
            this.setState({ rdoStatus4: (this.state.rdoStatus4 == 0 ? id : 0) });
            this.state.rdoStatus4 = (this.state.rdoStatus4 == 0 ? id : 0);
        }
        else if (id == 14) {
            this.setState({ rdoStatus5: (this.state.rdoStatus5 == 0 ? id : 0) });
            this.state.rdoStatus5 = (this.state.rdoStatus5 == 0 ? id : 0);
        }


        if ((this.state.rdoStatus == 0 && this.state.rdoStatus1 == 0 && this.state.rdoStatus2 == 0 && this.state.rdoStatus3 == 0 && this.state.rdoStatus4 == 0 && this.state.rdoStatus5 == 0) || (this.state.rdoStatus != 0 && this.state.rdoStatus1 != 0 && this.state.rdoStatus2 != 0 && this.state.rdoStatus3 != 0 && this.state.rdoStatus4 != 0 &&  this.state.rdoStatus5 != 0)) {
            this.setState({ allStatus: 0 });
        }
        else {
            this.setState({ allStatus: 1 });
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="profileCont">
                <div className="filterContent">
                    <div className="wrap1">
                        <div className="divBorder">
                            <div className="accHeadCont">
                                <div className="radioInputs">
                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(13)}>
                                        <input id="r5" checked={this.state.rdoStatus4 == 13} type="checkbox" name="group1" onClick={() => this.chkbtnClick(13)} className="custom-checkbox" />
                                        <label for="r5" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="Lucky logo" className="h-8 w-20" /> </span>
                                        {/* <span>{t('lblSuperSix')}</span> */}
                                    </div>
                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(11)}>
                                        <input id="r3" checked={this.state.rdoStatus2 == 11} type="checkbox" name="group1" onClick={() => this.chkbtnClick(11)} className="custom-checkbox" />
                                        <label for="r3" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-8 w-20" /> </span>
                                        {/* <span>{t('lblmagic5')}</span> */}
                                    </div>
                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(9)}>
                                        <input checked={this.state.rdoStatus == 9} id="r1" type="checkbox" name="group1" onClick={() => this.chkbtnClick(9)} className="custom-checkbox" />
                                        <label for="r1" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt="fortune 4 logo" className="h-8 w-20" /> </span>
                                        {/* <span>{t('lblFortune5')}</span> */}
                                    </div>
                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(10)}>
                                        <input id="r2" checked={this.state.rdoStatus1 == 10} type="checkbox" name="group1" onClick={() => this.chkbtnClick(10)} className="custom-checkbox" />
                                        <label for="r2" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt="super 6 logo" className="h-8 w-20" /> </span>
                                        {/* <span>{t('lblSuperSix')}</span> */}
                                    </div>

                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(14)}>
                                        <input id="r2" checked={this.state.rdoStatus5 == 14} type="checkbox" name="group1" onClick={() => this.chkbtnClick(14)} className="custom-checkbox" />
                                        <label for="r2" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/dream-5-logo.png?v0.1" alt="Dream 5 logo" className="h-8 w-20" style={{marginTop:'-9px'}}/> </span>
                                        {/* <span>{t('lblSuperSix')}</span> */}
                                    </div>


                                    <div className="checkbox-container" onClick={() => this.chkbtnClick(12)}>
                                        <input id="r4" checked={this.state.rdoStatus3 == 12} type="checkbox" name="group1" onClick={() => this.chkbtnClick(12)} className="custom-checkbox" />
                                        <label for="r4" className="font18"></label>
                                        <span className=""> <img src="./assets/img/gamesBg/others-logo.png?v0.3" alt="fortune 5 logo" className="h-8 w-20" /> </span>
                                        {/* <span>{t('lblSuperSix')}</span> */}
                                    </div>

                                    {/* <img src="./assets/img/icons/cross.svg" alt="cancel button" className="crossImg" onClick={() =>this.reSetFilter()} /> */}
                                </div>
                                <button className="inputDateRangeBtn" style={{ cursor: "pointer", backgroundColor: '#df911a', borderRadius: '30px' }}>
                                    <img src="./assets/img/icons/calendar.svg" />
                                    <input style={{ backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" dir="ltr" readOnly />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orders">
                    {this.state.OrderData ?
                        <ul className="ordersSection">
                            {/* {!this.state.TransactionData && <p className="text-center">{t('lblNopurchase')}</p>} */}
                            {this.state.OrderData.filter(fdata => this.state.allStatus == 0 || fdata.game_group_id == this.state.rdoStatus || fdata.game_group_id == this.state.rdoStatus1 || fdata.game_group_id == this.state.rdoStatus2 || fdata.game_group_id == this.state.rdoStatus3 || fdata.game_group_id == this.state.rdoStatus4 || fdata.game_group_id == this.state.rdoStatus5).map((data, index) =>
                                <li key={`bk_${index}`}>
                                    <div className="ordersCont">
                                        <div className="ordersSelect">
                                            <div className="orderTicket">
                                                <div className="col-lg-3">
                                                    <span className="">
                                                        {data.game_group_id == 9 ?
                                                            <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.2" alt="Fortune 4 logo" className="h-10 w-24" /> :
                                                            data.game_group_id == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png?v0.2" alt="super 6 logo" className="h-10 w-24" /> :
                                                                data.game_group_id == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png?v0.2" alt="magic 5 logo" className="h-10 w-24" />
                                                                    : data.game_group_id == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" />
                                                                        : data.game_group_id == 14 ? <img src="./assets/img/gamesBg/dream-5-logo.png?v0.1" alt="dream 5 logo" className="h-10 w-24" /> :
                                                                         <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune 5  logo" className="h-10 w-24" />}
                                                    </span>
                                                    {/* <h3>{data.game_name}</h3> */}
                                                    <small>{t('lblPlayType')}: {(data.play_type > 1) ? `${t('lblMulti')}` : `${t('lblsingle')}`}</small>
                                                    <small>{t('lblDrawDate')}: {data.draw_date_time}</small>
                                                    <small>{t('lblSubmitted')}: {data.transaction_date_time}</small>
                                                </div>
                                                <div className="col-lg-5 ordersCenter">
                                                    <div className={`orderBall border-game${data.game_group_id}`}>
                                                        {data.game_group_id != 13 && data.bet_info && data.bet_info.split(';').length > 0 && data.bet_info.split(';').map((ballNo, inx) =>
                                                            <span key={`bl_${inx}`} className={`bg-game${data.game_group_id_temp}`}>{ballNo}</span>
                                                        )}

                                                        {data.game_group_id == 13 && data.bet_info && data.bet_info.split(';').length > 0 && data.bet_info.split(';').map((ballNo, inx) =>
                                                            <div key={`bm_${inx}`} className={`lckmyCartBall lckmyCartBallOrdrs border-game13 game-13-balls-${parseInt(ballNo)}`} >
                                                                <span>{ballNo}</span>
                                                            </div>)}
                                                    </div>
                                                    {data.raffle_id != "" && data.raffle_id.length > 2 && <span className={`orderId border-game${data.game_group_id_temp}`}>{t('lblRafflaID')}: {data.raffle_id}</span>}
                                                </div>
                                                <div className="col-md-4">
                                                    {data.game_group_id != 12 && <button className={`bg-game${data.game_group_id_temp}  ${data.game_group_id_temp != 10 ? `!border-game${data.game_group_id_temp} !` : ''}text-white hover:!bg-white hover:!text-[#494949]`} onClick={() => this.gotoReBet(data.game_group_id, data.transaction_id)}>{t('lblBuyAgain')}</button>}
                                                    <button className={`${data.game_group_id_temp != 10 ? `bg-game${data.game_group_id_temp}` : ""} ${data.game_group_id_temp != 10 ? "viewTicket" : ""} ${`border-game${data.game_group_id_temp}`}`} onClick={() => this.viewTicket(data.transaction_id)}>{t('lblViewTickets')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                        :
                        <>
                            <p className="text-center">{t('lblNopurchase')}</p>
                        </>}
                </div>

            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { OrderData } = state.accountReducer;
    return { user, OrderData };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetOrderDel: accountActions.GetOrderDel,
    ViewTicket: gameActions.RePrintBetDel,
    GetReBetNumbers: gameActions.GetReBetNumbers
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Order))