import { appConstants } from '../helpers/actionType';
import { userService } from '../services/loginService';
import { commonClass } from "..//helpers/commonClass";
import { history } from '../../component/helpers/history';
import { alertActions } from './AlertAction';
import { SHA1 } from 'crypto-js';

export const loginActions = {
    login,
    lfnotpgen,
    setPage,
    Register,
    Registerotp,
    RegisterActivate,
    logout,
    getBalance,
    changepassword,
    captchaVerify,
    getBanner
}

function login(mobileNo, otp, Logintype, langID, loginKey, flagcode , defaultcontry) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            otp = SHA1(otp).toString();
            let data = { "password": `${otp}`, "captcha": defaultcontry, "type_id": Logintype }
            userService.login(data, mobileNo, langID, loginKey, flagcode).then(
                user => {
                    let strArr = user.result.Message;
                    dispatch({ type: appConstants.LOADED });
                    if (user.result.Status_id === "0") {
                        commonClass.SendPageDataLayer(1);
                        setPage(0);
                        let exprtime = 120;//sec.. 
                        if (user.result.Timeout_second) {
                            exprtime = user.result.Timeout_second;
                        }
                        // parseInt Balance devided Problem. 
                        let userdata = {
                            userid: user.result.User_name, username: user.result.User_name, mobileNo: user.result.User_name,
                            balance: parseFloat(user.result.Balance / 100), winbalance: parseFloat(user.result.Win_balance / 100),
                            loginKey: user.result.Session_id, emailID: user.result.TVN, loginExpiry: (exprtime * 1000), Imageinfo: user.result.Imageinfo,
                            Created_date: user.result.Created_date, Country_code: user.result.Country_code, Player_name: user.result.Player_name
                        }
                        localStorage.setItem('user', commonClass.encrypt(JSON.stringify(userdata)));
                        dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
                        let pageFlog = 0;
                        let eObj2 = {
                            'pk^email': user.result.TVN,
                            'email': user.result.TVN,
                            'mobile': flagcode + mobileNo                            
                        }
                        commonClass.SendUserTracking(3, eObj2);
                        //let eObj = {
                        //   'mobile': mobileNo,
                        //}
                        //commonClass.SendEventTracking(1, eObj);

                        // commonClass.SendUserTracking(3, eObj);
                        //localStorage.setItem('alertmsg', strArr);
                        dispatch({ type: appConstants.SET_PAGEID, pageFlog });
                        // if login user after 7 day call welcome  popup
                        let loginflag = false;
                        let lastlogindate = new Date(localStorage.getItem('lastloginuserdate'))
                        if (lastlogindate == null) {
                            loginflag = true;
                        }
                        else {
                            let nowtime = new Date();
                            const millisecondsDiff = nowtime.getTime() - lastlogindate.getTime()
                            let onedayinmillis = 86400000;
                            let skipdaycount = 5;
                            if (millisecondsDiff > (skipdaycount * onedayinmillis)) {
                                loginflag = true;
                            }
                            else {
                                loginflag = false
                            }
                        }
                        if (strArr != '' && loginflag) {
                            dispatch(alertActions.success(strArr)) // Welcome msg Show Sting
                        }
                        let lastlogin = new Date();
                        localStorage.setItem('lastloginuserdate', lastlogin.toString());
                        //for reload cart value
                        let lmobNo = localStorage.getItem('lastloginuser');
                        if (lmobNo == userdata.mobileNo || lmobNo == 0) {
                            localStorage.setItem('mycart', localStorage.getItem('lastloginmycart'));
                            localStorage.removeItem('lastloginmycart');
                        }
                        else {
                            localStorage.removeItem('mycart');
                            localStorage.removeItem('lastloginmycart');
                        }
                        setTimeout(() => { history.push('/home') }, 2000);
                    }
                    else if (user.result.Status_id == 7) { //if player not activated
                        let otp = { otp: user.result.Message, mobileNo: mobileNo, Ccode: flagcode };
                        dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                        let pageFlog = 3;
                        dispatch({ type: appConstants.SET_PAGEID, pageFlog });
                        let eObj = {
                            'mobile': mobileNo,
                            'REASON': strArr
                        }
                        commonClass.SendEventTracking(2, eObj);
                        history.push('/registerActivation');
                    }
                    else {
                        let eObj = {
                            'mobile': mobileNo,
                            'REASON': strArr
                        }
                        commonClass.SendEventTracking(2, eObj);
                        commonClass.SendPageDataLayer(2);
                        dispatch(alertActions.error(strArr));
                    }
                },
                error => {
                    let eObj = {
                        'mobile': mobileNo,
                        'REASON': error.message
                    }
                    commonClass.SendEventTracking(2, eObj);
                    commonClass.SendPageDataLayer(2);
                    dispatch({ type: appConstants.LOADED });
                    error = error.message;
                    dispatch(alertActions.error(error));;
                }
            );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function Registerotp(mobileNo, otp, langID, loginKey, CCode) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //commonClass.SendOtherEvent(1, 0);
            let data = { "otp": otp }
            userService.Registerotp(data, mobileNo, langID, loginKey, CCode)
                .then(
                    user => {
                        dispatch({ type: appConstants.LOADED });
                        let strArr = user.result.Message;
                        setPage(0);
                        if (user.result.Status_id == 0) {
                            //commonClass.SendOtherEvent(1, 1);
                            //commonClass.SendPageDataLayer(3);

                            dispatch(alertActions.success(strArr));
                            //alert(strArr);
                            //localStorage.setItem('alertmsg', strArr);   
                            let otp = { otp: user.result.Message, mobileNo: mobileNo, Ccode: CCode };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            let eObj = {
                                'mobile': mobileNo,
                            }
                            commonClass.SendEventTracking(5, eObj);
                            history.push('/registerActivation');

                        }
                        else if (user.result.Status_id == 1) {
                            let Gtinfo = CCode + '~' + mobileNo + '~' + strArr;
                            commonClass.SendPageDataLayer(4, Gtinfo);
                            let eObj = {
                                'mobile': mobileNo,
                                'REASON': strArr
                            }
                            commonClass.SendEventTracking(6, eObj);
                            //commonClass.SendOtherEvent(1, 2, strArr);
                            dispatch(alertActions.error(strArr));
                            //alert(strArr);
                            //localStorage.setItem('alertmsg', strArr);
                            // history.push('/register');                            
                        }
                        else {
                            let Gtinfo = CCode + '~' + mobileNo + '~' + strArr;
                            commonClass.SendPageDataLayer(4, Gtinfo);
                            let eObj = {
                                'mobile': mobileNo,
                                'REASON': strArr
                            }
                            commonClass.SendEventTracking(6, eObj);
                            //commonClass.SendOtherEvent(1, 2, strArr);
                            dispatch(alertActions.error(strArr));
                            //alert(strArr);
                            //localStorage.setItem('alertmsg', strArr);
                        }
                    },
                    error => {
                        let eObj = {
                            'mobile': mobileNo,
                            'REASON': error.message
                        }
                        commonClass.SendEventTracking(6, eObj);
                        let Gtinfo = CCode + '~' + mobileNo + '~' + error.message;
                        commonClass.SendPageDataLayer(4, Gtinfo);
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch(alertActions.error(error))
                        //commonClass.SendOtherEvent(1, 2, error);
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function Register(mobileNo, flog, langID, loginKey, firstname, lastname, password, Ccode) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            // if (flog == 1) { 
            //     commonClass.SendOtherEvent(2, 0); 
            // }
            password = SHA1(password).toString();
            let data = { type_id: flog, first_name: firstname, last_name: lastname, password: password, }
            userService.Register(data, mobileNo, langID, loginKey, Ccode)
                .then(
                    otpres => {
                        dispatch({ type: appConstants.LOADED });
                        if (otpres.result.Status_id == 0) {
                            let eObj = {
                                'mobile': mobileNo,
                                'NAME': firstname,
                                'LAST_NAME': lastname
                            }
                            localStorage.setItem('regnetcore', JSON.stringify({ firstname: firstname, lastname: lastname }));
                            commonClass.SendEventTracking(3, eObj);
                            let otpLst = otpres.result.Message.split('~'); //otp expired coundown
                            let _ExpSec = 0;
                            let _Maxtry = -1;
                            let _Msg;
                            if (otpLst.length > 0) {
                                _Msg = otpLst[0];
                            }
                            if (otpLst.length > 1) {
                                _ExpSec = otpLst[1];
                            }
                            if (otpLst.length > 2) {
                                _Maxtry = otpLst[2];
                            }
                            const min = 1;
                            const max = 100;
                            const random = min + (Math.random() * (max - min));
                            let otp = { otp: _Msg, mobileNo: mobileNo, flog: flog, firstname: firstname, lastname: lastname, password: password, Ccode: Ccode, ExpSec: _ExpSec, Maxtry: _Maxtry, RefNo: random };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            let pageFlog = 3;
                            dispatch({ type: appConstants.SET_PAGEID, pageFlog });
                            history.push('/otpgeneration');
                        }
                        else if (otpres.result.Status_id == 7) {
                            let otp = { otp: otpres.result.Message, mobileNo: mobileNo, Ccode: Ccode };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            let pageFlog = 3;
                            dispatch({ type: appConstants.SET_PAGEID, pageFlog });
                            let eObj = {
                                'mobile': mobileNo,
                                'NAME': firstname,
                                'LAST_NAME': lastname
                            }
                            commonClass.SendEventTracking(3, eObj);
                            history.push('/registerActivation');
                        }
                        else {
                            let Gtinfo = Ccode + '~' + mobileNo + '~' + otpres.result.Message;
                            commonClass.SendPageDataLayer(4, Gtinfo);
                            let otp = { otp: otpres.result.Message, mobileNo: mobileNo, flog: flog, Ccode: Ccode };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            dispatch(alertActions.error(otpres.result.Message));
                            let eObj = {
                                'mobile': mobileNo,
                                'NAME': firstname,
                                'LAST_NAME': lastname,
                                'REASON': otpres.result.Message
                            }
                            commonClass.SendEventTracking(4, eObj);
                            // if (flog == 1) { commonClass.SendOtherEvent(2, 2, otpres.result.Message); }
                        }
                    },
                    error => {
                        let eObj = {
                            'mobile': mobileNo,
                            'NAME': firstname,
                            'LAST_NAME': lastname,
                            'REASON': error.message
                        }
                        commonClass.SendEventTracking(4, eObj);
                        let Gtinfo = Ccode + '~' + mobileNo + '~' + error.message;
                        commonClass.SendPageDataLayer(4, Gtinfo);
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch(alertActions.error(error));
                        // if (flog == 1) { commonClass.SendOtherEvent(2, 2, error); }
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function RegisterActivate(mobileNo, email, nation, langID, loginKey, Ccode,nationname) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //commonClass.SendOtherEvent(1, 0);
            let data = { "email_id": email, "nationality": nation }
            userService.RegisterActivation(data, mobileNo, langID, loginKey, Ccode)
                .then(
                    user => {
                        let strArr = user.result.Message;
                        setPage(0);
                        if (user.result.Status_id == 0) {
                            let rnc = localStorage.getItem('regnetcore');
                            if (rnc) {
                                rnc = JSON.parse(rnc);
                                localStorage.removeItem('regnetcore');
                            }
                            let eObj2 = {
                                'pk^email': email,
                                'email': email,
                                'mobile': Ccode + mobileNo,
                                'NAME': rnc?.firstname,
                                'LAST_NAME': rnc?.lastname,
                                "NATIONALITY": nationname
                            }
                            commonClass.SendUserTracking(4, eObj2);
                            // let eObj = {
                            //     'mobile': Ccode + mobileNo,
                            //     'email': email,
                            //     'firstname': rnc?.firstname,
                            //     'lastname': rnc?.lastname,
                            //     8nationality": nation
                            // }
                            //commonClass.SendEventTracking(7, eObj, true);

                            commonClass.SendOtherEvent(1, 1);
                            commonClass.SendPageDataLayer(3);
                            //dispatch(alertActions.success(strArr));                            
                            //alert(strArr);
                            localStorage.setItem('alertmsg', strArr);
                            setTimeout(() => {
                                dispatch({ type: appConstants.LOADED });
                                history.push('/login');
                            }, 1000);
                        }
                        else if (user.result.Status_id == 1) {
                            let Gtinfo = Ccode + '~' + mobileNo + '~' + strArr;
                            commonClass.SendPageDataLayer(4, Gtinfo);
                            let eObj = {
                                'mobile':  mobileNo,
                                'REASON': strArr
                            }
                            commonClass.SendEventTracking(8, eObj);
                            //commonClass.SendOtherEvent(1, 2, strArr);
                            dispatch(alertActions.error(strArr));
                            //alert(strArr);
                            //localStorage.setItem('alertmsg', strArr);
                            //history.push('/register');                            
                        }
                        else {
                            let Gtinfo = Ccode + '~' + mobileNo + '~' + strArr;
                            commonClass.SendPageDataLayer(4, Gtinfo);
                            let eObj = {
                                'mobile':  mobileNo,
                                'REASON': strArr
                            }
                            commonClass.SendEventTracking(8, eObj);
                            //commonClass.SendOtherEvent(1, 2, strArr);
                            dispatch(alertActions.error(strArr));
                            //alert(strArr);
                            //localStorage.setItem('alertmsg', strArr);
                        }
                    },
                    error => {
                        let eObj = {
                            'mobile':  mobileNo,
                            'REASON': error.message
                        }
                        commonClass.SendEventTracking(8, eObj);
                        let Gtinfo = Ccode + '~' + mobileNo + '~' + error.message;
                        commonClass.SendPageDataLayer(4, Gtinfo);
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch(alertActions.error(error))
                        //commonClass.SendOtherEvent(1, 2, error);
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function lfnotpgen(mobileNo, flog, langID, loginKey, Ccode) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            // if (flog == 1) { 
            //     commonClass.SendOtherEvent(2, 0); 
            // }
            let data = { "type_id": flog }
            userService.otpgeneration(data, mobileNo, langID, loginKey, Ccode)
                .then(
                    otpres => {
                        dispatch({ type: appConstants.LOADED });
                        if (otpres.result.Status_id == 0) {
                            let otpLst = otpres.result.Message.split('~'); //otp expired coundown
                            let _ExpSec = 0;
                            let _Maxtry = -1;
                            let _Msg;
                            if (otpLst.length > 0) {
                                _Msg = otpLst[0];
                            }
                            if (otpLst.length > 1) {
                                _ExpSec = otpLst[1];
                            }
                            if (otpLst.length > 2) {
                                _Maxtry = otpLst[2];
                            }
                            const min = 1;
                            const max = 100;
                            const random = min + (Math.random() * (max - min));
                            let otp = { otp: _Msg, mobileNo: mobileNo, flog: flog, Ccode: Ccode, ExpSec: _ExpSec, Maxtry: _Maxtry, RefNo: random };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            let pageFlog = 3;
                            dispatch({ type: appConstants.SET_PAGEID, pageFlog });
                            if (flog == 1) {//for Register
                                commonClass.SendOtherEvent(2, 1);
                            }
                            else if (flog == 3) {// for ForgetPassword
                                history.push('/ForgetPassword')
                            }
                            else {
                                history.push('/otpgeneration');
                            }
                        }
                        else {
                            let otp = { otp: otpres.result.Message, mobileNo: mobileNo, flog: flog, Ccode: Ccode };
                            dispatch({ type: appConstants.SET_LOGIN_OTP, otp });
                            dispatch(alertActions.error(otpres.result.Message));
                            // if (flog == 1) { commonClass.SendOtherEvent(2, 2, otpres.result.Message); }
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch(alertActions.error(error));
                        // if (flog == 1) { commonClass.SendOtherEvent(2, 2, error); }
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function setPage(pageFlog) {
    if (pageFlog == 0)
        window.Loginpopup(pageFlog);
    return dispatch => {
        dispatch({ type: appConstants.SET_PAGEID, pageFlog });
    }
};
function logout(mobNo, isLoginPage) {
    //userService.logout();
    //history.push('/login');    
    //for reload cart value
    localStorage.removeItem('user');
    localStorage.removeItem('appvariables');
    localStorage.removeItem('wname'); //auto logout ,if url open duplicate window.IdleSesson componet
    localStorage.removeItem('atabId');
    if (isLoginPage != 1) {
        localStorage.setItem('lastloginuser', mobNo);
        history.push('/login');
    }

    return dispatch => {
        dispatch({ type: appConstants.LOGOUT })
    };
};
function getBalance(regMobileNo, langID, loginKey) {
    try {
        return dispatch => {
            userService.getBalance(regMobileNo, langID, loginKey)
                .then(
                    reg => {
                        if (reg.result != null) {
                            if (reg.result.Status_id == 0) {
                                let balance = reg.result.Balance;
                                let winbalance = reg.result.Win_balance;
                                dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                            }
                            else if (reg.result.Status_id == 5) {//5-for loginID mismatch 
                                setTimeout(() => {
                                    logout();
                                }, 1000);
                                dispatch(alertActions.error(reg.result.Message));
                                //localStorage.setItem('alertmsg', reg.result.Message);
                            }
                        }
                    },
                    error => {
                        let balance = 0;
                        let winbalance = 0;
                        dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function changepassword(mobileNo, old_password, new_password, setflag, langID, loginKey, Ccode) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            if (setflag == 1) { //1 =  Change PassWord ,2 =  Forgot PassWord ,                
                old_password = SHA1(old_password).toString();
            }
            new_password = SHA1(new_password).toString();
            let data = { "old_password": old_password, "new_password": new_password, "flag": setflag }
            userService.changepassword(data, mobileNo, langID, loginKey, Ccode).then(
                user => {
                    dispatch({ type: appConstants.LOADED });
                    let strArr = user.result.Message;
                    if (user.result.Status_id == 0) {
                        dispatch(alertActions.success(strArr));
                        if (setflag == 2) {
                            setTimeout(() => { history.push('/login') }, 1500);
                        }
                    }

                    else {
                        dispatch(alertActions.error(strArr));
                    }
                },
                error => {
                    dispatch({ type: appConstants.LOADED });
                    error = error.message;
                    dispatch(alertActions.error(error));
                }
            );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function captchaVerify(mobileNo, secret_key, recaptcha, langID, loginKey) {
    try {
        return dispatch => {
            let data = { "secret_key": secret_key, "recaptcha": recaptcha }
            userService.captchaVerify(data, mobileNo, langID, loginKey).then(
                user => {
                    return user;
                },
                error => {
                    return error;
                }
            );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function getBanner(MobileNo, langID, loginKey) {
    try {
        return dispatch => {
            userService.getBanner(MobileNo, langID, loginKey)
                .then(
                    gbanner => {
                        if (gbanner && gbanner.result && gbanner.result.Status_id == 0) {
                            gbanner = gbanner.result.banner_info;
                            dispatch({ type: appConstants.GET_BANNER_VERSION, gbanner })
                        }
                    },
                    error => {
                        error = error.message;
                        dispatch(alertActions.error(error));
                    }
                )
        }

    }
    catch {

    }
}


